// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorInput {
    margin-top: -20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: red;
    padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/errorinput.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".errorInput {\n    margin-top: -20px;\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 20px;\n    color: red;\n    padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
