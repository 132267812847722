// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetPasswordSuccessContent {
    width: 100%;
    font-family: var(--font-poppins);
    margin-top: 23px;
}
.resetPasswordSuccessContentTitle {
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -1.2px;
    text-align: center;
    color: #364A63; 
}

.resetPasswordSuccessContentDescription {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -1px;
    text-align: center;
    color: #4E4E4E; 
    margin-top: 23px; 
}`, "",{"version":3,"sources":["webpack://./src/inresetpassword/CSS/resetpasswordsuccess.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gCAAgC;IAChC,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".resetPasswordSuccessContent {\n    width: 100%;\n    font-family: var(--font-poppins);\n    margin-top: 23px;\n}\n.resetPasswordSuccessContentTitle {\n    font-size: 40px;\n    font-weight: 600;\n    line-height: 44px;\n    letter-spacing: -1.2px;\n    text-align: center;\n    color: #364A63; \n}\n\n.resetPasswordSuccessContentDescription {\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 30px;\n    letter-spacing: -1px;\n    text-align: center;\n    color: #4E4E4E; \n    margin-top: 23px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
