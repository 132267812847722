import React from 'react'

const BalanceDataDetail = ({imagesrc, label, value, altText}) => {
  return (
    <div className={`balance-detail-item ${label==='Wallet balance' ? 'bg-F4F2FE' : '' }`}>
        <figure className={`rounded-icon-wrapper ${label==='Wallet balance' ? 'm2' : ''}`}>
            <img src={imagesrc} alt={altText} loading='lazy' draggable='false' />
        </figure>
        <div>
            <span>{label}</span>
            <span>{value}</span>
        </div>
    </div>
  )
}

export default BalanceDataDetail