import React from 'react'
import './CSS/resetpasswordsuccess.css'  
const ResetPasswordSuccess = () => {
  return (
    <div className='resetPasswordSuccessContainer'>
            <div className='resetPasswordSuccessWrapper'>
                <figure>
                    <img src='/images/dashboard/resetpassword/Group 442.svg'  
                    alt='a men hold a big key beside it a blue rectangle within it password updated sentence and a checkmark' 
                    style={{width: '100%', maxWidth: '500px', display: 'block', margin: 'auto'}} 
                    />  
                </figure>
                <div className='resetPasswordSuccessContent'>
                    <div className='resetPasswordSuccessContentTitle'>Password updated!</div>
                    <p className='resetPasswordSuccessContentDescription'>Your password has been changed successfully. </p>  
                </div>
            </div>
        </div>
  )
}

export default ResetPasswordSuccess 
 