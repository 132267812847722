// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* select + div {
    margin-top: 0 !important;
} */

.inputForm {
    width: 100%;
    height: 35px;
    margin-bottom: 0;
    border: 1.5px solid #D5D5D5;
    border-radius: 2px;
    padding-inline: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
    color: #000000;
}
select.inputForm {
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
    border: 1.5px solid #D5D5D5;
    border-radius: 2px;
    padding-inline: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
    color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/selectinput.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":["/* select + div {\n    margin-top: 0 !important;\n} */\n\n.inputForm {\n    width: 100%;\n    height: 35px;\n    margin-bottom: 0;\n    border: 1.5px solid #D5D5D5;\n    border-radius: 2px;\n    padding-inline: 10px;\n    font-size: 15px;\n    font-weight: 400;\n    line-height: 27px;\n    color: #000000;\n}\nselect.inputForm {\n    width: 100%;\n    height: 35px;\n    margin-bottom: 20px;\n    border: 1.5px solid #D5D5D5;\n    border-radius: 2px;\n    padding-inline: 10px;\n    font-size: 15px;\n    font-weight: 400;\n    line-height: 27px;\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
