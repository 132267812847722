import React,  { useState, useEffect, useCallback} from 'react'
import { Field, ErrorMessage } from 'formik'
import ErrorInput from './ErrorInput'

const CurrentPassword = ({label, name, id, error, touched, onKeyUp}) => {
    const [isPassword, setIsPassword] = useState(true)
    useEffect(()=>{
      document.getElementById(id).addEventListener('keyup', (e)=>{
        if(document.getElementById(id)) {
          if(document.getElementById(id).value === '') {
            document.getElementById(`eye_current_password`).style.display = 'none'
            document.getElementById(id).style.paddingRight = '10px'
          }
          else {
            document.getElementById(`eye_current_password`).style.display = 'inline-block'
            document.getElementById(id).style.paddingRight = '38px'  
          } 
        }
        
      })

      
      return ()=>{

      }
    }, [id])
    const checkActive = useCallback(()=>{
        setIsPassword(!isPassword)
    }
        , [isPassword])
        // const {name, label, ...rest} = props
        function eye () {
          //console.log(document.getElementById(id))
        }
  return (
    <div className='form_control'>
            <label className='form_label' htmlFor={id}>{label}</label>
            <Field name={name} >
             {({
               field, // { name, value, onChange, onBlur }
            //    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
               meta,
             }) => {
                // console.log(field)

               return ( <div className='password_field_container'>
                    <input id={id} type={`${isPassword ? 'password' : 'text'}`}  {...field}  
                    className={`password_field ${error && touched ? 'error' : ''}
                    ${!error && touched ? 'correct' : ''}`}
                    onKeyUp={onKeyUp}
                    autoComplete='off'
                    autoCorrect='off'
                    inputMode='text'
                    />
                    <button onClick={checkActive} type='button' id={`eye_current_password`}>
                        {isPassword ? 
                          <i className="fa fa-eye-slash"></i> : 
                          <i className="fa fa-eye"></i>
                        } 
                    </button>
                </div> )
             }}
            </Field>
           <ErrorMessage  name={name} component={ErrorInput} /> 
           </div>
  )
}

export default CurrentPassword 

