import React from 'react'
import './CSS/errorinput.css'
const ErrorInput = (props) => {
  const styles = {
    marginTop: '-20px',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    color: 'red',
    paddingLeft: '10px'
  }
  return (
    <div className='errorInput'>{props.children}</div>
  )
}

export default ErrorInput 