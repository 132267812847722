import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, redirect, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik' 
import * as YUP from 'yup' 
import TextInput from '../components/TextInput'
import PasswordInput from '../components/PasswordInput'
import Modal from 'react-bootstrap/Modal'
import Loader from '../jsx/pages/Loader/Loader'
import './registration.css'
import { register, formatError } from '../security/AuthService'
import { useActive } from '../context/ActiveContext'
import ErrorModalMessage from '../Modals/ErrorModalMessage'
import Button from '../components/Button' 
const Registration = () => {
    const error = useRef(null)
    // const { handleMenuActive } = useActive()
    // const [isPending, setIsPending] = useState(false)
    const [show, setShow] = useState(false)
    const navigate = useNavigate()
    const [isError, setIsError] = useState(false)

    const handleShowError = useCallback(function handleShowError () {
        setIsError(true)
    })
    const handleCloseError = useCallback(function handleCloseError () {
        setIsError(false)
    })
    // const handleShowPending = useCallback(()=>{
    //     setIsPending(true)
    // })
    // const handleClosePending = useCallback(function handleClosePending () {
    //     setIsPending(false)
    // }) 
    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let message 
  return (
    <>
    {/* {!isPending &&  */}
    <div className='registration-container'>
        <div className='registration-wrapper'>
            <div className='registration-form-container'>
                <div className='form-welcoming-part'>
                    <Link to='/'><img src='/images/registration/Group 1.svg' alt='paytabs logo' draggable='false' /></Link>
                    <div id='welcome-to-paytabs'>Welcome to PayTaps</div>
                    <div id='signup-signin-your-account'>Sign up your account</div>
                </div>
                <>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            confirm_password: ''
                        }}

                        validationSchema={YUP.object({
                            email: YUP.string().required('Required!').email('Invalid Email!'),
                            password: YUP.string().required('Required!').min(5, 'Password must contain at least 5 characters')
                            .matches(/[A-Z]+/, { message: <span>Password must contain at least 1 uppercase character</span>})
                            .matches(/[a-z]+/, { message: <span>Password must contain at least 1 lowercase character </span>})
                            .matches(/[0-9]+/, { message: <span>Password must contain at least 1 number </span>}),
                            confirm_password: YUP.string().required('Required!').oneOf([YUP.ref('password'), ''], 'Please enter the correct password again')
                        })}

                        onSubmit={async (values, {setSubmitting, resetForm})=>{
                            // console.log(values)
                            
                            try {
                                if(isError) {
                                    handleCloseError()
                                }
                                // setIsPending(true)
                                // handleShowPending()
                                handleShow()
                                const response = await register({email: values.email, password: values.password}) 
                                message = await response.json()
                                // console.log({response: response, message: message})
                                if(response.ok && (message.message === 'User created successfully')) {
                                    setSubmitting(false)
                                    resetForm()
                                    // setIsPending(false)
                                    // handleClosePending()
                                    handleClose()
                                    // handleMenuActive('Dashboard')
                                    navigate('/login')
                                }
                                // const response = {ok: false}
                                if(!response.ok) {
                                    error.current = formatError(message.error)
                                    handleShowError()  
                                                        }
                                // if(response.ok && )
                                // setSubmitting(false)
                                // resetForm()
                                // navigate('/')
                            } catch (err) {
                                console.log(err)
                            }
                        }}
                    >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                <TextInput name='email' id='email' label='Email' error={formik.errors.email} touched={formik.touched.email} 
                                inputtype='email' onKeyUp={keyUpHandler} />
                                <PasswordInput name='password' id='password' label='Password' passwordName='password' error={formik.errors.password} touched={formik.touched.password} onKeyUp={keyUpHandler} />
                                <PasswordInput name='confirm_password' id='confirmpassword' label='Confirm Password' passwordName='password' error={formik.errors.confirm_password} touched={formik.touched.confirm_password} onKeyUp={keyUpHandler} />
                                <button type='submit' id='sign-up' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Sign Up</button>
                            </Form>)}}
                    </Formik>
            </>
            <div className='already-have-account'>
                Already have an account? 
                <Link to='/login' style={{
                    fontWeight: '700',
                    color: '#0E0E0E'
                }}> Log in</Link>
            </div>
            </div>
            
            <figure className='registration'>
            </figure>
        </div>
    </div>
            {/* } */}
    {/* {isPending && <> */}

        <Modal show={show} className='loader-error' backdrop='static' keyboard='false'>
          <Modal.Body className='center'>
              {!isError && <Loader />}
              {isError && <div className='errorMessageContainer'> <figure><img src='/images/registration/Reset password-rafiki.svg' width='300' /></figure><div>This email is already associated with an account</div><div className='messageError'>Please try logging in or use a different email to sign up.</div> <Button styles= 'try-again-btn' click={handleClose}>Try Again!</Button></div>}   
          </Modal.Body>
        </Modal>
        {/* {isError && <ErrorModalMessage isError={isError} handleClose={handleCloseError} message={error.current} />} */}
  {/* </>} */}
  
  </>
  )
}

export default Registration













 