import React, { useState, useRef, useCallback, useEffect } from 'react'
import './CSS/dashboard.css'
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import Button from './../components/Button'
import TransactionsVisulizer from '../components/TransactionsVisulizer'
// import UnsufficientBalance from '../Modals/UnsufficientBalance'
import SureToContinueTopUp from '../Modals/InsufficientBalance_top_up'
import { useAlignModalAdjust } from '../context/AlignModalAdjustContext'
import WalletDeposit from '../Modals/WalletDeposit'
// import { useStateCountDown } from '../context/EnableCountDown'
import { redirect, useLoaderData, useNavigate } from 'react-router-dom' 
import UnsufficientBalance from '../Modals/UnsufficientBalance';
import { useStateCountDown } from '../context/EnableCountDown'   
import { getAllDeposits, userBalance, cardInfo } from '../security/AuthService' 
import Loader from '../jsx/pages/Loader/Loader' 
import DepositVisualizer from '../components/DepositVisualizer'

const LimitChart = loadable(() =>
	pMinDelay(import("./../jsx/components/Mophy/Dashboard/LimitChart"), 1000) 
);
// const transactions = [{
//   origin: 'XYZ Store ID',
//   date: {
//       day: 'June 4, 2020',
//       time: '05:34'
//   },
//   value: '+$5,553',
//   status: 'Completed'
// },
// {
//   origin: 'Chef Renata',
//   date: {
//       day: 'June 4, 2020',
//       time: '05:34'
//   },
//   value: '+$6,125',
//   status: 'Pending'
// },
// {
//   origin: 'Cindy Alexandro',
//   date: {
//       day: 'June 4, 2020',
//       time: '05:34'
//   },
//   value: '-$167',
//   status: 'Canceled'
// },
// {
//   origin: 'Paipal',
//   date: {
//       day: 'June 4, 2020',
//       time: '05:34'
//   },
//   value: '+$4,985',
//   status: 'Completed'
// },
// {
//   origin: 'Hawkins Jr.',
//   date: {
//       day: 'June 4, 2020',
//       time: '05:34'
//   },
//   value: '-$3,652',
//   status: 'Pending'
// }, 
// {
//   origin: 'Paipal',
//   date: {
//       day: 'June 4, 2020',
//       time: '05:34'
//   },
//   value: '+$4,985',
//   status: 'Completed'
// },
// {
//   origin: 'Hawkins Jr.',
//   date: {
//       day: 'June 4, 2020',
//       time: '05:34'
//   },
//   value: '-$3,652',
//   status: 'Pending'
// }]


const Dashboard = () => {
  const {stateCountDown, setStateCountDown} = useStateCountDown()
  // const [activeFilterdepositvTrx, setActiveFilterdepositTrx] = useState(null) 
  const activeFilterdepositvTrx = useRef(null)
  const [show, setShow] = useState(false)
  const result = useLoaderData() 
  // const result = {normal_deposit: ''} 
  const [unsufficientBalanceState, setUnsufficientBalanceState] = useState(false)
  // const [refAllow, setrefAllow] = useState(false)
  const refAllow = useRef(false) 
  const [clickDepositStatus, setClickDepositStatus] = useState(false) 
  const navigate = useNavigate() 

  const [balance, setBalance] = useState('') 
  const [dashboardataLoadingState, setDashboardataLoadingState] = useState(false) 
  const [depositTrx, setdepositTrx] = useState({data: []})
  const [filtereddepositTrx, setFiltereddepositTrx] = useState({data: []}) 
  const [creditLimit, setCreditLimit] = useState('0')
  useEffect(()=>{
    HandleAllDeposits() 
    console.log(result) 
    if(typeof result === 'number') {
      const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))
      console.log(decoded_payload['req'])
      if(decoded_payload['req'] === 'N' && result < 120) {
              handleShowUnsBalance()
              refAllow.current = true
      }
    //   if()
    } else if(typeof result === 'string') {
      refAllow.current = false
    } else if((typeof result === 'object')) {
      refAllow.current = false 
      console.log('ND') 
    }
  }, []) 
    // const handleClose = () => {setShow(false);                 initialRender.current = false}
    // const { adjustState, setAdjustState } = useAlignModalAdjust()
    const handleClose = () => {setShow(false); setStateCountDown(false)} 
    const handleShow = function(){
      // console.log(result.normal_deposit) 
      if(typeof result === 'string') {
        navigate('/thankyoufordeposit')         
      }
      if(result.normal_deposit) {
        setShow(true); setStateCountDown(true)
      } else if(!result.normal_deposit) {
        handleShowUnsBalance() 
        refAllow.current = true 
      }
      setClickDepositStatus(true) 
    }
    
    // const handleClose1 = () => setShow1(false);
    // const handleShow1 = () => setShow1(true);
    
    console.log(typeof result)
  
    console.log(stateCountDown) 
    function setRefFalse() {
      // setrefAllow(false)
      // a = false
      setStateCountDown(false)
    }
    function setRefTrue() {
      // setrefAllow(true)
      // a = true 
      setStateCountDown(true)
    }
    // function activeFilterPrevTrxHandler (e) {
    //     console.log(e.target.innerHTML)
    //     setActiveFilterPrevTrx(e.target.innerHTML)
    // }
    const handleShowUnsBalance = useCallback(function() {
      setUnsufficientBalanceState(true)
    }, [])
    const handleCloseUnsBalance = useCallback(function() {
      setUnsufficientBalanceState(false)
      // setAdjustState(false)
    }, [])
    const handleclickDepositStatusOff = useCallback(function() {
      setClickDepositStatus(false) 
    }, []) 
    let a = false 
    console.log(result) 
    console.log(refAllow.current)  
    async function HandleAllDeposits() {
      
      try {
          setDashboardataLoadingState(true) 
            const result_balance_wallet = await userBalance()
            const wallet_balance = await result_balance_wallet.json()
            setBalance(wallet_balance['data'][0]['balance']) 
            console.log(wallet_balance['data'][0]['balance'])     
            const result = await  getAllDeposits()
            const res = await result.json() 
            const card_info = await cardInfo()
            const result_card_info = await card_info.json()
            console.log(result_card_info)
            console.log(res['data'])    
            if(result_balance_wallet.ok && result.ok && card_info.ok) {
              setdepositTrx({...depositTrx, data: res['data'].reverse()})
              setFiltereddepositTrx({...filtereddepositTrx, data: res['data']}) 
              if(result_card_info['data'].length>0) {
                setCreditLimit(result_card_info['data'][0]['creditLimit'])
              } else {
                setCreditLimit('0') 
              }
                                        
              setDashboardataLoadingState(false) 
 
            } 
      } catch(err) {
        console.log(err)
      }
    }  
    function activeFilterdepositHandler (e) {
      // setactiveFilterdepositHandler(e.target.innerHTML)
      activeFilterdepositvTrx.current = e.target.innerHTML 
      handleFilterdeposit() 
  }
    function handleFilterdeposit() {
        
      if(activeFilterdepositvTrx.current==='Current Month') {
          const filteredData = depositTrx['data'].filter((trx)=> ((new Date().getMonth()+1) === (new Date(trx?.createdAt?.date).getMonth()+1)) && ((new Date().getFullYear()) === (new Date(trx?.createdAt?.date).getFullYear()))) 
          // console.log(a) 
          console.log('month')
         return  setFiltereddepositTrx({...filtereddepositTrx, data: filteredData})    
             
      } else if(activeFilterdepositvTrx.current === 'Current Week') {
          // setFiltereddepositTrx({...filteredTopupHistory, data: []}) 
          // const today = new Date('07-13-2024') 
          console.log('week') 
          const today = new Date()  
          const today_index = today.getDay() 
          const start_week = today.valueOf() - (today_index * (24*60*60*1000))
          const end_week = today.valueOf() + ((6 - today_index) * (24*60*60*1000))
          console.log(new Date(`${new Date(start_week).getFullYear()}-${new Date(start_week).getMonth()+1}-${new Date(start_week).getDate()}`))  
          console.log(new Date(new Date(`${new Date(end_week).getFullYear()}-${new Date(end_week).getMonth()+1}-${new Date(end_week).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000)))
          // console.log(new Date(s_w)) 
          // console.log(new Date(e_w).getFullYear(), new Date(e_w).getMonth()+1, new Date(e_w).getDate())  
          // console.log(new Date(`${new Date(e_w).getFullYear()}-${new Date(e_w).getMonth()+1}-${new Date(e_w).getDate()}`))     
          // console.log(new Date(new Date(`${new Date(e_w).getFullYear()}-${new Date(e_w).getMonth()+1}-${new Date(e_w).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000)))       
          const start_week_date = new Date(`${new Date(start_week).getFullYear()}-${new Date(start_week).getMonth()+1}-${new Date(start_week).getDate()}`)
          const end_week_date = new Date(new Date(`${new Date(end_week).getFullYear()}-${new Date(end_week).getMonth()+1}-${new Date(end_week).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000))
          // console.log(new Date(start_week_date).valueOf(), new Date(end_week_date).valueOf())   
          // console.log(today, today_index, new Date(1724944132547))  
          // console.log(trx?.createdAt?.date)   
          const filteredData = depositTrx['data'].filter((trx)=> (new Date(trx['createdAt']['date']).valueOf()>=new Date(start_week_date).valueOf() && new Date(trx['createdAt']['date']).valueOf() <= new Date(end_week_date).valueOf()))  
            // console.log(a) 
            // console.log(filteredData)
          // const filteredData = depositTrx['data'].filter((trx)=> (console.log(trx?.createdAt?.date)))  
          // console.log(a) 
          // console.log(filteredData)
         return  setFiltereddepositTrx({...filtereddepositTrx, data: filteredData}) 
          // previousTrx['data'].filter((trx)=> {return (new Date().getMonth()+1) === (new Date(trx['dateOfTransaction']).getMonth()+1)}) 
      } else if(activeFilterdepositvTrx.current === 'Today') { 
          // const today = new Date('07-15-2024') 
          const today = new Date()  
          const start_day = new Date(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`)
          const end_day = new Date(new Date(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000))
          // previousTrx['data'].filter((trx)=> {return (new Date().getMonth()+1) === (new Date(trx['dateOfTransaction']).getMonth()+1)})   
          const filteredData = depositTrx['data'].filter((trx)=> new Date(trx?.createdAt?.date).valueOf() >= new Date(start_day).valueOf() && new Date(trx?.createdAt?.date).valueOf() <= new Date(end_day).valueOf())  
          console.log(filteredData)  
          return  setFiltereddepositTrx({...filtereddepositTrx, data: filteredData})
      } else {

          return setFiltereddepositTrx(depositTrx['data']) 
      } 
  
}
  return (
    <>
    {!dashboardataLoadingState && <div className='dashboard-container'>
        <section className='dashboard-overview-container'>
            <figure className='available-balance'>
                <img src='/images/dashboard/dashboard/Group 398.svg' alt='paytabs card' />
                <img src='/images/dashboard/dashboard/Group 399 (1).svg' alt='paytabs card' />
                <span className='available-balance-euro'>Available balance in USDT</span>
                <span className='available-balance-euro-amount'>{balance}</span> 
                <Button imagesrc='/images/dashboard/dashboard/Background.svg' styles='deposit-btn' click={handleShow}>Deposit</Button>
            </figure>
            <div className="card bgl-primary card-body overflow-hidden p-0 d-none d-md-flex rounded " style={{height: '100%', marginBottom: '0px'}}>
							<div className="p-0 text-center mt-3">
									<span className="text-black">Limit</span>
									<h3 className="text-black fs-20 mb-0 font-w600">€{creditLimit}</h3> 
									<small>€{localStorage.getItem('c_l')}</small>
							</div>
							<div className="mt-auto line-chart-demo" style={{position: 'relative'}}>
									{/* <LimitChart /> */}
							</div>									
						</div>
            <div className='net-multiple-wrapper'>
              <img src='/images/dashboard/dashboard/Border.svg' alt='percentage' draggable='false' />
              {/* <span>10%</span>
              <span>Net multiple</span>
              <span>Last 15 days <span>200%</span></span> */}
              <span></span>
              <span></span>
              <span> <span></span></span>
            </div>

        </section>
        <section className='previous-transactions-container'>
          <div className='previous-transactions-wrapper'>
            <div className='previous-transactions-wrapper-header'>
                    <div className='previous-transactions-wrapper-header-text-content'>
                        <span>Previous Transactions</span>
                        {/* <span>Lorem ipsum dolor sit amet, consectetur</span> */}
                        {(depositTrx['data'].length > 0) && 
                        // <span>Lorem ipsum dolor sit amet, consectetur</span> 
                        <>
                        {(activeFilterdepositvTrx.current === 'Current Month') && <span>Current month's deposits</span>}
                        {(activeFilterdepositvTrx.current === 'Current Week') && <span>Current Week's deposits</span>}
                        {(activeFilterdepositvTrx.current === 'Today') && <span>Today's deposits</span>}
                        {(activeFilterdepositvTrx.current === null) && <span>All deposits</span>}                     
                        </>}
                    </div>
                    {(depositTrx['data'].length > 0) && <div className='previous-transactions-wrapper-header-filter'>
                        <button className={`${activeFilterdepositvTrx.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterdepositHandler} >Current Month</button>
                        <button className={`${activeFilterdepositvTrx.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterdepositHandler}>Current Week</button>
                        <button className={`${activeFilterdepositvTrx.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterdepositHandler}>Today</button>
                    </div>} 
                </div>
          <DepositVisualizer data={filtereddepositTrx['data']} />
          </div>
        </section>
    </div>}
    {(dashboardataLoadingState) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
{/* <UnsufficientBalance show={show} handleClose={handleClose} handleShowSuretoContinueTopUp={handleShow1} />
<SureToContinueTopUp show={show1} handleClose={handleClose1} initialRender={adjustState} />  */}
{(result?.normal_deposit && clickDepositStatus) && <WalletDeposit show={show} handleClose={handleClose} stateCountDown={stateCountDown} setRefFalse={setRefFalse} setRefTrue={setRefTrue} handleclickDepositStatusOff={handleclickDepositStatusOff} /> }   
{(!result?.normal_deposit && clickDepositStatus) && <UnsufficientBalance show={unsufficientBalanceState} handleClose={handleCloseUnsBalance} a={refAllow.current} handleclickDepositStatusOff={handleclickDepositStatusOff} />    }                                  
</>
  )
}

export default Dashboard

