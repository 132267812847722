import React, { useState, useEffect, useRef, useCallback } from 'react' 
import './CSS/walletdeposit.css' 
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button'
import SureToContinueTopUp from './InsufficientBalance_top_up'
import Loader from '../jsx/pages/Loader/Loader'
import { redirect, useNavigate } from 'react-router-dom'
import { useAlignModalAdjust } from '../context/AlignModalAdjustContext'
import { generateAddress, regenerateAddress, initialDeposit, normalDeposit, refreshToken, killToken, saveToken } from '../security/AuthService'
import { useStateCountDown } from '../context/EnableCountDown'
import { useActive } from '../context/ActiveContext' 
import QRCodeStyling from "qr-code-styling";  
import ConfirmTermsConditions from './ConfirmTermsConditions'
import Select from '../components/Select' 
const WalletDeposit = (props) => {
    const [isCopied, setIsCopied] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //initialise timer that would be displayed 
    const [timer, setTimer] = useState(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `) 
    //State to choose if top up modal should appear or the regeneration
    const [refisTimerEnabled, setRefisTimerEnabled] = useState(true)
    //state to check if the refersh of top up modal is enabled or not 
    const [requestRefresh, setRequestRefresh] = useState(false)
    //state to stock the address wallet
    const [addressValue, setAddressValue]= useState('')
    //state to check the loading status of wallet address
    const [loadAdd, setLoadAdd] = useState(false)
    // timer that will be uses in the countdown calculation
    const refTimerND = useRef(null)
    //
    const [error, setError] = useState(false)
    // const error = useRef(false)  
    //
    const [success, setSuccess] = useState(false) 
    // const [sendStatus, setSendStatus] = useState(false) 
    const sendStatus = useRef(false) 
    const cancel = useRef(false) 
    const navigate = useNavigate() 
    const [termsConditions, setTermsConditions] = useState(false)   
//   const termsConditions = useRef(false) 
  const [confirm_TC, setConfirm_TC] = useState(false)
    const { handleMenuActive } = useActive()  


    const [accountType, setAccountType] = useState('Blockchain')  
    // const {stateCountDown, setStateCountDown} = useStateCountDown()
    // console.log(props.stateCountDown, refTimerND.current, refisTimerEnabled, addressValue ,requestRefresh,error, success)         
    useEffect(()=>{
        if(props.stateCountDown) {
            generateAddressWallet()
        }
        // }
    }, [props.stateCountDown])  
    useEffect(()=>{ 
        //Timer Count Down will be started only if stateCountDown is enabled
        if(props.stateCountDown) {
            if(timer === '-1:-1' || !refisTimerEnabled || timer.includes('-')) {
                handleShowrequestRefresh() 
                setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `)
                // console.log("CATCH YOU")  
            }
            
            if(refisTimerEnabled && addressValue) {
                var x = setInterval(function() {
                    // Get today's date and time
                    
                    const now = new Date()
                    // Find the distance between now and the count down date
                    var distance = refTimerND.current - now.getTime();
                    // Time calculations for minutes and seconds
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    // console.log(''+minutes) 
                    setTimer(`${(''+minutes).length === 1 ? '0' + minutes : minutes}:${(''+seconds).length === 1 ? '0' + seconds : seconds}`) 
                    // If the count down is over, write some text 
                    if (distance < 0) {
                        clearInterval(x);
                            setRefisTimerEnabled(false)
                            setRequestRefresh(true)
                            setTermsConditions(false)
                            setConfirm_TC(false) 
                        // console.log('TIMER ', refTimerND.current, ' SEND STATUS ', sendStatus.current)  
                        if(refTimerND.current === 0 && sendStatus.current) {
                            // console.log('YES 0')  
                            setError(false)
                            setSuccess(true)  
                            return null 
                        } else {    
                            setError(true) 
                            setSuccess(false)  
                        }
                        if(cancel.current) {
                            // console.log('CANCEL')        
                            setRefisTimerEnabled(true)

                        }
                            
                        // setSuccess(false)     
                        // error.current = false         
                    }
                }, 1000); 
            }
        }
    }, [timer, refisTimerEnabled, addressValue, props.stateCountDown, error, success, sendStatus.current, cancel.current])    
    async function generateAddressWallet() {
        try {
            // loadAddress()
             
            setLoadAdd(true)
            let result = await generateAddress() 
            // If fetch returns 401 
            if(result.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                    killToken()
                    return navigate('/login') 
                } else {
                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                    result = await generateAddress() 
                }

            } 

            const message =  await result.json()
            // console.log(message.message, message.data[0]['value']) 
            cancel.current = false
             if(result.ok && message.message === 'success' && message.data[0]['value']) {
                setLoadAdd(false)
                setAddressValue(message.data[0]['value'])
                refTimerND.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000 
                // setSuccess(true) 
                // console.log(props.a)  
             } else {
                setLoadAdd(false)
                setAddressValue('')
             }
        } catch(err) {
            console.log(err) 
            // setAddress({value: '', loading: false})
        }
    }  
    
    async function regenerateAddressWallet() {
        try {
            // loadAddress()
            refTimerND.current = 0
            setLoadAdd(true)
            let result = await regenerateAddress() 
            // If fetch returns 401 
            if(result.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                    killToken()
                    return navigate('/login') 
                } else {
                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                    result = await regenerateAddress()  
                }

            }

            const message =  await result.json()
            // console.log('REGENERATION: ', message.message, message.data[0]['value']) 
            setLoadAdd(false)
             if(result.ok && message.message === 'success' && message.data[0]['value']) {
                //  getAddressValue() 
                // address.current.loading = false
                // address.current.value = message.data[0]['value']
                // console.log(address.current)
                // setLoadAdd(false)
                setAddressValue(message.data[0]['value'])
                refTimerND.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000
                setError(false)
                // setSuccess(false) 

             } else {
                // setLoadAdd(false)
                setAddressValue('')
             }
        } catch(err) {
            console.log(err) 
            // setAddress({value: '', loading: false})
        }
    }
    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('wallet-address-value1').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
             
        } catch (err) {
            setIsCopied(false)
        }
    }
    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }

    const handleShowrequestRefresh = useCallback(function(){
        setRequestRefresh(true)
        // setError(true) 
        // requestRefresh.current = true
        // error.current = true 
    }, [])
    const handleCloserequestRefresh = useCallback(function(){
        // generateAddress()
        regenerateAddressWallet()
        // refisTimerEnabled.current = true
        // regenerateAddress()
        props.setRefTrue()
        refTimerND.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000
        setRefisTimerEnabled(true); 
        setRequestRefresh(false)
        // requestRefresh.current = false
        
        
        // return redirect('/ordercard')
    }, [])
    const handleCancel = useCallback(function() {
        // props.handleClose();
        let path_ = window.location.pathname;
        path_ = path_.split("/");
        path_ = path_[path_.length - 1]
        if(path_ === 'depositfund') {
            navigate('/dashboard')
            handleMenuActive('Dashboard') 
            handleMenuActive('Dashboard') 

        } 
        
        // cancel.current = true
        // refTimerND.current = 0;
         
        cancel.current = true
        refTimerND.current = 0; 
        setRequestRefresh(false); 
         
        setRefisTimerEnabled(true)  
        setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `)  
        sendStatus.current = false  
         
        // let path = window.location.pathname;
        // path = path.split("/");
        // path = path[path.length - 1]
        // console.log(path) 
        // if(path === 'depositfund') {
        //     return redirect('/dashboard') 
        // }

        if(props.handleclickDepositStatusOff() !== null) {
            // console.log('NOT NULL') 
        } 
        props.handleclickDepositStatusOff()                                  
    }, [])
    const handleOKDeposit = useCallback(function() { 
        setRequestRefresh(false); 
        // props.handleClose(); 
        setRefisTimerEnabled(true); 
        setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `) 
        sendStatus.current = false 
        // if(props.handleclickDepositStatusOff() !== null) {
        //     console.log('NOT NULL')
        // }
        // props.handleclickDepositStatusOff()
        let path = window.location.pathname;
        path = path.split("/");
        path = path[path.length - 1]
        
        // console.log(path) 
    }, []) 
    const generateQRCode = (data) => { 
        const qrCode = new QRCodeStyling({ 
          width: 126, 
          height: 130, 
          data: data,  
          image: "https://paytaps.com/images/dashboard/header/paytaps%20icon.svg", // Your logo  
          dotsOptions: { 
            gradient: { 
              type: "radial",  
              colorStops: [ 
                { offset: 0, color: "#4196FC" },
                { offset: 0.5, color: "#4A61D4" },
                { offset: 1, color: "#5520A3" }    
              ], 
            }, 
            type: "rounded", // Dot shape 
          }, 
          backgroundOptions: { 
            gradient: { 
              type: "radial", // 'linear' or 'radial' gradient 
              colorStops: [ 
                { offset: 0, color: "#ffffff" }, // Center color 
                { offset: 1, color: "#ffffff" }, // Outer color 
              ], 
            }, 
          }, 
          imageOptions: { 
            margin: 5, // Margin around the logo 
            scale: 0.4, // Scale the logo (0.1 to 1.0, where 1.0 is the original size)                                                                 
          }, 
          cornersSquareOptions: { 
            color: "#5520A3", // Color for the corner squares 
            type: "extra-rounded", // Shape: 'square', 'rounded', 'extra-rounded' 
          }, 
          cornersDotOptions: { 
            color: "#5520A3", // Color for the dots inside the corner squares 
            type: "rounded", // Shape: 'square', 'dot', 'rounded' 
          },  
        });       
     
        qrCode.append(document.getElementById("qrcodenormal")); // Append the QR code to the container 
      };
      useEffect(() => { 
        if (addressValue) { 
          generateQRCode(addressValue); // Generate QR code with the fetched data 
        } 
      }, [addressValue]); 
      const handleShowTermsConditions = useCallback(function() {
        setTermsConditions(true) 
        // termsConditions.current = true
      }, [])
    
      const handleCloseTermsConditions = useCallback(function() {
        setTermsConditions(false)   
        // termsConditions.current = false 
      }, []) 
      const handleConfirmTermsConditions = function(e) { 
        // console.log(document.querySelector('input[name="agree_terms_conditions"]').checked, termsConditions)  
          
          if(!termsConditions) {
            if(confirm_TC) {
              return setConfirm_TC(false) 
            }
          handleShowTermsConditions() 
          // console.log('IN')
          // document.querySelector('input[name="agree_terms_conditions1"]').checked = true   
  
        } else {
          // handleShowTermsConditions() 
          if(confirm_TC) {
            return setConfirm_TC(false) 
          }
          handleCloseTermsConditions() 
          // console.log('OUT') 
          // document.querySelector('input[name="agree_terms_conditions1"]').checked = false   
        }
    }  
    const handleAccountType = useCallback(function(payload) {
        setAccountType(payload) 
    }, [])  
    function handleResetForm(e) {
        state.resetForm()
        state.setFieldValue('hash_trx', '')
        state.setFieldValue('uid', '')   
        setTermsConditions(false)
        setConfirm_TC(false)  

    }
  return (
    <>
    {refisTimerEnabled && <Modal show={props.show} onHide={props.handleClose} className='wallet_deposit' backdrop='static' keyboard='false' >
        
        
        <Modal.Header>
        <div className='timer'><img src='/images/dashboard/topup/tabler_alarm.svg' alt='blue alarm' /><span>{timer}</span></div> 
        
        <div className='insufficient-balance'>
            Wallet deposit 
        </div> 
        
    </Modal.Header>
    
    
    <Formik
                        initialValues={
                            accountType === 'Blockchain' ? 
                            {
                            topup_amount: '',
                            hash_trx: '',
                            uid: null
                            } :
                            {
                            topup_amount: '',
                            uid: '', 
                            hash_trx: null
                            } 
                        } 
                        validateOnChange={ true }
                        validateOnBlur={ true } 
                        validationSchema={
                            accountType === 'Blockchain' ?
                            YUP.object({
                                topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid topu up amount</span>}).test('normal deposit', `Please enter at least $${localStorage.getItem('m_n_d')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_n_d'))),  
                                hash_trx: YUP.string().required('Required!')  
                            }) 
                            :
                            YUP.object({
                                topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid topu up amount</span>}).test('normal deposit', `Please enter at least $${localStorage.getItem('m_n_d')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_n_d'))),  
                                uid: YUP.string().required('Required!').matches(/^[0-9]+$/, { message: <span>Please enter a valid UID</span>}).min(8, 'Please enter a valid UID'), 
                            }) 
                        }

                        onSubmit={async (values, {setSubmitting, resetForm})=>{
                            try {
                                let account_type = `${(accountType === 'Blockchain') ? 'blockchain' : 'ciexAccount'}`   

                                if(account_type === 'blockchain') {
                                    let result = await normalDeposit({hashTransaction: values.hash_trx, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: null}) 
                                    // If fetch returns 401 
                                    if(result.status === 401) {
                                        const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                                        // If refresh token returns 401
                                        if(refreshTokenResult.status === 401) {
                                        killToken()
                                        return navigate('/login')
                                        } 

                                        // If refresh token is successfull 
                                        const payloadRefreshToken = await refreshTokenResult.json()   

                                        let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                                        // Payload of new generated token 
                                        let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                                        if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                                            killToken()
                                            return navigate('/login') 
                                        } else {
                                            saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                                            result = await normalDeposit({hashTransaction: values.hash_trx, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: null})   
                                        }

                                    }

                                    const message = await result.json()
                                    if(result.ok && message.message === 'success') {
                                        navigate('/thankyoufordeposit')
                                        sendStatus.current = true
                                        handleMenuActive('') 
                                    }
                                } else {
                                    let result = await normalDeposit({hashTransaction: null, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: values.uid}) 
                                    // If fetch returns 401 
                                    if(result.status === 401) {
                                        const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                                        // If refresh token returns 401
                                        if(refreshTokenResult.status === 401) {
                                        killToken()
                                        return navigate('/login')
                                        } 

                                        // If refresh token is successfull 
                                        const payloadRefreshToken = await refreshTokenResult.json()   

                                        let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                                        // Payload of new generated token 
                                        let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                                        if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                                            killToken()
                                            return navigate('/login') 
                                        } else {
                                            saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                                            result = await normalDeposit({hashTransaction: null, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: values.uid})   
                                        }

                                    }   

                                    const message = await result.json()
                                    if(result.ok && message.message === 'success') {
                                        navigate('/thankyoufordeposit')
                                        sendStatus.current = true
                                        handleMenuActive('')  
                                    }
                                }

                                refTimerND.current = 0
                                props.setRefFalse()
                                setRefisTimerEnabled(true) 
                                setRequestRefresh(false)
                                props.handleClose()
                                setError(false)  
                            } catch(err) {

                            } 
                        }} 
                    >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                <Select handleAccountType={handleAccountType} handleResetForm={handleResetForm} />  
                                {accountType === 'Blockchain' && <TextInput name='hash_trx' id='hash_trx_wallet-deposit' label='Enter Hash Transaction' 
                                inputtype='text' onKeyUp={keyUpHandler} /> }
                                {accountType === 'CIEx Account' && <TextInput name='uid' id='uid' label='Enter Account UID'        
                                inputtype='text' onKeyUp={keyUpHandler} /> } 
                                <TextInput name='topup_amount' id='topup_amount' label='Enter amount' 
                                inputtype='text' onKeyUp={keyUpHandler} /> 
                                  


                                {!loadAdd && <div className='wallet-address-wrapper'>
                                    <div className='use-wallet-address-provided-for-deposit'>Use the wallet address provided below to make the deposit.</div> 
                                    <div id='qrcodenormal'></div>
                                    <div>
                                        <div>
                                            <img src='/images/dashboard/topup/tabler_wallet.svg' alt='wallet' />
                                            <div className='wallet-address'>
                                                <span>Wallet address (TRC20)</span>
                                                <span id='wallet-address-value1'>{addressValue}</span>
                                            </div>
                                        </div>
                                        <button className='copy-btn' type='button' onClick={copyAddress}> 
                                            <img src='/images/dashboard/topup/tabler_copy.svg' 
                                            alt='2 squares with the highest one its right half clipped' hidden={isCopied} /> 
                                            <span hidden={!isCopied}>Copied!</span>
                                        </button>
                                    </div>
                                </div>}
                                {loadAdd && <div className='d-flex justify-content-center'><Loader /></div>} 
                                {/* <label className='check-term-conditions'>
                                    <Field type="checkbox" name="agree_terms_conditions" />
                                    <span> Agree to the Terms and Conditions and Privacy Policy.</span> 

                                </label> */} 
                                <div className='check-term-conditions'>
                                      <Field type="checkbox" name="agree_terms_conditions_normal_deposit" checked={termsConditions || confirm_TC}  onClick={handleConfirmTermsConditions} />   
                                      <span style={{color: 'var(--black-color)'}}> I Agree to the Terms and Conditions.</span>  
                                    </div>

                                
                                {/* <ButtonBootstrap variant="secondary" onClick={handleClose}>
                                    
                                </ButtonBootstrap>
                                <button type='submit' id='confirm_topup'></button> */}
                                {/* <Button click={handleClose}>Cancel</Button> */}
                                <div className='group-btn'> 
                                    <Button click={handleCancel} id="cancel">Cancel</Button>                                       
                                    {/* <Button btnType='submit' id='confirm_wallet_normal_deposit' disabled={!formik.values.agree_terms_conditions || !(formik.dirty && formik.isValid) || formik.isSubmitting || !refisTimerEnabled}>Confirm</Button>    */}
                                    {accountType === 'Blockchain' && <Button btnType='submit' id='confirm_wallet_normal_deposit_hash' disabled={(!document.querySelector('input[name="agree_terms_conditions_normal_deposit"]')?.checked) || !( formik.isValid) || formik.isSubmitting || !refisTimerEnabled}>Confirm</Button>  }
                                    {accountType === 'CIEx Account' && <Button btnType='submit' id='confirm_wallet_normal_deposit_uid' disabled={(!document.querySelector('input[name="agree_terms_conditions_normal_deposit"]')?.checked) || !( formik.isValid) || formik.isSubmitting || !refisTimerEnabled}>Confirm</Button> }   

                                </div>
                                
                                {/* <Button btnType='submit' id='submit_topup' disabled={!formik.values.agree_terms_conditions || !(formik.dirty && formik.isValid) || formik.isSubmitting || !refisTimerEnabled} styles='submit-btn'>Submit</Button>  */}
                                
                                
                            </Form>)}}
    </Formik>
    
    
    </Modal>}
    {!refisTimerEnabled && 
        <Modal show={requestRefresh} className='loader-error' backdrop='static' keyboard='false'>
            <Modal.Body className='center'>
                {(error && !success) && <div className='errorMessageContainer'> <figure><img src='/images/dashboard/topup/refresh.svg' width='150' /></figure><div>Times out</div><div className='messageError'>It seems your session has timed out. Please refresh the page to continue. If you need assistance, feel free to contact our support team.</div> <Button styles= 'try-again-btn' click={handleCloserequestRefresh}>Refresh</Button></div>}  
                {(success && !error) && <div>
                    
                    <div>
                    <div className='thankyoudepositContainer'> <figure><img src='/images/dashboard/topup/Wallet-pana 1 (1).svg' /></figure><div>Thank you for Deposit</div><div className='messagethankyoudeposit'>thank you for you Paytaps wallet deposit
                    you'll receive and email notification once your deposit is confirmed</div> <Button styles= 'okThankyouDeposit' click={handleOKDeposit}>Ok</Button></div>
                        {/* <figure><img src='/images/dashboard/topup/Wallet-pana 1 (1).svg' width='150' /></figure>  */}
                    </div> 
</div>}
                {/* <Button styles= 'try-again-btn' click={()=> {setRequestRefresh(false); props.handleClose(); setRefisTimerEnabled(true); setTimer('30') }}>Refresh</Button></div>}  */}
            </Modal.Body>
        </Modal>
    }
    <ConfirmTermsConditions show={termsConditions} handleShowTermsConditions={handleShowTermsConditions} handleCloseTermsConditions={handleCloseTermsConditions} confirm_TC={()=>setConfirm_TC(true)} />
    </>
  )
}

export default WalletDeposit  
 