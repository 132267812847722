import React from 'react'
import { Accordion } from 'react-bootstrap' 
import './CSS/transactionsVisualizer.css'   
const TransactionsVisulizer = (props) => {
    //console.log(props.data) 
  return (
    <>
        {((props.data?.length > 0) && props.data !== null && props.data !== undefined ) && <div className='transactions-wrapper-body'>
            <ul className='transactions-wrapper-body-list'>
                {props.data.map((item, index)=>{
                    // item['transactionMode'] === 'Credit'                  
                    // console.log(typeof item['transactionResponse'])                  
                    return (
                            
                            <Accordion key={index}>  
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                            <li  className='transactions-wrapper-body-list-transaction-data-item'>
                                                <figure className='transaction-status-image'>
                                                    {   ((item['transactionResponse'] === '80') && item['transactionType'] === 'Card_Load')  ?                     
                                                        <img src='/images/dashboard/mycard/transaction-green.svg' alt='green arrow: credited' />  :
                                                        <img src='/images/dashboard/mycard/transaction-red.svg' alt='red arrow: debited' /> 
                                                        // (item.status === 'Completed' ? 
                                                        // (<img src='/images/dashboard/mycard/transaction-green.svg' alt='completed transaction' /> ) :
                                                        // ((item.status === 'Pending') ?
                                                        //     (<img src='/images/dashboard/mycard/transaction-red.svg' alt='pending transaction' />) :
                                                        //     (<img src='/images/dashboard/mycard/transaction-red.svg' alt='pending transaction' />)) )       
                                                    }
                                                </figure>
                                                <div>
                                                    <span className='transaction-origin'>{`${(item['merchantName'] === '') ? 'TRANS_FEES' : item['merchantName']}`}</span> 
                                                    <div className='transaction-date'><span>{`${new Date(item['dateOfTransaction']).toLocaleString('default', { month: 'long' }).substring(0,3)} ${(new Date(item['dateOfTransaction']).getDate() > 9) ? new Date(item['dateOfTransaction']).getDate() : '0'+new Date(item['dateOfTransaction']).getDate()}, ${new Date(item['dateOfTransaction']).getFullYear()}`}</span><span>{`${(new Date(item['dateOfTransaction']).getHours() > 9) ? new Date(item['dateOfTransaction']).getHours() : '0'+new Date(item['dateOfTransaction']).getHours()}:${(new Date(item['dateOfTransaction']).getMinutes() > 9) ? new Date(item['dateOfTransaction']).getMinutes() : '0'+new Date(item['dateOfTransaction']).getMinutes()}`}</span></div>
                                                    <span className='transaction-value'>${item['authAmount']}</span>
                                                    <span className='transaction-status'>Completed</span>
                                                </div>
                                            </li>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='transactionDetailsWrapper'>
                                            <div className='transactionDetailItem'>
                                                <span className='transactionDetailItemLabel'>Transaction ID</span>
                                                <span className='transactionDetailItemValue text-black font-w500'>{item['transactionId']}</span>
                                            </div> 
                                            <div className='transactionDetailItem'>
                                                <span className='transactionDetailItemLabel'>RNN</span>
                                                <span className='transactionDetailItemValue text-black font-w500'>
                                                    {item['retrievalReferenceNumber']}
                                                </span>
                                            </div> 
                                            <div className='transactionDetailItem'>
                                                <span className='transactionDetailItemLabel'>Country</span>
                                                <span className='transactionDetailItemValue text-black font-w500'>
                                                    {`${item['transactionCountry'] ? item['transactionCountry'] : '--'}`} 
                                                </span> 
                                            </div>
                                            <div className='transactionDetailItem'>
                                                <span className='transactionDetailItemLabel'>Paid Date</span>
                                                <span className='transactionDetailItemValue text-black font-w500'>{`${new Date(item['dateOfTransaction']).toLocaleString('default', { month: 'long' })} ${(new Date(item['dateOfTransaction']).getDate() > 9) ? new Date(item['dateOfTransaction']).getDate() : '0'+new Date(item['dateOfTransaction']).getDate()}, ${new Date(item['dateOfTransaction']).getFullYear()}`}</span>  
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>)
                    
                    })}
            </ul>
        </div>
        }
        {
            (props.data?.length === 0 || props.data === null || props.data === undefined ) && <div className='noRecords'>No Records</div> 
        }
    </>
  )
    
}

export default TransactionsVisulizer