import React from 'react'
import { Modal } from 'react-bootstrap'
const ErrorModalMessage = (props) => {
  return (
    <>
    {props.isError && <Modal show={props.isError} onHide={props.handleClose}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header> */}
          <Modal.Body className='center'>
            {props.message}
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>}
        </>
  )
}

export default ErrorModalMessage
