import React, { useState, useEffect, useCallback } from 'react'  
import Topup from '../Modals/Topup'  
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up' 
const TopUpCard = () => {
    const [show, setShow] = useState(false) 
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  
    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);
    useEffect(()=>{
        setShow(true) 
    }, []) 
    const handleClose = useCallback(function() {
        setShow(false) 
    }, []) 
  return (
    
    <>
        <Topup show={show} handleClose={handleClose} handleShowInsufTopupBalance={handleShowInsufTopupBalance} />   
        <InsufficientBalance_top_up show={showInsufficientTopupBalance} handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} handleShowInsufTopupBalance={handleShowInsufTopupBalance} />   
    </>
  )
}

export default TopUpCard 