import React, { useState, useEffect, useRef, useCallback  } from 'react' 
import './CSS/unsufficientbalance.css'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button'
import SureToContinueTopUp from './InsufficientBalance_top_up'
import Loader from '../jsx/pages/Loader/Loader'
import { redirect, useNavigate } from 'react-router-dom'
import { useAlignModalAdjust } from '../context/AlignModalAdjustContext'
import { generateAddress, regenerateAddress, initialDeposit } from '../security/AuthService' 
// import QRCodeStyling from "qr-code-styling"; 
import QRCode from 'react-qr-code' 
import QRCodeStyling from "qr-code-styling";   
import ConfirmTermsConditions from './ConfirmTermsConditions' 
import Select from '../components/Select' 


const UnsufficientBalance = (props) => {
    

    
    const navigate = useNavigate()
    const { setAdjustState } = useAlignModalAdjust()
    const [isCopied, setIsCopied] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [timer, setTimer] = useState(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `)
    const [refisTimerEnabled, setRefisTimerEnabled] = useState(true)
    const [requestRefresh, setRequestRefresh] = useState(false)
    // const [address, setAddress] = useState({value: '', loading: false})
    // const address = useRef({value: '', loading: false})
    const [addressValue, setAddressValue]= useState('')
    const [loadAdd, setLoadAdd] = useState(false)
    // const requestRefresh = useRef(true)
    const [error, setError] = useState(false) 
    // const 
    //180000 3min
    //300000 5min
    // const refTimerIns = useRef(new Date().getTime() + 18001)
    const refTimerIns = useRef(null)

    // const termsConditions = useRef(false) 
    // const [confirm_TC, setConfirm_TC] = useState(false)
    // const [termsConditions1, setTermsConditions1] = useState(false) 

    
    const [termsConditions, setTermsConditions] = useState(false)   
//   const termsConditions = useRef(false) 
  const [confirm_TC, setConfirm_TC] = useState(false)
    
    const [qrData, setQrData] = useState(null); 
    const [accountType, setAccountType] = useState('Blockchain')  

    const handleShowrequestRefresh = useCallback(function(){
        setRequestRefresh(true)
        // requestRefresh.current = true
    }, [])
    // const getAddressValue = useCallback((payload)=>{
    //     address.current = {value: payload, loading: false}
    // }, [])
    // const loadAddress = useCallback(()=>{
    //     address.current = {value: '', loading: true}
    // }, [])
    const handleCloserequestRefresh = useCallback(function(){
        // generateAddress()
        regenerateAddressWallet()
        // refisTimerEnabled.current = true
        refTimerIns.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000
        setRefisTimerEnabled(true); 
        setRequestRefresh(false)
        // requestRefresh.current = false
        
        
        // return redirect('/ordercard')
    }, [])
    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('wallet-address-value').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
            setIsCopied(false)
        }
    }
    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }
    // const countdownTime = new Date().getTime() + 18001
    async function generateAddressWallet() {
        try {
            // loadAddress()
            setLoadAdd(true)
            const result = await generateAddress()
            const message =  await result.json()
            console.log(message.message, message.data[0]['value'])
             if(result.ok && message.message === 'success' && message.data[0]['value']) {
                //  getAddressValue() 
  
                setLoadAdd(false)
                setAddressValue(message.data[0]['value'])
                // setQrData(message.data[0]['value'])
                refTimerIns.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000
                console.log(refTimerIns.current)  

             } else {
                setLoadAdd(false)
                setAddressValue('')
             }
        } catch(err) {
            console.log(err) 
            // setAddress({value: '', loading: false})
        }
    }
    async function regenerateAddressWallet() {
        try {
            // loadAddress()
            setLoadAdd(true)
            const result = await regenerateAddress()
            const message =  await result.json()
            console.log('REGENERATION: ', message.message, message.data[0]['value'])
             if(result.ok && message.message === 'success' && message.data[0]['value']) { 
                setLoadAdd(false)
                setAddressValue(message.data[0]['value'])
                // setQrData(message.data[0]['value']) 
                refTimerIns.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000 

             } else {
                setLoadAdd(false)
                setAddressValue('')
             }
        } catch(err) {
            console.log(err) 
            // setAddress({value: '', loading: false})
        }
    }
    useEffect(()=>{
        // const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))
        // if((decoded_payload['req'] === 'N' || decoded_payload['req'] !== 'D') && balance === 0) {
            generateAddressWallet()
        // }
    }, [])
    useEffect(()=>{
        // getAddressWallet()
        if(props.a) {
        if(timer === '-1:-1' || !refisTimerEnabled) {
                    handleShowrequestRefresh()
                    // setAdjustState(false)
                    // props.checkrefisTimerEnabled_Uns_Bal(true) 

            setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `) 
                        // handleShowrequestRefresh()
        }
        // console.log(currentDate.getTime() + 180000)
        // if(!refisTimerEnabled) {
        //     handleShowrequestRefresh()
        //     setTimer('03:00')
        // }
        if(refisTimerEnabled && addressValue) {

        
        var x = setInterval(function() {

            // Get today's date and time
            const now = new Date()
            
              
            // Find the distance between now and the count down date
            var distance = refTimerIns.current - now.getTime();
              
            // Time calculations for minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
              console.log(''+minutes)
            setTimer(`${(''+minutes).length === 1 ? '0' + minutes : minutes}:${(''+seconds).length === 1 ? '0' + seconds : seconds}`) 
              
            // If the count down is over, write some text 
            if (distance < 0) {
              
            //   requestRefresh.current = false
            // error.current = true
              clearInterval(x);
              setRefisTimerEnabled(false)
              
              setTermsConditions(false)
              setConfirm_TC(false) 
              // setTermsConditions1(false)
              // setConfirm_TC(false) 
            //   props.checkrefisTimerEnabled_Uns_Bal(false) 
              
            }
          }, 1000);
        }
    }
    }, [timer, refisTimerEnabled, addressValue, handleShowrequestRefresh, props.a]) 
    // const handleShowTermsConditions = useCallback(function() {
    //     setTermsConditions1(true) 
    //     // termsConditions.current = true
    //   }, [])
    //   const handleCloseTermsConditions = useCallback(function() {
    //     setTermsConditions1(false)   
    //     // termsConditions.current = false 
    //   }, [])
    //   const handleConfirmTermsConditions = function(e) { 
    //     // console.log(document.querySelector('input[name="agree_terms_conditions"]').checked, termsConditions1)  
          
    //       if(!termsConditions1) {
    //         if(confirm_TC) {
    //           return setConfirm_TC(false) 
    //         }
    //       handleShowTermsConditions() 
    //       // console.log('IN')
    //       // document.querySelector('input[name="agree_terms_conditions1"]').checked = true   
  
    //     } else {
    //       // handleShowTermsConditions() 
    //       if(confirm_TC) {
    //         return setConfirm_TC(false) 
    //       }
    //       handleCloseTermsConditions() 
    //       // console.log('OUT') 
    //       // document.querySelector('input[name="agree_terms_conditions1"]').checked = false   
    //     }
    // }
    const handleCloseTermsTC = useCallback(function() {
        setConfirm_TC(false)
      }, [])
      const generateQRCode = (data) => { 
        const qrCode = new QRCodeStyling({ 
          width: 126, 
          height: 130, 
          data: data, 
        //   image: "https://paytaps.com/logo_paytaps_ico.png", // Your logo 
          dotsOptions: { 
            gradient: { 
              type: "radial", // Type of gradient ('linear', 'radial') 
              colorStops: [ 
                { offset: 0, color: "#00FF00" }, // Start color 
                { offset: 1, color: "#4267b2" }, // End color 
              ], 
            }, 
            type: "rounded", // Dot shape 
          }, 
          backgroundOptions: { 
            gradient: { 
              type: "radial", // 'linear' or 'radial' gradient 
              colorStops: [ 
                { offset: 0, color: "#ffffff" }, // Center color 
                { offset: 1, color: "#e9ebee" }, // Outer color 
              ], 
            }, 
          }, 
          imageOptions: { 
            margin: 10, // Margin around the logo 
            scale: 0.4, // Scale the logo (0.1 to 1.0, where 1.0 is the original size) 
          }, 
          cornersSquareOptions: { 
            color: "#4267b2", // Color for the corner squares 
            type: "extra-rounded", // Shape: 'square', 'rounded', 'extra-rounded' 
          }, 
          cornersDotOptions: { 
            color: "#4267b2", // Color for the dots inside the corner squares 
            type: "rounded", // Shape: 'square', 'dot', 'rounded' 
          }, 
        }); 
     
        qrCode.append(document.getElementById("qrcode")); // Append the QR code to the container 
      };
      useEffect(() => { 
        if (addressValue) { 
          generateQRCode(addressValue); // Generate QR code with the fetched data 
        } 
      }, [addressValue]);
       
      const handleShowTermsConditions = useCallback(function() {
        setTermsConditions(true) 
        // termsConditions.current = true
      }, [])
    
      const handleCloseTermsConditions = useCallback(function() {
        setTermsConditions(false)   
        // termsConditions.current = false 
      }, []) 
      const handleConfirmTermsConditions = function(e) { 
        // console.log(document.querySelector('input[name="agree_terms_conditions"]').checked, termsConditions)  
          
          if(!termsConditions) {
            if(confirm_TC) {
              return setConfirm_TC(false) 
            }
          handleShowTermsConditions() 
          // console.log('IN')
          // document.querySelector('input[name="agree_terms_conditions1"]').checked = true   
  
        } else {
          // handleShowTermsConditions() 
          if(confirm_TC) {
            return setConfirm_TC(false) 
          }
          handleCloseTermsConditions() 
          // console.log('OUT') 
          // document.querySelector('input[name="agree_terms_conditions1"]').checked = false   
        }
    }
    const handleAccountType = useCallback(function(payload) {
      setAccountType(payload) 
    }, [])  
    function handleResetForm(e) {
        state.resetForm()
        state.setFieldValue('hash_trx', '')
        state.setFieldValue('uid', '')   
        setTermsConditions(false)
        setConfirm_TC(false)  

    } 
  return (
    <>
    {refisTimerEnabled &&
    
        <Modal show={props.show} onHide={props.handleClose} className='unsufficient-balance-topup' backdrop='static' keyboard='false' >
        
        
            <Modal.Header>
            <div className='timer'><img src='/images/dashboard/topup/tabler_alarm.svg' alt='blue alarm' /><span>{timer}</span></div> 
            <figure>
                <img src='/images/dashboard/topup/Group 318.svg' alt='wallet containing fiat money and coins' />
            </figure>
            <div className='insufficient-balance'>
                Insufficient  Balance 
            </div>
            <div className='insufficient-balance-deposit-120-dollar'>
                It appears that your current balance is insufficient to order a card. To proceed, please deposit at least <span>${localStorage.getItem('m_f_d')}</span>.
            </div>
        </Modal.Header>
        {/* <Modal.Body className='center'> */}
        {/* <button type='button' class="btn-close" aria-label="Close"><img src='/images/dashboard/topup/cross.svg'  /></button> */}
        
        <Formik
                            initialValues={accountType === 'Blockchain' ? 
                              {
                              topup_amount: '',
                              hash_trx: '',
                              uid: null
                              } :
                              {
                              topup_amount: '',
                              uid: '', 
                              hash_trx: null
                              }}
                            validateOnChange={ true }
                            validateOnBlur={ true } 
                            validationSchema={
                              accountType === 'Blockchain' ?
                              YUP.object({
                                  topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid topu up amount</span>}).test('normal deposit', `Please enter at least $${localStorage.getItem('m_f_d')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_f_d'))),  
                                  hash_trx: YUP.string().required('Required!') 
                              }) 
                              :
                              YUP.object({
                                  topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid topu up amount</span>}).test('normal deposit', `Please enter at least $${localStorage.getItem('m_f_d')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_f_d'))),   
                                  uid: YUP.string().required('Required!').matches(/^[0-9]+$/, { message: <span>Please enter a valid UID</span>}).min(8, 'Please enter a valid UID'), 
                              }) 
                            }

                            onSubmit={async (values, {setSubmitting, resetForm})=>{
                                console.log(values)
                                let account_type = `${(accountType === 'Blockchain') ? 'blockchain' : 'ciexAccount'}`   

                                if(account_type === 'blockchain') {
                                  const result = await initialDeposit({hashTransaction: values.hash_trx, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: null}) 
                                  const message = await result.json()
                                  console.log(message)
                                  if(result.ok && message.message === 'success') {
                                      navigate('/thankyoufordeposit')
                                  }
                                  if(!result.ok) {
                                    setError(true)
                                  }
                              } else {
                                  const result = await initialDeposit({hashTransaction: null, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: values.uid})   
                                  const message = await result.json()
                                  console.log(message) 
                                  if(result.ok && message.message === 'success') {
                                      navigate('/thankyoufordeposit')
                                  }
                                  if(!result.ok) {
                                    setError(true)
                                  } 
                              }
                              refTimerIns.current = 0
                              setRequestRefresh(false)
                                        
                            }} 
                        >
                            {(formik) => {
                            state = formik
                            return (
                                <Form className='form' onSubmit={formik.handleSubmit}>
                                    <TextInput name='topup_amount' id='topup_amount' label='Enter amount' 
                                    inputtype='text' onKeyUp={keyUpHandler} />
                                    {/* <TextInput name='hash_trx' id='hash_trx' label='Enter Hash Transaction' 
                                    inputtype='text' onKeyUp={keyUpHandler} />  */} 
                                    <Select handleAccountType={handleAccountType} handleResetForm={handleResetForm} />  
                                    {accountType === 'Blockchain' && <TextInput name='hash_trx' id='hash_trx_wallet-deposit' label='Enter Hash Transaction' 
                                    inputtype='text' onKeyUp={keyUpHandler} /> }
                                    {accountType === 'CIEx Account' && <TextInput name='uid' id='uid' label='Enter Account UID'        
                                    inputtype='text' onKeyUp={keyUpHandler} /> } 
                                    {/* <div className='amount-fees-wrapper'>
                                        <div className='amount-fees-row'><span>Amount</span><span>500.00 EUR</span></div>
                                        <div className='amount-fees-row'><span>Fees</span><span>3.0 EUR</span></div>
                                        <div className='amount-fees-row'><span>Total</span><span>503.00 EUR</span></div>
                                    </div>
                                    <div className='transaction-fee-notification'>
                                        * Our transaction fee are included. <span>See transaction fee</span>
                                    </div> */} 
                                    

    
                                    {!loadAdd && <div className='wallet-address-wrapper'>
                                        <div className='use-wallet-address-provided-for-deposit'>Use the wallet address provided below to make the deposit.</div>
                                        {/* {addressValue && <QRCode
                        title="Paytaps"
                        value={addressValue} 
                        size={256}
                        level={"L"} 
                        // bgColor={back}
                        // fgColor={fore}
                        // size={size === '' ? 0 : size}
                    />} */}             <div id='qrcode'>

                    </div>
                                        <div>
                                            <div>
                                                <img src='/images/dashboard/topup/tabler_wallet.svg' alt='wallet' />
                                                <div className='wallet-address'>
                                                    <span>Wallet address (TRC20)</span>
                                                    <span id='wallet-address-value'>{addressValue}</span>
                                                </div>
                                            </div>
                                            <button className='copy-btn' type='button' onClick={copyAddress}>
                                                <img src='/images/dashboard/topup/tabler_copy.svg' 
                                                alt='2 squares with the highest one its right half clipped' hidden={isCopied} />
                                                <span hidden={!isCopied}>Copied!</span>
                                            </button>
                                        </div>
                                    </div>}
                                    {loadAdd && <div className='d-flex justify-content-center'><Loader /></div>} 
                                    {/* <label className='check-term-conditions'>
                                        <Field type="checkbox" name="agree_terms_conditions" />
                                        <span> Agree to the Terms and Conditions and Privacy Policy.</span> 

                                    </label> */}

                                    <div className='check-term-conditions'>
                                      <Field type="checkbox" name="agree_terms_conditions_first_deposit" checked={termsConditions || confirm_TC}  onClick={handleConfirmTermsConditions} />   
                                      <span> I Agree to the Terms and Conditions and Privacy Policy.</span>  
                                    </div> 
                                    {/* <div className='check-term-conditions'>
                                      <Field type="checkbox" name="agree_terms_conditions" checked={termsConditions1 || confirm_TC} onClick={handleConfirmTermsConditions} />  
                                      <span> I Agree to the Terms and Conditions and Privacy Policy.</span>  
                                    </div> */}
                                    
                                    {/* <ButtonBootstrap variant="secondary" onClick={handleClose}>
                                        
                                    </ButtonBootstrap>
                                    <button type='submit' id='confirm_topup'></button> */}
                                    {/* <Button click={handleClose}>Cancel</Button> */}
                                    {/* <Button btnType='submit' id='submit_topup' disabled={!formik.values.agree_terms_conditions || !(formik.dirty && formik.isValid) || formik.isSubmitting } styles='submit-btn'>Submit</Button>   */} 
                                    {accountType === 'Blockchain' && <Button btnType='submit' id='submit_first_deposit_hash' disabled={(!document.querySelector('input[name="agree_terms_conditions_first_deposit"]')?.checked) || !(formik.dirty && formik.isValid) || formik.isSubmitting } styles='submit-btn'>Submit</Button> } 
                                    {accountType === 'CIEx Account' && <Button btnType='submit' id='submit_first_deposit_uid' disabled={(!document.querySelector('input[name="agree_terms_conditions_first_deposit"]')?.checked) || !(formik.dirty && formik.isValid) || formik.isSubmitting } styles='submit-btn'>Submit</Button>  }  

                                    
                                    
                                </Form>)}}
        </Formik>
        
        {/* </Modal.Body>
        <Modal.Footer> */}
        
        {/* <ButtonBootstrap variant="primary" onClick={handleClose}>
            Save Changes
        </ButtonBootstrap> */}
        {/* </Modal.Footer> */}
        </Modal>
    }
    {!refisTimerEnabled && 
        <Modal show={requestRefresh} className='loader-error' backdrop='static' keyboard='false'>
            <Modal.Body className='center'>
                {!error &&<div className='errorMessageContainer'> <figure><img src='/images/dashboard/topup/refresh.svg' width='150' /></figure><div>Times out</div><div className='messageError'>It seems your session has timed out. Please refresh the page to continue. If you need assistance, feel free to contact our support team.</div> <Button styles= 'try-again-btn' click={handleCloserequestRefresh}>Refresh</Button></div>}  
                {error && <div>ERROR</div>} 
            </Modal.Body>
        </Modal>
    }
  {/* <SureToContinueTopUp show={show} handleClose={handleClose} />  */}
  <ConfirmTermsConditions show={termsConditions} handleShowTermsConditions={handleShowTermsConditions} handleCloseTermsConditions={handleCloseTermsConditions} confirm_TC={()=>setConfirm_TC(true)} handleCloseTermsTC={handleCloseTermsTC} /> 
    </>
  )

}

export default UnsufficientBalance

 