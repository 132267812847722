import React, { useState } from 'react';import './CSS/OtpInput.css';  
const OtpInput = ({ length, onChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;      
      setOtp(newOtp);
      onChange(newOtp.join(''));

      // Move focus to next inputif (index < length - 1) {
      if(index < length - 1) {
        e.target.nextSibling.focus();
      }
      }    }   
//   const handleKeyDown = (e, index) => {
//     if (e.key === 'Backspace' && otp[index] === '') {
//       if (index > 0) {
//         e.target.previousSibling.focus();
//       }
//     }

const handleKeyDown = (e, index) => {     if (e.key === 'Backspace') {       
    e.preventDefault();       
    const newOtp = [...otp];       
    if (newOtp[index] !== '') {         
        newOtp[index] = ''; 
    // Clear current input      
    } else if (index > 0) {        
         e.target.previousSibling.focus();         newOtp[index - 1] = ''; 
    // Clear previous input
     } setOtp(newOtp); onChange(newOtp.join('')); } }; 
  

  return (
    <div className="otp-input">
      {otp.map((_, index) => (
        <input key={index} type="text" maxLength="1" value={otp[index]} onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
    </div>
  );
};

export default OtpInput;