import React from 'react'
import PaytabsMasterCard from './components/PaytabsMasterCard'
import Cardfeatures from './components/Cardfeatures'
import ProductOverview from './components/ProductOverview'
import Howitworks from './components/Howitworks'
import Benefits from './components/Benefits'
import SecureTrusted from './components/SecureTrusted'
import CardDetails from './components/CardDetails'
import Testimonial from './components/Testimonial'
import Experience from './components/Experience'


const LpBody = () => {
  return (
    <>
          <PaytabsMasterCard />
          <Cardfeatures />
          <ProductOverview />
          <Howitworks />
          <Benefits />
          <SecureTrusted />
          <CardDetails />
          <Testimonial />
          <Experience /> 
    </>
  )
}

export default LpBody