import React, { useEffect, useState} from 'react'
import { userBalance } from '../security/AuthService'
const useBalance = () => {
    const [balance, setBalance] = useState(null)
    useEffect(()=>{
        userBalance().then((result)=> result.json()).then((result)=> console.log(result))
    })
  return balance
}

export default useBalance