import React , { useState, useEffect, useRef, useCallback } from 'react'  
import { Modal } from 'react-bootstrap'
import './CSS/showdetails.css'
import Button from '../components/Button'
import OtpInput from '../components/OtpInput' 

import './CSS/verifycardinfo.css'
import { generateOTP, verifyOTP } from '../security/AuthService'   
const VerifyCardInfo = (props) => {
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(`${localStorage.getItem('o_t_p_t').length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00'}  `); 
    const [stateCodeGe, setStateCodeGe] = useState(false) 
    const refTimerOTP = useRef(null)
    const refAllow = useRef(false)
    const otpValue = useRef(null)  
    const [error, setError] = useState('') 
    useEffect(()=>{
        // getAddressWallet()
        if(refAllow.current) {
        if(timer === '-1:-1') {
                    // handleShowrequestRefresh()
                    // setAdjustState(false)
                    // props.checkrefisTimerEnabled_Uns_Bal(true) 
            setTimer(`${localStorage.getItem('o_t_p_t').length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00'}  `)
                        // handleShowrequestRefresh()
        }
        // console.log(currentDate.getTime() + 180000)
        // if(!refisTimerEnabled) {
        //     handleShowrequestRefresh()
        //     setTimer('03:00')
        // }
        if(stateCodeGe) {

        
        var x = setInterval(function() {

            // Get today's date and time
            const now = new Date()
            
              
            // Find the distance between now and the count down date
            var distance = refTimerOTP.current - now.getTime();
              
            // Time calculations for minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
              console.log(''+minutes)
            setTimer(`${(''+minutes).length === 1 ? '0' + minutes : minutes}:${(''+seconds).length === 1 ? '0' + seconds : seconds}`) 
              
            // If the count down is over, write some text 
            if (distance < 0) {
              
            //   requestRefresh.current = false
            // error.current = true
              clearInterval(x);
              refAllow.current = false  
              console.log(timer) 
              setStateCodeGe(false) 
              setTimer(`${localStorage.getItem('o_t_p_t').length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00'}  `) 
              if(error) {
                setError('')
              }
            //   setRefisTimerEnabled(false)
            //   props.checkrefisTimerEnabled_Uns_Bal(false) 
              
            }
          }, 1000);
        }
    }
    }, [timer, stateCodeGe,  props.a]) 
    // const handleCloserequestRefresh = useCallback(function(){
    //     // generateAddress()
    //     // regenerateAddressWallet()
    //     // refisTimerEnabled.current = true
    //     // regenerateAddress()
    //     // props.setRefTrue()
    //     refTimerOTP.current = new Date().getTime() + 18001
    //     // setRefisTimerEnabled(true); 
    //     // setRequestRefresh(false)
    //     // requestRefresh.current = false
        
        
    //     // return redirect('/ordercard')
    // }, [])
    // const handleShowrequestRefresh = useCallback(function(){
    //     // setRequestRefresh(true)
    //     // setError(true) 
    //     // requestRefresh.current = true
    //     // error.current = true 
    // }, [])
    const handleOtpChange = (value) => {
        setOtp(value);
      };
      const handleTimerStart = useCallback(async function() {
        // console.log(typeof localStorage.getItem('o_t_p_t')) 
        // console.log('YEAH YEAH') 
        // if(error) {
        //   document.querySelectorAll('.otp-input > input[type="text"]').forEach((item)=>{
        //     console.log(item) 
        //     item.setAttribute('value', '') 
        // })
          setError('') 
        // } 
        // props.handleRefAllowTrue()
        const generateOTP_result = await generateOTP()
        // const generateOTP_data = await generateOTP_result.json() 
        // console.log(generateOTP_data)
        if(generateOTP_result.ok) {
            refAllow.current = true         
            setStateCodeGe(true)  
            // refTimerOTP.current = new Date().getTime() + 18001 
            refTimerOTP.current = new Date().getTime() + (parseInt(localStorage.getItem('o_t_p_t')) * 60 * 1000) + 1000
        }
        // refAllow.current = true         
        // setStateCodeGe(true)  
        // // refTimerOTP.current = new Date().getTime() + 18001 
        // refTimerOTP.current = new Date().getTime() + (parseInt(localStorage.getItem('o_t_p_t')) * 60 * 1000) + 1000
        // console.log(props.a, stateCodeGe, refTimerOTP.current) 
      }, []) 
      const handleCancel = useCallback(function() {
        refAllow.current = false         
        setStateCodeGe(false) 
        refTimerOTP.current = 0
        props.handleClose()
        if(error) {
            setError('') 
        }
        console.log(typeof otp) 
      })
      const handleverifyOTP = useCallback(async function() { 
        // console.log(otp) 
        let otpValue =''
        document.querySelectorAll('.otp-input > input[type="text"]').forEach((item)=>{
            otpValue += item.value
        })
        console.log(otpValue) 
        const verify_result = await verifyOTP({
            otp: parseInt(otpValue) 
        }) 
        const verify_data = await verify_result.json() 
        console.log(verify_result)
        refAllow.current = false         
        setStateCodeGe(false) 
        refTimerOTP.current = 0
        if(verify_result.ok) {
             props.handleClose()
             props.handleShowcardDetails()
        } else {
            setError(verify_data['message']) 
        }
        console.log(verify_data) 
        console.log(document.querySelectorAll('.otp-input > input[type="text"]'))
        
        // props.handleClose()
        // props.handleShowcardDetails()  
        // console.log('RRRRR')     
    }, [])  
  return (
    <Modal show={props.show}  className='topup-modal verifyInfo' backdrop='static' keyboard='false' >
        <div> 
            <figure>
                <img src='/images/dashboard/mycard/freepik--Character--inject-44 (1).svg' alt='2 cards' />
            </figure>
            <div className='verify-card-info'>Verify your card info</div>
            <div className='presstoverify'>Press send button to receive a verification code to your email</div>
            <Button styles='sendotpcode' click={handleTimerStart} disabled={stateCodeGe}>Send</Button> 
            <div className='otpTimer'>{timer}</div>
            <OtpInput length={4} onChange={handleOtpChange}  />
            {error && <div className='errorOTPVerification'>{error}! Please Try Again</div>}     
            <div className='group-btn'>
                <Button click={handleCancel}>Cancel</Button>
                <Button  styles='uns-bal-go-deposit' disabled={!stateCodeGe || (otp.length !== 4)} id='verifyOTP' click={handleverifyOTP}>Verify</Button> 
            </div>
        </div>
                                
                                
   </Modal> 
  )
}

export default VerifyCardInfo
