import React from 'react'
import './CSS/resetpasswordnewpassword.css' 
import { Link,useLocation, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import TextInput from '../components/TextInput'
import PasswordInput from '../components/PasswordInput' 
import { forgetPassword, formatError, refreshToken, killToken, saveToken  } from '../security/AuthService'
const ResetPasswordNewPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { email } = location.state || {};
    // console.log(email) 
  return (
    <section className='resetPasswordEmailContainer'>
        <div className='resetPasswordEmailContent'>
            <Link to='/'>
                <img src='/images/registration/Group 1.svg' alt='paytabs logo' loading='lazy' draggable='false' className='paytapsLogo' />
            </Link>
            <div className='resetPassword'>New password
              </div>
            <div className='resetPasswordEnterCodeSentEmail'>Set your new password</div> 
            <Formik
                            initialValues={{
                                password: '',
                                confirm_password: '' 
                            }}

                            validationSchema={YUP.object({
                                password: YUP.string().required('Required!').min(5, 'Password must contain at least 5 characters')
                                .matches(/[A-Z]+/, { message: <span>Password must contain at least 1 uppercase character</span>})
                                .matches(/[a-z]+/, { message: <span>Password must contain at least 1 lowercase character </span>})
                                .matches(/[0-9]+/, { message: <span>Password must contain at least 1 number </span>}),
                            confirm_password: YUP.string().required('Required!').oneOf([YUP.ref('password'), ''], 'Please enter the correct password again')
                            })}

                            onSubmit={ async (values, {setSubmitting, resetForm})=>{
                                try {
                                    let result = await forgetPassword({password: values.password,email: email}) 
                                    // If fetch returns 401 
                                    if(result.status === 401) {
                                        const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                                        // If refresh token returns 401
                                        if(refreshTokenResult.status === 401) {
                                        killToken()
                                        return navigate('/login')
                                        } 

                                        // If refresh token is successfull 
                                        const payloadRefreshToken = await refreshTokenResult.json()   

                                        let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                                        // Payload of new generated token 
                                        let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                                        if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                                            killToken()
                                            return navigate('/login') 
                                        } else {
                                            saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                                            result = await forgetPassword({password: values.password,email: email}) 
                                        }

                                    }

                                    // console.log("reponse2",result) 
                                    const reponse = await result.json()
                                    // console.log("reponse3",reponse)
                                    if(result.ok && reponse.message === 'Successfully') {
                                        //   console.log(reponse)  
                                        navigate('/login')
                                    }
                                    setSubmitting(false)
                                    resetForm()
                                } catch(err) {

                                }
                            }}
                        >
                            {(formik) => {
                            
                            return (

                                <Form className='form' onSubmit={formik.handleSubmit}>

                                    <PasswordInput name='password' id='password' label='Password'  />
                                    <PasswordInput name='confirm_password' id='confirmpassword' label='Confirm Password'  />

                                    <button type='submit' id='updatepassword' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Update</button> 

                                </Form>)}}
                        </Formik>

                        <div className='already-have-account'>
                            Remember It? 
                            <Link to='/login' style={{
                                fontWeight: '700',
                                color: '#0E0E0E'
                            }}>  Sign in here </Link>
                        </div>
        </div>
    </section>
  )
}

export default ResetPasswordNewPassword 
 