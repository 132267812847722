import React from 'react'

const TopUpVisualizer = (props) => {
  return (
    <>
    {(props.data?.length > 0) && props.data !== null && props.data !== undefined  && <div className='transactions-wrapper-body'>
        <ul className='transactions-wrapper-body-list'>
            {props.data.map((item, index)=>{
                return (<li key={index} className='transactions-wrapper-body-list-transaction-data-item'>
                            <figure className='transaction-status-image'>
                                {   props.topup ? 
                                    <img src='/images/dashboard/mycard/transaction-green.svg' alt='completed transaction' />  :
                                    (item.status === 'Completed' ? 
                                    (<img src='/images/dashboard/mycard/transaction-green.svg' alt='completed transaction' /> ) :
                                    ((item.status === 'Pending') ?
                                        (<img src='/images/dashboard/mycard/transaction-red.svg' alt='pending transaction' />) :
                                        (<img src='/images/dashboard/mycard/transaction-red.svg' alt='pending transaction' />)) )       
                                }
                            </figure>
                            <div>
                                <span className='transaction-origin'>Card Top-Up</span>
                                <div className='transaction-date'><span>{`${new Date(item['dateOfTransaction']).toLocaleString('default', { month: 'long' }).substring(0,3)} ${(new Date(item['dateOfTransaction']).getDate() > 9) ? new Date(item['dateOfTransaction']).getDate() : '0'+new Date(item['dateOfTransaction']).getDate()}, ${new Date(item['dateOfTransaction']).getFullYear()}`}</span><span>{`${(new Date(item['dateOfTransaction']).getHours() > 9) ? new Date(item['dateOfTransaction']).getHours() : '0'+new Date(item['dateOfTransaction']).getHours()}:${(new Date(item['dateOfTransaction']).getMinutes() > 9) ? new Date(item['dateOfTransaction']).getMinutes() : '0'+new Date(item['dateOfTransaction']).getMinutes()}`}</span></div> 
                                <span className='transaction-value'>€{item['authAmount']}</span> 
                                {/* {item.status&&<span className='transaction-status'>{item.status}</span>} */}
                            </div>
                        </li>)
                })}
        </ul>
    </div>}
    {
            (props.data?.length === 0 || props.data === null || props.data === undefined ) && <div className='noRecords'>No Records</div>  
        }
    </>
  )
}

export default TopUpVisualizer 
