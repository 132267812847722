import React, { useState, useRef, useCallback } from 'react'

import { Link, redirect, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup'
import Modal from 'react-bootstrap/Modal'

import TextInput from '../components/TextInput'
import PasswordInput from '../components/PasswordInput'
import Loader from '../../src/jsx/pages/Loader/Loader'
import './login.css'
import { login, formatError, saveToken, joinToken, getConfig  } from '../security/AuthService' 
import { useActive } from '../context/ActiveContext' 
import Button from '../components/Button'

const Login = () => {
    const error = useRef(null)
    const preverror = useRef(null)
    // const [isPending, setIsPending] = useState(false)
    const [show, setShow] = useState(false)
    const navigate = useNavigate()
    const [isError, setIsError] = useState(false)
    const {handleMenuActive} = useActive()
    let state 

    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }
    const handleClose = useCallback(() => setShow(false), []);
    const handleShow = useCallback(() => setShow(true), []);
    // const handleShowPending = useCallback(()=>{
    //     setIsPending(true)
    // })
    // const handleClosePending = useCallback(()=>{
    //     setIsPending(false)
    // })
    const handleShowError = useCallback(()=>{
        setIsError(true)
    }, [])
    const handleCloseError = useCallback(()=>{
        setIsError(false)
    }, [])
    let authResponse
    return (
        <>
        {/* {!isPending &&  */}
        <div className='login-container'>
        <div className='login-wrapper'>
            <figure className='login'>
            </figure>
            <div className='login-form-container'>
                <div className='form-welcoming-part'>
                    <Link to='/'>
                        <img src='/images/registration/Group 1.svg' alt='paytabs logo' loading='lazy' draggable='false' />
                    </Link>
                    <div id='welcome-to-paytabs'>Welcome to PayTaps</div>
                    <div id='signup-signin-your-account'>Sign in by entering information below</div> 
                </div>
                <>
                        <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}

                            validationSchema={YUP.object({
                                email: YUP.string().required('Required!').email('Invalid Email!'),
                                password: YUP.string().required('Required!')
                                // .min(5, 'Password must contain at least 5 characters')
                                // .matches(/[A-Z]+/, { message: <span>Password must contain at least 1 uppercase character</span>})
                                // .matches(/[a-z]+/, { message: <span>Password must contain at least 1 lowercase character </span>})
                                // .matches(/[0-9]+/, { message: <span>Password must contain at least 1 number </span>})
                            })}

                            onSubmit={ async (values, {setSubmitting, resetForm})=>{
                                
                                try {
                                    if(isError) {
                                        handleCloseError()
                                    }
                                    // console.log(values)
                                
                                    // setIsPending(true)
                                    // handleShowPending()
                                    handleShow()
                                    const response = await login({email: values.email, password: values.password}) 
                                    authResponse = await response.json()
                                    // console.log( authResponse.token)
                                    // console.log(authResponse.refresh_token)    
                                    if(response.ok && authResponse.token) {
                                        // console.log('!!!!!!!!!!!!!!')
                                        //  await new Promise((resolve, reject)=>{
                                        //     resolve(handleCloseError())
                                        // })
                                        // console.log(config_data) 
                                        handleCloseError()
                                        saveToken(authResponse.token, authResponse.refresh_token) 
                                        const config_result = await getConfig()
                                        const config_data = await config_result.json() 
                                        // console.log(config_data) 
                                        if(config_result.ok) {
                                            // console.log(config_data['data'][2]['name']) 
                                            // console.log(config_data['data'][5]['name'])
                                            // console.log(config_data['data'][3]['value']) 
                                            if(config_data['data'][0]['name'] === 'CreditLimit') {
                                                localStorage.setItem('c_l', config_data['data'][0]['value'])
                                            } else {
                                                localStorage.setItem('c_l', '100000')
                                            } 
                                            if(config_data['data'][6]['name'] === 'TimeDownOTP') {
                                                localStorage.setItem('o_t_p_t', config_data['data'][6]['value'])
                                            } else {
                                                localStorage.setItem('o_t_p_t', '3')
                                            }
                                            if(config_data['data'][3]['name'] === 'MinTopup')  {
                                                localStorage.setItem('m_t_u', config_data['data'][3]['value'])
                                            } else {
                                                localStorage.setItem('m_t_u', '100')
                                            }
                                            if(config_data['data'][4]['name'] === 'TopupFee')  {
                                                localStorage.setItem('t_u_f', config_data['data'][4]['value'])
                                            } else {
                                                localStorage.setItem('t_u_f', '3')
                                            }
                                            if(config_data['data'][1]['name'] === 'MinFirstDeposit') {
                                                localStorage.setItem('m_f_d', config_data['data'][1]['value'])
                                            } else {
                                                localStorage.setItem('m_f_d', '120')
                                            }
                                            if(config_data['data'][2]['name'] === 'MinDeposit')  {
                                                localStorage.setItem('m_n_d', config_data['data'][2]['value'])
                                            } else {
                                                localStorage.setItem('m_n_d', '100')
                                            }
                                            if(config_data['data'][5]['name'] === 'TimeDownDeposite')  {
                                                localStorage.setItem('t_d_d', config_data['data'][5]['value'])
                                            } else {
                                                localStorage.setItem('t_d_d', '3')
                                            }
                                        } else {
                                            // localStorage.setItem('p_t_o_t', '3') 
                                            localStorage.setItem('o_t_p_t', '3')
                                            localStorage.setItem('m_t_u', '100')
                                            localStorage.setItem('t_u_f', '3') 
                                            localStorage.setItem('m_f_d', '120')
                                            localStorage.setItem('m_n_d', '100')
                                            localStorage.setItem('t_d_d', '3')
                                        }
                                        // if(authResponse.token === joinToken()) {
                                        //     console.log('Similar')
                                        // }
                                        // console.log(atob(localStorage.getItem('u_t_p')).)                                                
                                        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))
                                        // console.log(decoded_payload)
                                        // console.log(decoded_payload['req'])
                                        // decoded_payload['req'] = 'S ' 
                                        setSubmitting(false)
                                        resetForm()
                                        // setIsPending(false)
                                        
                                        // handleMenuActive('Dashboard')
                                        // console.log(decoded_payload['req'])  
                                        switch(decoded_payload['req']) {
                                            // case 'N': { handleMenuActive(''); navigate('/ordercard') }
                                            //           break;  
                                            case 'N': { handleMenuActive('User Guide'); navigate('/userguide') }                     
                                                      break;
                                            case 'P': { handleMenuActive(''); navigate('/thankyouforcardorder') } 
                                                      break;
                                            // case 'D': { handleMenuActive(''); navigate('/ordercard') } 
                                            //           break; 
                                            case 'D': { handleMenuActive('User Guide'); navigate('/userguide') }                          
                                                      break;
                                            case 'S': {handleMenuActive('Dashboard');navigate('/dashboard')}
                                                      break;
                                            default:  navigate('/ordercard')
                                        } 
                                        // handleClosePending()
                                        handleClose()
                                        // navigate('/dashboard') 
                                    }
                                    // // const response = {ok: false}
                                    else if(!response.ok && authResponse.message) {
                                        // console.log(authResponse.message, '????????')
                                        error.current = formatError(authResponse.message)
                                        handleShowError()  
                                        // error.current = false
                                                            }
                                    // await new Promise((resolve, reject)=>{
                                    //     resolve(setTimeout(()=>{
                                    //         setSubmitting(false)
                                    //         resetForm()
                                    //         setIsPending(false)
                                    //     }, 5000))
                                    // })
                                } catch (err) {
                                    // console.log(err)
                                }

                            }}
                        >
                            {(formik) => {

                            state = formik
                            
                            return (

                                <Form className='form' onSubmit={formik.handleSubmit}>

                                    <TextInput inputtype='email' name='email' id='email' label='Email' error={formik.errors.email} touched={formik.touched.email} onKeyUp={keyUpHandler} />

                                    <PasswordInput name='password' id='password' label='Password' error={formik.errors.password} touched={formik.touched.password} onKeyUp={keyUpHandler} />
                                    <Link to='/resetpassword' className='forgotPassword'>Forgot Password</Link>

                                    <button type='submit' id='sign-in' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Sign In</button> 

                                </Form>)}}
                        </Formik>
                </>
                <div className='already-have-account'>
                    Don't have an account?
                    <Link to='/register' style={{
                        fontWeight: '700',
                        color: '#0E0E0E'
                    }}>  Sign up </Link>
                </div> 
            </div>
        </div>
    </div>
    {/* } */}
{/* {isPending && <> */}

      {/* <Modal show={show} onHide={handleClose}> */}
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body className='center'> */}
            {/* <Loader /> */}
        {/* </Modal.Body> */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      {/* </Modal> */}
{/* </>} */}
{/* {isPending && <> */}

<Modal show={show} className='loader-error' backdrop='static' keyboard='false'>
  <Modal.Body className='center'>
      {!isError && <Loader />}
      {isError && <div className='errorMessageContainer'> <figure><img src='/images/registration/Reset password-rafiki.svg' width='300' /></figure><div>Invalid credentials</div><div className='messageError'>The email address or password you entered is incorrect. Please check your details and try again. </div> <Button styles= 'try-again-btn' click={handleClose}>Try Again!</Button></div>}  
  </Modal.Body>
</Modal>
{/* {isError && <ErrorModalMessage isError={isError} handleClose={handleCloseError} message={error.current} />} */}
</>
// }
// </>
)
}

export default Login










