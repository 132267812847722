// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password_field_container {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}
.password_field {
    width: 100%;
    height: 43px;
    border: 1.5px solid #D5D5D5;
    border-radius: 17px;
    padding-inline: 10px;
    /* padding-right: 38px; */
}
.password_field_container > button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    z-index: 2;
    display: none; 
}
input::-ms-reveal { 
    display: none; 
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/passwordinput.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,mBAAmB;IACnB,oBAAoB;IACpB,yBAAyB;AAC7B;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,uBAAuB;IACvB,YAAY;IACZ,UAAU;IACV,aAAa;AACjB;AACA;IACI,aAAa;AACjB","sourcesContent":[".password_field_container {\n    width: 100%;\n    position: relative;\n    margin-bottom: 20px;\n}\n.password_field {\n    width: 100%;\n    height: 43px;\n    border: 1.5px solid #D5D5D5;\n    border-radius: 17px;\n    padding-inline: 10px;\n    /* padding-right: 38px; */\n}\n.password_field_container > button {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    background: transparent;\n    border: none;\n    z-index: 2;\n    display: none; \n}\ninput::-ms-reveal { \n    display: none; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
