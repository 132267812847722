import React, { useState, useEffect } from 'react'                                                                                                              
import { Modal } from 'react-bootstrap'
import './CSS/showdetails.css'
import Button from '../components/Button' 
import useCardDetails from '../hooks_/useCardDetails' 
import { getcardDetails, getProfileData } from '../security/AuthService'              
import Loader from '../jsx/pages/Loader/Loader' 
const ShowDetails = (props) => {
    // const {data, error, loading} = useCardDetails() 
    // console.log({data, error, loading}) 
    const [data, setData] = useState({})
    const [error, setError] = useState('') 
    const [loading, setLoading] = useState(false) 
    const [isCopied, setIsCopied] = useState(false)

    useEffect(()=>{
        if(props.show) {
            getCardDetailsData() 
        }
    }, [props.show]) 
    async function getCardDetailsData () {
        setLoading(true)
        const card_details_result = await getcardDetails()
        const card_details_data = await card_details_result.json() 
        const name_result = await getProfileData()
        const name_data = await name_result.json() 
        console.log(card_details_data, name_data['data'].firstName, name_data['data'].lastName)     
        if(card_details_result.ok && name_result.ok) { 
         setData({...data, data: card_details_data['data'], firstName: name_data['data'].firstName, lastName: name_data['data'].lastName})  
         console.log(data.cardNumber)    
        } else {
        setError('No Data')
        }
        setLoading(false) 
    }
    function handleCancel () {
        props.handleClosecardDetails()
    }
    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('cardNumber').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
            setIsCopied(false)
        }
    }

  return (
    <Modal show={props.show}  className='topup-modal' backdrop='static' keyboard='false' >
        {/* <div>
            <figure>
                <img src='/images/dashboard/mycard/paytaps card 1.svg' alt='paytaps card' />
            </figure>
        </div> !loading && !error */}
        {((!loading && !error) && (Object.keys(data).length > 1)) && <><div className={`card-wrapper cardDetailsData`} >
                    <figure>
                        <img src='/images/dashboard/mycard/paytaps card.png' alt="card paytaps" />
                        {/* <span className='card-holder-name'>foulan ben foulan</span> */}
                        <span className='card-holder-name'>{data.firstName} {data.lastName}</span>   
                        {/* <span className='card-number'>9758  0158  0015  8799</span> */}
                        <span className='card-number' id='cardNumber'>{data.data['cardNumber']}</span>
                        <div className='card-validity'>
                            <span>VALID THRU</span>
                            <span>{`${data.data['cardExpMth'].length < 2 ? '0' + data.data['cardExpMth'] : data.data['cardExpMth']}/${data.data['cardExpYear']}`}</span> 
                        </div>
                    </figure>
                </div>
                <div>
                    <div className='showDetailsTitle'>Your Card Details.</div>
                    <div className='showDetailsDescription'>The details of your card are displayed above. You can copy your card number to link it with digital wallet services such as Google Pay, Apple Pay, or Samsung Pay. 
Use the button below to copy the number.</div> 
                </div>
                <div className='group-btn'> 
                                    <Button click={handleCancel} id="cancel">Cancel</Button>
                                    <Button btnType='submit' id='copyAddress' click={copyAddress}><span hidden={!isCopied}>Copied!</span><span hidden={isCopied}>Copy card number</span></Button> 
                                </div></> }
                                {(loading && !error) && <><div className='loaderWrapper'><Loader /></div></>} 
   </Modal> 
  )
}

export default ShowDetails