// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loggedResetPasswordContainer {
    width: 100%;
    max-width: 558px; 
    margin: 70px auto auto auto; 
    display: flex;
    align-items: center;
    justify-content: center; 
}
.resetPasswordFormWrapper {
    width: 100%;
    padding: 27px 41px 41px 41px; 
    background: #FFFFFF;
    box-shadow: 0 3.9px 3.9px 0 #0000001A;
    border-radius: 8px;
}
.resetPasswordTitle {
    font-family: var(--font-poppins); 
    font-size: 30px;
    font-weight: 600;
    line-height: 37px;
    text-align: center;
    margin-top: 20px;
    color: #000000;
}

#updatePassword {
    width: 100%;
    height: 50px;
    background: #1EAAE7;
    border: none;
    border-radius: 12px; 
    box-shadow: 0px 4px 20px 0px #00000014;
    font-family: var(--font-poppins); 
    font-size: 20px;
    font-weight: 400;
    line-height: 22px; 
    letter-spacing: -0.2px; 
    text-align: center; 
    color: #FFFFFF;
    margin-top: 20px;
}

#updatePassword:disabled {
    opacity: 0.7;
    cursor: not-allowed; 
}




@media screen and (max-width: 480px) {
    .resetPasswordFormWrapper {
        padding: 27px 27px 41px 27px; 
    }
}`, "",{"version":3,"sources":["webpack://./src/inresetpassword/CSS/resetpasswordpage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,4BAA4B;IAC5B,mBAAmB;IACnB,qCAAqC;IACrC,kBAAkB;AACtB;AACA;IACI,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,sCAAsC;IACtC,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;;;;AAKA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".loggedResetPasswordContainer {\n    width: 100%;\n    max-width: 558px; \n    margin: 70px auto auto auto; \n    display: flex;\n    align-items: center;\n    justify-content: center; \n}\n.resetPasswordFormWrapper {\n    width: 100%;\n    padding: 27px 41px 41px 41px; \n    background: #FFFFFF;\n    box-shadow: 0 3.9px 3.9px 0 #0000001A;\n    border-radius: 8px;\n}\n.resetPasswordTitle {\n    font-family: var(--font-poppins); \n    font-size: 30px;\n    font-weight: 600;\n    line-height: 37px;\n    text-align: center;\n    margin-top: 20px;\n    color: #000000;\n}\n\n#updatePassword {\n    width: 100%;\n    height: 50px;\n    background: #1EAAE7;\n    border: none;\n    border-radius: 12px; \n    box-shadow: 0px 4px 20px 0px #00000014;\n    font-family: var(--font-poppins); \n    font-size: 20px;\n    font-weight: 400;\n    line-height: 22px; \n    letter-spacing: -0.2px; \n    text-align: center; \n    color: #FFFFFF;\n    margin-top: 20px;\n}\n\n#updatePassword:disabled {\n    opacity: 0.7;\n    cursor: not-allowed; \n}\n\n\n\n\n@media screen and (max-width: 480px) {\n    .resetPasswordFormWrapper {\n        padding: 27px 27px 41px 27px; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
