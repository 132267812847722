import React, {useEffect} from 'react'
import './CSS/cardfeatures.css'

const Cardfeatures = () => {
  return (
    <section id='card-features'>
        <div className='card-features-container'>
            <div className='card-features-title'>CARD FEATURES</div>
            <p className='card-features-subtitle'>Why Choose PAYTAPS Card?</p>
            <div className='card-features-free-to-acquire'>
                
                <div className='card-features-free-to-acquire-text-content'>
                    <p className='card-features-free-to-acquire-text-content-free-to-acquire'>Free to Acquire</p>
                    <p className='card-features-free-to-acquire-text-content-no-initial-cost'>No initial cost for the card.</p>
                </div>
                <img src='/images/landingpage/body/Main Layers 1.svg' alt='dashboard on mobile phone' className='web-phone-img' loading='lazy' draggable='false' />
                <img src='/images/landingpage/body/Phone.svg' alt='dashboard on mobile phone' className='mobile-phone-img' loading='lazy' draggable='false' />
            </div>

            <div className='no-charges-low-top-up-fee-container'>
                <div className='no-charges-wrapper'>
                    <img src='/images/landingpage/body/no-card-fee-8842166-7233255 1.svg' alt='no monthly charges' loading='lazy' draggable='false' />
                    <div>
                        <div className='no-charges-wrapper-title'>No Monthly Charges </div>
                        <div className='no-charges-wrapper-subtitle'>Enjoy the benefits without any monthly fees.</div>
                    </div>
                </div>
                <div className='low-top-up-fee-wrapper'>
                    <div>
                        <div className='no-charges-wrapper-title'>Low Top-Up Fee:</div>
                        <div className='no-charges-wrapper-subtitle'>Only 3% for top-ups.</div>
                    </div>
                    <img src='/images/landingpage/body/Frame 3.svg' alt='low top up fee' loading='lazy' draggable='false' />
                </div>
            </div>

            <div className='mobile-wallet-compatibility-worldwide-accessible-container'>
                <div className='mobile-wallet-compatibility-wrapper'>
                    <img src='/images/landingpage/body/Group 16.svg' alt='mobile wallet compatibility' loading='lazy' draggable='false' />
                    <div className='mobile-wallet-compatibility-wrapper-text-content'>
                        <div className='mobile-wallet-compatibility-wrapper-title'>Mobile Wallet Compatibility</div>
                        <div className='mobile-wallet-compatibility-wrapper-subtitle'>Works with Apple Pay, Samsung Pay, and Google  Pay.</div>
                    </div>

                </div>
                <div className='worldwide-accessible-wrapper'>
                    <img src='/images/landingpage/body/worldwide-10055717-8137178 1.svg' alt='worldwide accessible' loading='lazy' draggable='false' />
                    <div>
                        <div className='worldwide-accessible-wrapper-title'>Worldwide Accessibility</div>
                        <div className='worldwide-accessible-wrapper-subtitle'>Use your card globally.</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )

}

export default Cardfeatures