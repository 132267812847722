// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  } */
   /* input[type='date']:not(:focus) {
    color: red;
   }
   input[type='date'][value=''] {
    color: transparent ;
   } */`, "",{"version":3,"sources":["webpack://./src/components/CSS/dateinput.css"],"names":[],"mappings":"AAAA;;;;;;;;KAQK;GACF;;;;;MAKG","sourcesContent":["/* input[type=\"date\"]:before {\n    content: attr(placeholder) !important;\n    color: #aaa;\n    margin-right: 0.5em;\n  }\n  input[type=\"date\"]:focus:before,\n  input[type=\"date\"]:valid:before {\n    content: \"\";\n  } */\n   /* input[type='date']:not(:focus) {\n    color: red;\n   }\n   input[type='date'][value=''] {\n    color: transparent ;\n   } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
