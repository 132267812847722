// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/Inter-VariableFont_slnt,wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/DMSans-VariableFont_opsz,wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Inter';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
}
@font-face {
    font-family: 'DM Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___})
}
html {
    overflow-x: hidden !important;
    -webkit-user-select: none !important;
            user-select: none !important;
}
body {
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    min-height: 100vh !important;
    overflow-x: hidden !important;
    max-width: 2000px !important;
    margin-inline: auto !important;
    /* background: #FFFFFF !important; */
}`, "",{"version":3,"sources":["webpack://./src/landingpage/lplayout.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB;AACJ;AACA;IACI,sBAAsB;IACtB;AACJ;AACA;IACI,6BAA6B;IAC7B,oCAA4B;YAA5B,4BAA4B;AAChC;AACA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,iCAAiC;IACjC,4BAA4B;IAC5B,6BAA6B;IAC7B,4BAA4B;IAC5B,8BAA8B;IAC9B,oCAAoC;AACxC","sourcesContent":["@font-face {\n    font-family: 'Inter';\n    src: url('/public/fonts/Inter-VariableFont_slnt\\,wght.ttf')\n}\n@font-face {\n    font-family: 'DM Sans';\n    src: url('/public/fonts/DMSans-VariableFont_opsz\\,wght.ttf')\n}\nhtml {\n    overflow-x: hidden !important;\n    user-select: none !important;\n}\nbody {\n    margin: 0 !important;\n    padding: 0 !important;\n    box-sizing: border-box !important;\n    min-height: 100vh !important;\n    overflow-x: hidden !important;\n    max-width: 2000px !important;\n    margin-inline: auto !important;\n    /* background: #FFFFFF !important; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
