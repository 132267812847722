import React from 'react'

const DepositVisualizer = (props) => {
  return (
    <>
    {(props.data?.length > 0) && props.data !== null && props.data !== undefined  && <div className='transactions-wrapper-body'>
        <ul className='transactions-wrapper-body-list'>
            {props.data.map((item, index)=>{
                return (<li key={index} className='transactions-wrapper-body-list-transaction-data-item'>
                            <figure className='transaction-status-image'>
                                {   item.status === "Success" ? 
                                    <img src='/images/dashboard/mycard/transaction-green.svg' alt='completed transaction' />  :
                                    (item.status === 'Decline' ? 
                                    (<img src='/images/dashboard/mycard/transaction-red.svg' alt='declined transaction' /> ) :
                                    (
                                        (<img src='/images/dashboard/dashboard/SVG (2).svg' alt='pending transaction' />)) )       
                                }
                            </figure>
                            <div>
                                {/* <div>`${console.log(item?.createdAt?.date'])}`</div> */}
                                <span className='transaction-origin'>Deposit</span>
                                <div className='transaction-date'><span>{`${new Date(item?.createdAt?.date).toLocaleString('default', { month: 'long' }).substring(0,3)} ${(new Date(item?.createdAt?.date).getDate() > 9) ? new Date(item?.createdAt?.date).getDate() : '0'+new Date(item?.createdAt?.date).getDate()}, ${new Date(item?.createdAt?.date).getFullYear()}`}</span><span>{`${(new Date(item?.createdAt?.date).getHours() > 9) ? new Date(item?.createdAt?.date).getHours() : '0'+new Date(item?.createdAt?.date).getHours()}:${(new Date(item?.createdAt?.date).getMinutes() > 9) ? new Date(item?.createdAt?.date).getMinutes() : '0'+new Date(item?.createdAt?.date).getMinutes()}`}</span></div> 
                                <span className='transaction-value'>${item['amount']}</span> 
                                <span className='transaction-status'>{item.status}</span>
                            </div>
                        </li>)
                })}
        </ul>
    </div>}
    {
            (props.data?.length === 0 || props.data === null || props.data === undefined ) && <div className='noRecords'>No Records</div>  
        }
    </>
  )
}

export default DepositVisualizer 
