import React, { useEffect } from 'react'
import {Helmet} from "react-helmet";
import './CSS/contact.css' 
const Contact = () => {
    useEffect(() => { 
        const script = document.createElement("script"); 
        script.src = "https://js.hsforms.net/forms/v2.js"; 
        document.body.appendChild(script); 
     
        script.onload = () => { 
          if (window.hbspt) { 
            window.hbspt.forms.create({ 
              portalId: "5056029", 
              formId: "a5e6c62d-00bc-4182-ba51-6c9af5d18766", 
              target: "#hubspotForm" 
            }); 
          } 
        }; 
      }, []); 
     
      return ( 
        <div className='hsContainer'> 
          <Helmet> 
            <script src="https://js.hsforms.net/forms/v2.js" type="text/javascript" /> 
          </Helmet> 
          <div id="hubspotForm"></div> 
        </div> 
      ); 
     
}
export default Contact

{/* <script>
  
</script> */}


