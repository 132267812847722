import React, { useState, useEffect, useCallback } from 'react'
import './CSS/topup.css'
import { useToggle } from '../context/ToggleContext'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button' 
import { convertToUSDT, requestTopUp } from '../security/AuthService' 
import { useNavigate } from 'react-router-dom'
const Topup = (props) => {
    const [isCopied, setIsCopied] = useState(false)
    const [amount, setAmount] = useState('--')
    const [totalAmount, setTotalAmount] = useState('--') 
    const navigate = useNavigate() 
    // const refAmount = useRef('--')
    // const refTotalAmount = useRef('--')
     

    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('wallet-address-value').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
                setIsCopied(false)
        }
    }
    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value) 
        state.setFieldTouched(e.target['name'])
    }
    useEffect(()=>{
         
        try {
            if(document.getElementById('topup_amount')) {
                document.getElementById('topup_amount').addEventListener('keyup', ()=>{
                    // if(document.getElementById('topup_amount').value)
                       handleChange() 
                })}
        } catch(err) {

        }
    })  
    async function handleChange() {
        // if(isNaN(amount)) {
        //     console.log('NOT NUMBER') 
        // }
        
       try {
            let topup_fee = parseFloat(localStorage.getItem('t_u_f'))
            // console.log(localStorage.getItem('m_t_f'))
            // console.log(topup_fee)
            // console.log(topup_fee) 
            if(document.getElementById('topup_amount').value === '') {
                setAmount('--')
                setTotalAmount('--') 
            }
            if( /^[0-9\.]+$/.test(document.getElementById('topup_amount').value)) {
                
                    const convert = await convertToUSDT({
                        amount: parseFloat(document.getElementById('topup_amount').value) 
                    })
                    const convert_data = await convert.json() 
                    console.log(convert_data['data'])   
                    if(document.getElementById('topup_amount').value === '') {
                        setAmount('--')
                        setTotalAmount('--') 
                    } else {
                        setAmount((parseFloat(document.getElementById('topup_amount').value)).toFixed(2)) 
                        setTotalAmount((((parseFloat(convert_data['data']['result'])*topup_fee)/100) + parseFloat(convert_data['data']['result'])).toFixed(2)) 
                        // console.log(((parseFloat(document.getElementById('topup_amount').value)*3)/100))
                        // console.log((((parseFloat(convert_data['data']['result'])*topup_fee)/100) + parseFloat(convert_data['data']['result'])).toFixed(2))              
                    }
                    
                
            } else {
                // console.log('Nnnnnnnn')
                setAmount('--')
                setTotalAmount('--')
            }
       } catch(err) {
        console.log(err) 
       }
    }
    const handleCancel = useCallback(function() {
        let path = window.location.pathname;
        path = path.split("/");
        path = path[path.length - 1] 
        console.log(path)  
        if(path === 'topupcard') {
            navigate('/mycard')      
        }
        setAmount('--')
        setTotalAmount('--')
        props.handleClose() 
    }, [])  
  return (
     <Modal show={props.show} onHide={props.handleClose} className='topup-modal' backdrop='static' keyboard='false' >
    <div className='modal-header'>
      
        <div className='topup-title'>Top-up</div>
        <div className='topup-description'>Enter the amount in EUR you want to add. This amount will be available on your card in EUR.</div>
    </div> 
    {/* <Modal.Body className='center'> */}
    {/* <button type='button' class="btn-close" aria-label="Close"><img src='/images/dashboard/topup/cross.svg'  /></button> */}
    
     <Formik
                        initialValues={{
                            topup_amount: ''
                        }}
                        validateOnChange={ true }
                        validateOnBlur={ true } 
                        validationSchema={YUP.object({
                            topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid top-up amount</span>}).test('topup', `Please enter at least ${localStorage.getItem('m_t_u')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_t_u'))),
                            // email: YUP.string().email('Invalid Email!').required('Required!'),
                            // password: YUP.string().required('Required!'),
                            // confirm_password: YUP.string().required('Required!').oneOf([YUP.ref('password'), ''], 'Should be as password')
                        })}

                        onSubmit={async (values, {setSubmitting, resetForm})=>{ 
                            try {
                                let path = window.location.pathname;
                                path = path.split("/");
                                path = path[path.length - 1] 
                                console.log(path)  
                                 
                                console.log(values)
                                console.log(typeof totalAmount)  
                                console.log({
                                    amountFiat: parseFloat(values.topup_amount), 
                                    amountCryptoTotal: parseFloat(totalAmount)
                                })
                                const topup = await requestTopUp({
                                    amountFiat: parseFloat(values.topup_amount), 
                                    amountCryptoTotal1: parseFloat(totalAmount)
                                })
                                if(path === 'topupcard') {
                                    navigate('/mycard')      
                                }
                                // const topup_result = await topup.json() 
                                props.handleClose() 
                                props.handleShowConfirmation()
                                console.log(await topup.json()) 
                                if(!topup.ok) {
                                    props.handleShowInsufTopupBalance() 
                                }
                                // console.log(topup_result) 
                                // props.handleClose() 
                                // props.handleShowConfirmation()
                                setAmount('--')
                                setTotalAmount('--') 
                                // setSubmitting(false)
                                // resetForm()
                                // navigate('/')
                                // props.handleShowInsufTopupBalance()
                                
                            } catch(err) {

                            }
                        }}
                    >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                <TextInput name='topup_amount' id='topup_amount' label='Enter amount' 
                                inputtype='text' onKeyUp={keyUpHandler} /> 
                                <div className='amount-fees-wrapper'>
                                    <div className='amount-fees-row'><span>Amount</span><span>{amount} EUR</span></div>
                                    <div className='amount-fees-row'><span>Fees</span><span>{localStorage.getItem('t_u_f')}%</span></div> 
                                    <div className='amount-fees-row'><span>Total</span><span>{totalAmount} USDT</span></div>
                                </div>
                                <div className='transaction-fee-notification'>
                                    * Our transaction fee are included. <span>See transaction fee</span>
                                </div>
                                {/* <div className='wallet-address-wrapper'>
                                    <div>Lorem ipsum dolor sit amet, consectetuer</div>
                                    <div>
                                        <div>
                                            <img src='/images/dashboard/topup/tabler_wallet.svg' alt='wallet' />
                                            <div className='wallet-address'>
                                                <span>Wallet code</span>
                                                <span id='wallet-address-value'>FJG97GR54EAZD452CX3AZEZE23WXWSD2548SHC</span>
                                            </div>
                                        </div>
                                        <button className='copy-btn' type='button' onClick={copyAddress}>
                                            <img src='/images/dashboard/topup/tabler_copy.svg' 
                                            alt='2 squares with the highest one its right half clipped' hidden={isCopied} />
                                            <span hidden={!isCopied}>Copied!</span>
                                        </button>
                                    </div>
                                </div> */}
                                <div className='group-btn'>
                                
                                <Button click={handleCancel} id="cancel">Cancel</Button>
                                <Button btnType='submit' id='confirm_topup' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Confirm</Button> 
                                </div>
                                
                            </Form>)}}
    </Formik> 
    {/* </Modal.Body>
    <Modal.Footer> */}
      
      {/* <ButtonBootstrap variant="primary" onClick={handleClose}>
        Save Changes
      </ButtonBootstrap> */}
    {/* </Modal.Footer> */}
   </Modal> 
  )
}

export default Topup 