import React, { useState, useRef, useEffect } from 'react'           
import './CSS/transactionshistory.css'  
import TransactionsVisulizer from '../components/TransactionsVisulizer' 
import TopUpVisualizer from '../components/TopUpVisualizer'  
import moment from 'moment';   
import { getAllDeposits, getCardTopupHistory } from '../security/AuthService'; 
import Loader from '../jsx/pages/Loader/Loader';    
import DepositVisualizer from '../components/DepositVisualizer'; 
const TransactionsHistory = () => { 
    const [depositHistory, setDepositHistory] = useState({data: []}) 
    const [topupHistory, setTopupHistory] = useState({data: []}) 
    const [filteredDepositHistory, setFilteredDepositHistory] = useState({data: []}) 
    const [filteredTopupHistory, setFilteredTopupHistory] = useState({data: []}) 
    const activeFilterPrevTrx = useRef(null)  
    // const [activeFilterTopUp, setActiveFilterTopUp] = useState(null)
    const activeFilterTopUp = useRef(null) 
    const [isLoading, setIsLoading] = useState(false) 

    useEffect(()=>{
        getAllTransactionsHistory() 
    }, [])  
    async function getAllTransactionsHistory () {
        try {
            setIsLoading(true)  
            const all_deposits = await getAllDeposits()
            const all_deposits_result = await all_deposits.json() 
            const all_topup = await getCardTopupHistory()
            const all_topup_result = await all_topup.json() 
            if(all_deposits.ok && all_topup.ok) {
                console.log(all_deposits_result)
                console.log(all_topup_result) 
                setDepositHistory({...depositHistory, data: all_deposits_result['data'].reverse()}); 
                setFilteredDepositHistory({...filteredDepositHistory, data: all_deposits_result['data']})    
                setTopupHistory({...topupHistory, data: all_topup_result['data']}); 
                setFilteredTopupHistory({...filteredTopupHistory, data: all_topup_result['data']}) 
            } 
        } catch(err) {

        } finally {
            setIsLoading(false) 
        }
         
    }
    function activeFilterPrevTrxHandler (e) {
        //console.log(e.target.innerHTML)
        // setActiveFilterPrevTrx(e.target.innerHTML) 
        activeFilterPrevTrx.current = e.target.innerHTML 
        //console.log(activeFilterPrevTrx.current)  
        handleFilterDepositHistory()
    }
    function activeFilterTopUpHandler (e) {
        activeFilterTopUp.current = e.target.innerHTML 
        handleFilterTopupHistory() 
    } 

    function handleFilterDepositHistory() {
        
        if(activeFilterPrevTrx.current==='Current Month') {
            const filteredData = depositHistory['data'].filter((trx)=> (((moment().month() + 1) === moment(trx?.createdAt?.date).month() + 1) && ((moment(trx?.createdAt?.date).year()) === moment().year() )))    
            return  setFilteredDepositHistory({...filteredDepositHistory, data: filteredData})
               
        } else if(activeFilterPrevTrx.current === 'Current Week') {
            const filteredData = depositHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('week').valueOf())))     
            return  setFilteredDepositHistory({...filteredDepositHistory, data: filteredData}) 
        } else if(activeFilterPrevTrx.current === 'Today') {
            const filteredData = depositHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf() >= moment().startOf('day')) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('day'))))   
            return  setFilteredDepositHistory({...filteredDepositHistory, data: filteredData})  
        } else {

            return setFilteredDepositHistory(depositHistory['data']) 
        } 
    
} 
function handleFilterTopupHistory() {
    
    if(activeFilterTopUp.current==='Current Month') {
        const filteredData = topupHistory["data"].filter((trx)=> (((moment().month() + 1) === moment(trx?.createdAt?.date).month() + 1) && ((moment(trx?.createdAt?.date).year()) === moment().year() )))    
        return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})
           
    } else if(activeFilterTopUp.current === 'Current Week') {
        const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('week').valueOf())))     
        return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData}) 
    } else if(activeFilterTopUp.current === 'Today') {
        const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf() >= moment().startOf('day')) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('day'))))  
        return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})  
    } else {

        return setFilteredTopupHistory(topupHistory['data']) 
    } 

} 
  return (
    

    <>
        {!isLoading && <div className='allTransactionsHistory'>  
                            <section className='history-data-container'>
                                <div className='previous-transactions-wrapper'>
                                    <div className='previous-transactions-wrapper-header'>
                                        <div className='previous-transactions-wrapper-header-text-content'>
                                            <span>Deposit History </span>
                                            {(depositHistory['data'].length > 0) && 
                                            <>
                                            {(activeFilterPrevTrx.current === 'Current Month') && <span>Current month's transactions</span>}
                                            {(activeFilterPrevTrx.current === 'Current Week') && <span>Current Week's transactions</span>}
                                            {(activeFilterPrevTrx.current === 'Today') && <span>Today's transactions</span>}
                                            {(activeFilterPrevTrx.current === null) && <span>All Deposit History</span>}                     
                                            </>}
                                        </div>
                                        {(depositHistory['data'].length > 0) && <div className='previous-transactions-wrapper-header-filter'>
                                            <button className={`${activeFilterPrevTrx.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler} >Current Month</button>
                                            <button className={`${activeFilterPrevTrx.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Current Week</button>
                                            <button className={`${activeFilterPrevTrx.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Today</button>
                                        </div>}
                                    </div>
                                    
                                <DepositVisualizer data={filteredDepositHistory['data']} />      

                                </div>
                                <div className='top-up-history-wrapper'>
                                    <div className='previous-transactions-wrapper-header'>
                                        <div className='previous-transactions-wrapper-header-text-content'>
                                            <span>Top-up History  </span>
                                            {(topupHistory['data'].length > 0) && 
                                            <>
                                            {(activeFilterTopUp.current === 'Current Month') && <span>Current month's transactions</span>}
                                            {(activeFilterTopUp.current === 'Current Week') && <span>Current Week's transactions</span>}
                                            {(activeFilterTopUp.current === 'Today') && <span>Today's transactions</span>}
                                            {(activeFilterTopUp.current === null) && <span>All Top-up History</span>}                   
                                            </>}
                                        </div>
                                        {(topupHistory['data'].length > 0) &&<div className='previous-transactions-wrapper-header-filter'>
                                            <button className={`${activeFilterTopUp.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler} >Current Month</button>
                                            <button className={`${activeFilterTopUp.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Current Week</button>
                                            <button className={`${activeFilterTopUp.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Today</button>
                                        </div>} 
                                    </div>
                                    
                                    <TopUpVisualizer data={filteredTopupHistory['data']} />        

                                </div>
                                
                            </section>
                       </div>
        }  


    {isLoading && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
    </>
  )
}

export default TransactionsHistory 
 
