import React, { useEffect, useRef, useState } from 'react'
import  IntlTelInput  from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";

import { intlTelInput } from "intl-tel-input/react"

const Phone = (props) => {
    
    const ref = useRef(false)
    const [isValid, setIsValid] = useState(null);
    const [number, setNumber] = useState(null);
    const [error, setError] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null)
    // const [err, setErr] = useState(null)
    let init = 0
    // let err = false
    useEffect(()=>{
        // const iti = intlTelInput(input);
        // const input = document.querySelector("#phone");
        // window.intlTelInput(input, {
        //     utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.8.1/build/js/utils.js",
        // });
        // console.log(intelinput.current.isValidNumber())
        //console.log(props.intel.current.getInstance().getValidationError()) 
        // document.querySelector('.iti__tel-input')?.addEventListener('keyUp', ()=>{
        //   console.log('KEY UP')
        // }) 
        // intelinput.current.getInstance().setNumber("+447733123456")
        //console.log('Ref', ref.current)
        // console.log(document.querySelector('.iti__tel-input').addEventListener('keyup'))
        document.querySelector('.iti__tel-input').addEventListener('keyup', ()=>{
                  //console.log('KEY UP')
                  // ref.current = true
                  // document.getElementById('errorrrrr').style.display = 'none'
                  // setErr(true)
                  setNumber(true)
                  //console.log('init', init)
                  if(props.intel.current?.getInstance().isValidNumber()) {
                    setError(null)
                    //console.log('NO ERROR')
                  } else {
                    if(props.intel.current?.getInstance().getNumber()==='' && props.intel.current?.getInstance().getValidationError()===2) {
                      setErrorMsg('Required!')
                    } else if (props.intel.current?.getInstance().getValidationError()===2 && props.intel.current?.getInstance().getNumber()!=='') {
                      setErrorMsg('Too Short')
                    } else if(props.intel.current?.getInstance().getValidationError()===1) {
                      setErrorMsg('Invalid Country Code')
                    } else if(props.intel.current?.getInstance().getValidationError()===3) {
                      setErrorMsg('Too Long')
                    } else if(props.intel.current?.getInstance().getValidationError()===4) {
                      setErrorMsg('Is Possible Local Only')
                    } else if(props.intel.current?.getInstance().getValidationError()===5) {
                      setErrorMsg('Invalid Length')
                    }
                    props.intel.current?.getInstance().setNumber(props.intel.current?.getInstance().getNumber())
                    setError(true)
                    ref.current = true
                    // ref.current = true
                    // setErr(true)
                    //console.log('ERROR', 'NOW')
                    //console.log('VALID', props.intel.current?.getInstance().isValidNumber())
                    // err = true 
                    props.handleDisable(props.intel.current?.getInstance().isValidNumber())
                    //console.log('IS VALID', props.intel.current?.getInstance().isValidNumber())
                    // console.log(err)
                  }
                  //console.log(props.intel.current?.getInstance().getNumber() === '')
                  props.handleDisable(props.intel.current?.getInstance().isValidNumber())

                   
        })
          
    })
    // function InputHanlder (){
    //   setErr(true)
    //   console.log('init', init)
    //   if(intelinput.current?.getInstance().isValidNumber()) {
    //     setError(null)
    //     console.log('NO ERROR')
    //     setErr(false)
    //     // err = true
    //   } else {
    //     setError(true)
    //     init = true 
    //     // ref.current = true
    //     // setErr(true)
    //     console.log('ERROR')
    //     // err = true 
    //     console.log(err)
    //   }
    //   ref.current = true 
    //   console.log(intelinput.current?.getInstance().isValidNumber()); }
    // {  
    //   () 
    //   ? 'Required !' : 
    //   ((intelinput.current?.getInstance().getValidationError()===2) && (intelinput.current?.getInstance().getValidationError() !== '')? 'TOO_SHORT' : '1')}
    //   const result = (
    //     if(intelinput.current?.getInstance().getNumber() === '' && (intelinput.current?.getInstance().getValidationError()===2)))
      
  return (
    <>
    {/* <IntlTelInput
    // onChangeNumber={setNumber}
    // onChangeValidity={setIsValid}
    // name={props.name}
    initOptions={{
        initialCountry: "us",
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.8.1/build/js/utils.js",
        strictMode: true, 
        useFullscreenPopup: false 
    }}
    ref={props.intel}
    // onChangeValidity={}
    // onChangeCountry={}
    // onChangeNumber={InputHanlder}
    // onkeyUp={()=>console.log('HI')}
    // onChangeErrorCode={()=>console.log(intelinput.current?.getInstance().getValidationError())}
    // onChangeValidity={()=>console.log("Validity", ref.current)}
    // onChangeErrorCode={}
/> */}
    {/* {( ref.current && error) && <div >{  (intelinput.current?.getInstance().getNumber() === '') 
    ? 'Required !' : 
    ((intelinput.current?.getInstance().getValidationError()===2) ? 'TOO_SHORT' : ''}</div>} */}
    {/* <div id='errorrrrr'></div> */}
    {/* {( ref.current && error) && <div >{  
      (intelinput.current?.getInstance().getNumber() === '' && (intelinput.current?.getInstance().getValidationError()===2)) 
      ? 'Required !' : 
      ((intelinput.current?.getInstance().getValidationError()===2) && (intelinput.current?.getInstance().getValidationError() !== '')? 'TOO_SHORT' : '1')}</div>} */}
      
      <div className='form_control'>
        {props.label && <label htmlFor={props.id} className='form_label'>{props.label}</label>}
        <IntlTelInput
          // onChangeNumber={setNumber}
          // onChangeValidity={setIsValid}
          // name={props.name}
          initOptions={{
              initialCountry: "us",
              utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.8.1/build/js/utils.js",
              strictMode: true, 
              excludeCountries: ["af", "tn", "ag", "ar", "aw", "bs", "bd","bb","by","bz", "cm", "ky", "hr", "cd", "cg", "ec", "sv", "gi", "ht", "iq", "ir", "jm", "jo", "lb", "ml", "mz", 
                "pa", "ph", "ru", "sn", "ss", "lk", "sy", "ug", "vu", "vn", "vg", "vi", "ye" 
              ] ,   
              useFullscreenPopup: false,
              autoPlaceholder: 'aggressive',
              customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                return 'Enter Phone Number'
              },
              
          }}
          ref={props.intel}
          // onChangeValidity={}
          // onChangeCountry={}
          // onChangeNumber={InputHanlder}
          // onkeyUp={()=>console.log('HI')}
          // onChangeErrorCode={()=>console.log(intelinput.current?.getInstance().getValidationError())}
          // onChangeValidity={()=>console.log("Validity", ref.current)}
          // onChangeErrorCode={}
        />
        {( ref.current && error) &&<div style={{fontSize: '12px',
                                                fontWeight: '500',
                                                lineHeight: '20px',
                                                color: 'red',
                                                paddingLeft: '10px'}}>
        {errorMsg}</div>}
      </div>
    </>
  )
}

export default Phone
