import React from 'react'

const Button = (props) => {
  return (
    <button className={`button-container ${props.styles && props.styles}`}  onClick={props.click} 
    type={`${props.btnType === 'submit' ? 'submit' : 'button'}`} id={props.id} disabled={props.disabled}>
        {props.imagesrc && <img src={props.imagesrc} alt={props.altText} />}
        <span>{props.children}</span>
    </button>
  )
}

export default Button