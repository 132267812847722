import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/paytabsmastercard.css'
import SignUpDashboard from '../../../components/SignUpDashboard'                      
const PaytabsMasterCard = () => {
  return (
    <section id='paytabs-master-card'>

      <div className='absolute-layer1'></div>
      <div className='absolute-layer2'></div>
      <div className='absolute-layer3'></div>
      <div className='absolute-layer4'></div>

      <div className='paytabs-master-card-container'>
        <div className='paytabs-master-card-title mt-165'>PAYTAPS</div>
        <img src='/images/landingpage/body/Group 2.svg' alt='cards' loading='eager' draggable='false' />
        <div className='paytabs-master-card-title'>MASTERCARD CARD</div>
        <p className='paytabs-master-card-subtitle'>Global Coverage with Limitless Opportunities</p>
        <SignUpDashboard classStyles='paytabs-master-card-get-now' linkLabel='Get Your PAYTAPS Card Now!' />        
      </div>

    </section>
  )
}

export default PaytabsMasterCard