import React from 'react'
import './CSS/resetpasswordemail.css' 
import { Link,useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import TextInput from '../components/TextInput'
import { generateOTPForgetPassword, formatError  } from '../security/AuthService'
const ResetPasswortEmail = () => {
    const navigate = useNavigate()
    //let state 

    // const keyUpHandler = (e)=>{
    //     state.setFieldValue(e.target['name'], e.target.value); 
    //     state.setFieldTouched(e.target['name'])
    // }
  return (
    <section className='resetPasswordEmailContainer'>
        <div className='resetPasswordEmailContent'>
            <Link to='/'>
                <img src='/images/registration/Group 1.svg' alt='paytabs logo' loading='lazy' draggable='false' className='paytapsLogo' />
            </Link>
            <div className='resetPassword'>Reset Password </div>
            <div className='resetPasswordEnterCodeSentEmail'>Enter your Email an OTP code will be sent to you!</div>
            <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}

                            validationSchema={YUP.object({
                                email: YUP.string().required('Required!').email('Invalid Email!'),
                            })}

                            onSubmit={ async (values, {setSubmitting, resetForm})=>{
                                console.log("reponse")
                                const result = await generateOTPForgetPassword({email: values.email}) 
                                console.log("reponse2",result)
                                const reponse = await result.json()
                                console.log("reponse3",reponse)
                                if(result.ok && reponse.message === 'successfully') {
                                  console.log(reponse)
                                  navigate('/resetpasswordverification', { state: { email: values.email } })
                                }
                                setSubmitting(false)
                                resetForm()
                                
                            }}
                        >
                            {/* {(formik) => {

                             state = formik
                            
                            return (

                                <Form className='form' onSubmit={formik.handleSubmit}>
                                    <TextInput inputtype='email' name='email' id='email' label='Email' error={formik.errors.email} touched={formik.touched.email} onKeyUp={keyUpHandler} />

                                   

                                    <button type='submit' id='resetpassword' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Reset</button> 

                                </Form>)}} */}
                                 {(formik) => (
            <Form className='form' onSubmit={formik.handleSubmit}>
              <TextInput
                inputtype='email'
                name='email'
                id='email'
                label='Email'
                error={formik.errors.email}
                touched={formik.touched.email}
                onChange={formik.handleChange}  
                onBlur={formik.handleBlur}      
              />
              <button
                type='submit'
                id='resetpassword'
                disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
              >
                Reset
              </button>
            </Form>
          )}
        </Formik>
                        

                        <div className='already-have-account'>
                            Remember It? 
                            <Link to='/login' style={{
                                fontWeight: '700',
                                color: '#0E0E0E'
                            }}>  Sign in here </Link>
                        </div>
        </div>
    </section>
  )
}

export default ResetPasswortEmail