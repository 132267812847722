import { Suspense } from "react"
import { defer, useLoaderData, Await } from "react-router-dom" 
export default function Main () {
    const item = useLoaderData()
    // const result = data.map((user)=>{
    //     return (
    //         <div key={user.id}>
    //             {user.name}
    //         </div>
    //     )
    // })
    function maindata(data) {
        return data.map((user)=>{
            return (
                <div key={user.id}>
                    {user.name}
                </div>
            )})
    }
    return (
        <>
        <h1>Main</h1>
        <Suspense fallback={<div>Loading...</div>}>
        <Await resolve={item.data}>
            {(item)=>{
                return maindata(item)
            }}
        </Await>
        </Suspense>
        
        </>
    )
} 
async function appdata() {
    const data =  await fetch('https://jsonplaceholder.typicode.com/users')
    const info = await data.json()
    return info
}
export function loader () {
    // const data =  await fetch('https://jsonplaceholder.typicode.com/users').then((res)=>
    //     res.json()
    // )
    // const info =  data.json()
    // console.log(info)
    // let data = appdata()
    
    return defer({data:  appdata()})
}
