// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bodyTC {
    height: 500px;
    overflow-y: scroll; 
    color: black; 
}
.confirmTermsConditions > .modal-dialog {
    width: 100%;
    font-family: var(--font-poppins);
    box-shadow: 0 8px 16px 0.5px #1C2B4626, 0px 0px 1px #52648433; 
    margin: auto;
}
.confirmTermsConditions > .modal-dialog > .modal-content {
    border-radius: 5px;
} 
#bodyTC::-webkit-scrollbar {
    width: 4px;
  }
   
  /* Handle */
  #bodyTC::-webkit-scrollbar-thumb {
    background: #1eaae7;
    /* border-radius: 5px; */
  }


  .confirmTermsConditions .modal-title, .confirmTermsConditions h5 {
    color: #1eaae7; 
  }  
  #bodyTC p {
    font-size: 15px; 
  }

  #bodyTC + .modal-footer {
    padding: 8px 30px 8px 15px; 
  }
  #confirmTermsConditionsBtn {
    border: 1.5px solid #1EAAE7;
    color: #FFFFFF;
    background: #1EAAE7;
    padding: 8px 10px;
    width: 90px;
    border-radius: 5px;
  }
  #cancelTermsConditions {
    border: 1.5px solid #FF2E2E;
    border-radius: 5px;
    background: #FF2E2E;
    padding: 8px 10px;
    width: 90px;
  }
  button#confirmTermsConditionsBtn:disabled {
    opacity: 0.7; 
    cursor: not-allowed; 
  }
  @media screen and (max-width: 576px) {
    .confirmTermsConditions > .modal-dialog {
      width: calc(100% - 30px);  
  }
  }`, "",{"version":3,"sources":["webpack://./src/Modals/CSS/confirmtermsconditions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,WAAW;IACX,gCAAgC;IAChC,6DAA6D;IAC7D,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,UAAU;EACZ;;EAEA,WAAW;EACX;IACE,mBAAmB;IACnB,wBAAwB;EAC1B;;;EAGA;IACE,cAAc;EAChB;EACA;IACE,eAAe;EACjB;;EAEA;IACE,0BAA0B;EAC5B;EACA;IACE,2BAA2B;IAC3B,cAAc;IACd,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,2BAA2B;IAC3B,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;EACb;EACA;IACE,YAAY;IACZ,mBAAmB;EACrB;EACA;IACE;MACE,wBAAwB;EAC5B;EACA","sourcesContent":["#bodyTC {\n    height: 500px;\n    overflow-y: scroll; \n    color: black; \n}\n.confirmTermsConditions > .modal-dialog {\n    width: 100%;\n    font-family: var(--font-poppins);\n    box-shadow: 0 8px 16px 0.5px #1C2B4626, 0px 0px 1px #52648433; \n    margin: auto;\n}\n.confirmTermsConditions > .modal-dialog > .modal-content {\n    border-radius: 5px;\n} \n#bodyTC::-webkit-scrollbar {\n    width: 4px;\n  }\n   \n  /* Handle */\n  #bodyTC::-webkit-scrollbar-thumb {\n    background: #1eaae7;\n    /* border-radius: 5px; */\n  }\n\n\n  .confirmTermsConditions .modal-title, .confirmTermsConditions h5 {\n    color: #1eaae7; \n  }  \n  #bodyTC p {\n    font-size: 15px; \n  }\n\n  #bodyTC + .modal-footer {\n    padding: 8px 30px 8px 15px; \n  }\n  #confirmTermsConditionsBtn {\n    border: 1.5px solid #1EAAE7;\n    color: #FFFFFF;\n    background: #1EAAE7;\n    padding: 8px 10px;\n    width: 90px;\n    border-radius: 5px;\n  }\n  #cancelTermsConditions {\n    border: 1.5px solid #FF2E2E;\n    border-radius: 5px;\n    background: #FF2E2E;\n    padding: 8px 10px;\n    width: 90px;\n  }\n  button#confirmTermsConditionsBtn:disabled {\n    opacity: 0.7; \n    cursor: not-allowed; \n  }\n  @media screen and (max-width: 576px) {\n    .confirmTermsConditions > .modal-dialog {\n      width: calc(100% - 30px);  \n  }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
