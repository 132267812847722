import React, { useRef, useEffect } from 'react'
import './CSS/suretocontinuetopup.css'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button'
const InsufficientBalance_top_up = (props) => {
    // const initialRender = useRef(true);
    useEffect(()=>{
        // if(window.innerWidth >= 600) {
        //     // props.initialRender
        //     console.log(props.initialRender.current)
        //     if(document.querySelector('.confirmation > .modal-dialog')) {
        //         if (props.initialRender) {
        //             (document.querySelector('.confirmation > .modal-dialog').style.left = '-8px') 
        //         } else {
        //             (document.querySelector('.confirmation > .modal-dialog').style.left = '0px') 
        //         }
                
        //     }
        //     }

            // if (props.initialRender.current) {
            //         (document.querySelector('.confirmation > .modal-dialog').style.left = '-8px') 
            //     }
                
            // }
            // else {
            // }
        
    })
  return (
    <Modal show={props.show} onHide={props.handleClose} className='confirmation' backdrop='static' keyboard='false'>
    <div>
        <figure>
            <img src='/images/dashboard/mycard/Group 318 (1).svg' alt='wallet with crypto coins and fiat money' draggable='false' />
        </figure>
        <div className='sure-to-continue-top-up'>Insufficient  Balance </div>
        <div>Your current balance is not enough to complete this transaction. Please add the necessary funds to your wallet and try again.</div>
    </div>
    <div className='group-btn'>
        <Button click={props.handleCloseInsufTopupBalance}>Cancel</Button>
        <Button>Deposit</Button>
    </div>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
  )
}

export default InsufficientBalance_top_up