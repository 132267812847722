import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Link, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext";
import ToggleContextProvider from './context/ToggleContext';
import ActiveContextProvider from './context/ActiveContext';
import AlignModalAdjustContextProvider from './context/AlignModalAdjustContext';
import EnableCountDownContextProvider from './context/EnableCountDown';
import { router } from './jsx/routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store = {store}>
      {/* <BrowserRouter basename='/react/demo'> */}
        <ThemeContext>
          {/* <App /> */}
          <ToggleContextProvider>
            <ActiveContextProvider>
              <AlignModalAdjustContextProvider>
                <EnableCountDownContextProvider>
                  <RouterProvider router={router} />
                </EnableCountDownContextProvider>
              </AlignModalAdjustContextProvider>
            </ActiveContextProvider>
          </ToggleContextProvider>
        </ThemeContext>
      {/* </BrowserRouter> */}
    </Provider>
  </React.StrictMode>
);
reportWebVitals();