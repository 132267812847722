import React, { useState, useCallback } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/logo.png";
import logoText from "../../../images/logo-text.png";
import { useToggle } from "../../../context/ToggleContext";
export function  NavMenuToggle(){
	setTimeout(()=>{	
		let mainwrapper = document.querySelector("#main-wrapper");
      if(mainwrapper) {
         if(mainwrapper.classList.contains('menu-toggle')){
            mainwrapper.classList.remove("menu-toggle");
         }else{
            mainwrapper.classList.add("menu-toggle");
         }
      }
		
	},10);
   // let mainwrapper = document.querySelector("#main-wrapper");
	// 	if(mainwrapper.classList.contains('menu-toggle')){
	// 		mainwrapper.classList.remove("menu-toggle");
	// 	}else{
	// 		mainwrapper.classList.add("menu-toggle");
	// 	}
}

const NavHader = () => {
   // const togglerContainerStyles = {
   //    display: 'flex',
   //    justifyContent: 'center',
   //    alignItems: 'center',
   //    width: '100%',
   //    height: '100%',
   //    position: 'relative',
   //    left: '-4px'
   // }
   // const [toggle, setToggle] = useState(false);
   // const toggleHandler = useCallback(function toggleHandler () {
   //    setToggle(!toggle)
   //    NavMenuToggle()
   // }, [toggle])
   const { toggle, toggleHandler } = useToggle()
   // function toggler () {
   //    if(window.innerWidth >= 1024) {
   //       toggleHandler()
   //    } else {
   //       toggleHandler()
   //       toggleHandler()
   //    }
   //    toggleHandler()
   // }
   return (
      <div className="nav-header">
         {/* <Link to="/" className="brand-logo">
            <img className="logo-abbr" src={logo} alt="" />
            <img className="logo-compact" src={logoText} alt="" />
            <img className="brand-title" src={logoText} alt="" />
         </Link> */}
         
         <div className='togglerContainerStyles' >
            <img src="/images/landingpage/header/Layer 2.svg"  alt="paytaps-main-logo" onClick={toggleHandler} style={toggle ? {cursor: 'pointer', display: 'none'} : {cursor: 'pointer'}} className="toggler-trigger" />  
            <img src="/images/dashboard/header/paytaps icon.svg" alt="paytaps-icon-logo" onClick={toggleHandler} style={!toggle ? {width: '52px', height: '52px', cursor: 'pointer', display: 'none'} : {width: '52px', height: '52px', cursor: 'pointer'}} className="toggler-trigger" />
         </div>

         {/* <div className="nav-control" 
            onClick={() => {
               setToggle(!toggle)
               NavMenuToggle()
            }
            }
         >
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div> */}
      </div>
   );
};

export default NavHader;

