// import axios from 'axios';
// import swal from "sweetalert";
// import {
//     loginConfirmedAction,
//     Logout,
// } from '../store/actions/AuthActions';
// import {  API_HOST } from '../config/env';

// const API_URL = API_HOST() + "api/";

// const  signUp =(email, password) =>{

//     return axios.post(API_URL + "signup", {
//         email,
//         password,
//       }).then(response => {
//         return response.data; // Retourner les données de la réponse si nécessaire
//     }).catch(error => {
//         // Gérer les erreurs ici
//         console.error("Erreur lors de l'inscription:", error);
//         throw error; // Lancer l'erreur pour la gérer plus tard si nécessaire
//     });
// }

// const login = (username, password) => {
//     return axios
//     .post(API_URL + "login", {
//         username,
//       password,
//      // returnSecureToken: true,
//     })
//     .then((response) => {
//         console.log("bb",response)
//       if (response.data.token) {
//         localStorage.setItem("user", JSON.stringify(response.data));
//       }

//       return response.data;
//     }).catch(error => {
//         // Gérer les erreurs ici
//         console.error("Erreur lors de login:", error);
//         throw error; // Lancer l'erreur pour la gérer plus tard si nécessaire
//     });
    
// }

// const logout = () => {
//     localStorage.removeItem("user");
//   };

// const  formatErrorOld=(errorResponse)=>  {
//     console.log(errorResponse)
//     switch (errorResponse.error.message) {
//         case 'EMAIL_EXISTS':
//             //return 'Email already exists';
//             swal("Oops", "Email already exists", "error");
//             break;

//         case 'EMAIL_NOT_FOUND':
//             //return 'Email not found';
//            swal("Oops", "Email not found", "error",{ button: "Try Again!",});
//            break;
//         case 'INVALID_PASSWORD':
//             //return 'Invalid Password';
//             swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
//             break;
//         case 'USER_DISABLED':
//             return 'User Disabled';

//         default:
//             return '';
//     }
// }

// const  formatError=(errorResponse)=>  {
//     console.log(errorResponse)
//     switch (errorResponse.error) {
//         case 'Invalid credentials.':
//             //return 'Email already exists';
//             swal("Oops", "Invalid credentials", "error");
//             break;
//         case 'Email already exists':
//             //return 'Email already exists';
//             swal("Oops", "Email already exists", "error");
//             break;
//         case 'EMAIL_NOT_FOUND':
//             //return 'Email not found';
//            swal("Oops", "Email not found", "error",{ button: "Try Again!",});
//            break;
//         case 'INVALID_PASSWORD':
//             //return 'Invalid Password';
//             swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
//             break;
//         case 'USER_DISABLED':
//             return 'User Disabled';

//         default:
//             return '';
//     }
// }

// // export function saveTokenInLocalStorage(tokenDetails) {
// //     tokenDetails.expireDate = new Date(
// //         new Date().getTime() + tokenDetails.expiresIn * 1000,
// //     );
// //     localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
// // }

//  const  runLogoutTimer = (dispatch, timer, navigate)=> {
//      setTimeout(() => {
//          //dispatch(Logout(history));
//          dispatch(Logout(navigate));
//      }, timer);
//  }

//  const  checkAutoLogin =(dispatch, navigate)=> {
//      const tokenDetailsString = localStorage.getItem('userDetails');
//      let tokenDetails = '';
//      if (!tokenDetailsString) {
//          dispatch(Logout(navigate));
//  		return;
//      }

//      tokenDetails = JSON.parse(tokenDetailsString);
//      let expireDate = new Date(tokenDetails.expireDate);
//      let todaysDate = new Date();

//     if (todaysDate > expireDate) {
//          dispatch(Logout(navigate));
//          return;
//      }

//      dispatch(loginConfirmedAction(tokenDetails));
	
//      const timer = expireDate.getTime() - todaysDate.getTime();
//      runLogoutTimer(dispatch, timer, navigate);
//  }
// export default {
//     signUp,
//     login,
//     logout,
//     formatError,
//     checkAutoLogin,
//     runLogoutTimer,
//   };
