import React, {useState} from 'react'
import { Link,useLocation, useNavigate  } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import TextInput from '../components/TextInput' 
import OtpInput from '../components/OtpInput'  
import { verifyOTPForgetPassword, formatError, refreshToken, killToken, saveToken } from '../security/AuthService'
import './CSS/resetpasswordverification.css'             
const ResetPasswordOTP = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { email } = location.state || {};
    //console.log(email);
    const handleOtpChange = (value) => {
        setOtp(value);
      };
  return (
    <section className='resetPasswordEmailContainer'>
        <div className='resetPasswordEmailContent'>
            <Link to='/'>
                <img src='/images/registration/Group 1.svg' alt='paytabs logo' loading='lazy' draggable='false' className='paytapsLogo' />
            </Link>
            <div className='resetPassword'>Enter your code  </div>
            <div className='resetPasswordEnterCodeSentEmail'>Please enter your OTP code here</div>

            <Formik
                            initialValues={{
                                otp: '',
                            }} 

                            validationSchema={YUP.object({
                                otp: YUP.string().required('Required!'),
                            })}

                            onSubmit={ async (values, {setSubmitting, resetForm})=>{
                              try {
                                // console.log("reponse") 
                                let result = await verifyOTPForgetPassword({otp: values.otp,email: email}) 
                                // If fetch returns 401 
                                if(result.status === 401) {
                                  const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                                  // If refresh token returns 401
                                  if(refreshTokenResult.status === 401) {
                                  killToken()
                                  return navigate('/login')
                                  } 

                                  // If refresh token is successfull 
                                  const payloadRefreshToken = await refreshTokenResult.json()   

                                  let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                                  // Payload of new generated token 
                                  let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                                  if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                                      killToken()
                                      return navigate('/login') 
                                  } else {
                                      saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                                      result = await verifyOTPForgetPassword({otp: values.otp,email: email})   
                                  }

                                }

                                // console.log("reponse2",result) 
                                const reponse = await result.json()
                                // console.log("reponse3",reponse) 
                                if(result.ok && reponse.message === 'OTP is valid') {
                                  // console.log(reponse) 
                                  navigate('/newpassword', { state: { email: email } })
                                } 
                                 
                                setSubmitting(false)
                                resetForm() 
                              } catch(err) {

                              } 
                            }}
                        >
                           
                                 {(formik) => (
                                <Form className='form' onSubmit={formik.handleSubmit}>
                                  <OtpInput length={6} 
                                //    onChange={handleOtpChange}  
                                      onChange={(otp) => formik.setFieldValue('otp', otp)} 
                                  />
                                  
                                  {/* <button type='submit' id='verifyNowOTP' disabled>Verify Now</button>  */}
                                  <button
                                    type='submit'
                                    id='verifyNowOTP'
                                    disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting || formik.values.otp.length !== 6}  
                                  > 
                                    Verify Now
                                  </button> 
                                </Form>  
          )}
        </Formik>
           


                        <div className='already-have-account'>
                            Remember It? 
                            <Link to='/login' style={{
                                fontWeight: '700',
                                color: '#0E0E0E'
                            }}>  Sign in here </Link>
                        </div>
        </div>
    </section>
  )
}

export default ResetPasswordOTP