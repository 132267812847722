import React from 'react'
import Slider from "react-slick";
import './CSS/testimonial.css'

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section id='testimonial'>
        <div className='testimonial-container'>
            <div className='testimonial-title'>Testimonials Section</div>
            <div className='testimonial-subtitle'>What Our Users Say</div>
            <Slider {...settings}>
                <div className='testimonial-text-wrapper'>
                      
                      <div>"No monthly fees and global accessibility make PAYTAPS the best card  I've ever used."</div>
                      <span className='testimonial-author'><span><img src='/images/landingpage/body/Image-94.svg' alt='avatar' loading='lazy' draggable='false' /></span>Emily R</span>
                      <span><img src='/images/landingpage/body/Icon.svg' alt='icon' loading='lazy' draggable='false' /></span>
                </div>
                <div className='testimonial-text-wrapper'>
                    
                    <div>"No monthly fees and global accessibility make PAYTAPS the best card  I've ever used."</div>
                    <span className='testimonial-author'><span><img src='/images/landingpage/body/Image-94.svg' alt='avatar' loading='lazy' draggable='false' /></span>Emily R</span>
                    <span><img src='/images/landingpage/body/Icon.svg' alt='icon' loading='lazy' draggable='false' /></span>
                </div>
                <div className='testimonial-text-wrapper'>
                    
                    <div>"No monthly fees and global accessibility make PAYTAPS the best card  I've ever used."</div>
                    <span className='testimonial-author'><span><img src='/images/landingpage/body/Image-94.svg' alt='avatar' loading='lazy' draggable='false' /></span>Emily R</span>
                    <span><img src='/images/landingpage/body/Icon.svg' alt='icon' loading='lazy' draggable='false' /></span>
                </div>
            </Slider>
        </div>
    </section>
  )
}

export default Testimonial