import React, { useState, useEffect } from 'react'
import { getcardDetails } from '../security/AuthService'              
const useCardDetails = () => {
  const [data, setData] = useState({})
  const [error, setError] = useState('') 
  const [loading, setLoading] = useState(false) 
  useEffect(()=>{
    getCardDetailsData()
  }, []) 
  async function getCardDetailsData () {
    setLoading(true)
    const card_details_result = await getcardDetails()
    const card_details_data = await card_details_result.json() 
    //console.log(card_details_data) 
    if(card_details_result.ok) {
      // setData({...data, card_details_data['data']})
    } else {
      setError('No Data')
    }
    setLoading(false) 
  }
return {data, error, loading} 
}

export default useCardDetails