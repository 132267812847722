import React from 'react'

const ThankYouForDeposit = () => {
  return (
    <div className='thankYouOrderCardContainer'>
            <div className='thankYouOrderCardWrapper'>
                <figure>
                    <img src='/images/dashboard/thankyoufordeposit/Wallet-pana 1.svg' alt='wallet with crypto coins and a card around it' />
                </figure>
                <div className='thankYouOrderCardContent'>
                    <div className='thankYouOrderCardContentTitle'>Thank you for your Deposit</div>
                    <p className='thankYouOrderCardContentDescription'>Thank you for your Paytaps wallet deposit <br /> 
                    you'll receive an email notification once your deposit is confirmed</p> 
                </div>
            </div>
        </div>
  )
}

export default ThankYouForDeposit 
