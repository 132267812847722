import React, { useState } from 'react'
import './CSS/select.css' 
const Select = (props) => { 
    const [state, setState] = useState(false)
    const [currentValue, setCurrentValue] = useState('Blockchain')  
    function handleCloseList () {
        setState(false) 
    }
    function handleSwitch () {
        if(state) {
            setState(false)
        } else {
            setState(true) 
        }
    } 
    function handleCurrentValue (e) {
        // if(e.currentTarget.dataset.type !== e.currentTarget.innerHTML) {
            props.handleResetForm()
        // }
        
        props.handleAccountType(e.currentTarget.dataset.type)     
        console.log(e.currentTarget.dataset.type)  
        setCurrentValue(e.currentTarget.innerHTML) 
        handleCloseList() 
         
    }
  return (
    <>
    <div className='selectContainer'>
        <div id='accounTypeLabel' >Account type</div>
        <button className='accountType' aria-labelledby='accounTypeLabel' type='button' onClick={handleSwitch}>
            <span>{currentValue}</span><img src='/images/dashboard/deposit/tabler_caret-down-filled.svg' alt='arrow' className={`${state ? 'rotate180' : ''} `} />  
        </button>  
        <div className='listWrapper' hidden={!state}>
            <ul>
                <li onClick={handleCurrentValue} data-type='Blockchain'>Blockchain</li>
                <li onClick={handleCurrentValue} data-type='CIEx Account'>CIEx Account</li>    
            </ul> 
        </div>
    </div>
    </>
  )
}

export default Select