import React from 'react'
import LpHeader from './LPHeader/LpHeader'
import LpBody from './LPBody/LpBody'
import LpFooter from './LPFooter/LpFooter'
import './lplayout.css'

const Lplayout = () => {
  return (
    <>
      <LpHeader />
      <main>
          <LpBody />
      </main>
      <LpFooter />
    </>
  )
}

export default Lplayout