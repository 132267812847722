import React, { useEffect } from 'react'
import './CSS/thankyouordercard.css' 

const ThankYouOrderCard = () => {
    
  return (
    <>
        <div className='thankYouOrderCardContainer'>
            <div className='thankYouOrderCardWrapper'>
                <figure>
                    <img src='/images/dashboard/thankyouordercard/thankyouforordering.svg' alt='an open envelope in front of it a paper rocket with two cards  on top of it, a plant at the bottom of the envelope, 
                    and a blue shield at the top left of the envelope' />
                </figure>
                <div className='thankYouOrderCardContent'>
                    <div className='thankYouOrderCardContentTitle'>Thank you for Ordering</div>
                    <p className='thankYouOrderCardContentDescription'>Thank you for your paytaps Visa Card request. <br /> 
                    You'll receive an email notification once your card is approved.</p>  
                </div>
            </div>
        </div>
    </>
  )
}

export default ThankYouOrderCard 