import React, { useState, useEffect } from 'react' 
import './CSS/myinfo.css'
import ProfileChunkData from '../components/ProfileChunkData'
import { getProfileData } from '../security/AuthService'  
import Loader from '../jsx/pages/Loader/Loader' 
import { Link } from 'react-router-dom' 
const MyInfo = () => {
    const [profile, setProfile] = useState({data: {}}) 
    const [error, setError] = useState('') 
    const [profileLoadingState, setProfileLoadingState] = useState(false)   
    useEffect(()=>{
        // try {
            // const result =  getProfileData()
            // const response = await result.json()
            // console.log(response) 
            // return null 
            handleProfileData() 
        //   }
        //   catch (err) {
        //     console.log(err) 
        //     throw err
        //   }
    }, []) 
    async function handleProfileData() {
        try {
            setProfileLoadingState(true) 
            const result =  await getProfileData()
            const finalData =  await result.json()
            console.log(finalData)
            if(result.ok && (finalData.message ==="successfully")) {
                setProfile({...profile, data: finalData.data})   
                console.log(finalData.data)  
                console.log(profile) 
                setProfileLoadingState(false) 
                setError('') 
            } else {
                setError(finalData.message)  
                setProfileLoadingState(false) 
            } 
        } catch(err) {
            setError('Error') 
        }
    } 
    if(error) {
       return  <>{error && <div className='errorLoadingProfile'>{error}</div>}</>   
    }
  return (
    <>
    {(!profileLoadingState && !error) && <div className='myinfo-container'> 
        <>
        { Object.keys(profile.data).length > 0 && <>
        <section className='profile-wrapper'>
            <div className='profile-header'>
                <figure>
                    <img src='/images/dashboard/profile/User-120.svg' alt='person/ user' draggable='false' />
                </figure>
                <div>
                    <div className='profile-info-title'>My profile info </div>
                    <p className='profile-info-bio'>Here is the information related to your PayTaps card.</p>    
                </div>
                
            </div>
            <div className='profile-body'>
                <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_user.svg' imagealt='User' detailLabel='Full name' detailValue={profile.data.firstName} />
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/tabler_user.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Full name</span>
                    <span>foulan ben foulan</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_brand-mailgun.svg' imagealt='Mailing brand' detailLabel='Email Address' detailValue={profile.data.emailAddress} />

            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/tabler_brand-mailgun.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Email Address</span>
                    <span>foulan@gmail.com</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_calendar-month.svg' imagealt='Calendar' detailLabel='Date of birth' 
            detailValue={`${(new Date(profile.data.dateOfBirth.date).getMonth()+1 > 9) ? new Date(profile.data.dateOfBirth.date).getMonth()+1: '0' + (new Date(profile.data.dateOfBirth.date).getMonth()+1)}/${(new Date(profile.data.dateOfBirth.date).getDate() > 9) ? new Date(profile.data.dateOfBirth.date).getDate() > 9 : '0' + (new Date(profile.data.dateOfBirth.date).getDate()) }/${new Date(profile.data.dateOfBirth.date).getFullYear()}`} />       

            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/tabler_calendar-month.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>date of birth</span>
                    <span>12/12/1990</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_phone.svg' imagealt='Phone' detailLabel='Phone number' detailValue={profile.data.mobile} /> 
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/tabler_phone.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>phone number</span>
                    <span>+216 55 555 555</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_user-circle.svg' imagealt='User surrounded with circle frame' detailLabel='Prefered name' detailValue={profile.data.preferredName} />
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/tabler_user-circle.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>prefered name</span>
                    <span>mr foulan</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_flag.svg' imagealt='Flag' detailLabel='Nationality' detailValue={profile.data.nationality} />
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/tabler_flag.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Nationality</span>
                    <span>tunisian</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-language.svg' imagealt='Globe' detailLabel='Country of residence' detailValue={profile.data.countryCode} /> 
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/ic_outline-language.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Country of residence</span>
                    <span>tunisia</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-transgender.svg' imagealt='Gender' detailLabel='Gender' detailValue={`${profile.data.gender.toLowerCase() === 'm' ? 'Male' : 'Female'}`} />  
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/ic_outline-transgender.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Gender</span>
                    <span>male</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-location-on.svg' imagealt='Location' detailLabel='Billing Address' detailValue={profile.data.buillingAddress} /> 
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/ic_outline-location-on.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Billing Address</span>
                    <span>ariana - soukra</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_baseline-corporate-fare.svg' imagealt='Building' detailLabel='Billing City' detailValue={profile.data.buillingCity} /> 
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/ic_baseline-corporate-fare.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Billing City</span>
                    <span>ariana </span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_current-location.svg' imagealt='Current location' detailLabel='Billing Country' detailValue={profile.data.buillingCountry} /> 
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/tabler_current-location.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Billing Country</span>
                    <span>tunisia </span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-markunread-mailbox.svg' imagealt='Postal box' detailLabel='Billing Zip Code' detailValue={profile.data.buillingZipCode} /> 
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/ic_outline-markunread-mailbox.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Billing Zip Code</span>
                    <span>2080</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-account-balance.svg' imagealt='Building from Roman era' detailLabel='Billing State' detailValue={profile.data.buillingState} /> 
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/ic_outline-account-balance.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Billing State</span>
                    <span>ariana</span>
                </div>
            </div> */}
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-check.svg' imagealt='Check mark' detailLabel='Delivery State' detailValue={profile.data.deliveryState} />  
            {/* <div className='profile-data-item'>
                <img src='/images/dashboard/profile/ic_outline-check.svg' />
                <div className='profile-data-item-values-wrapper'>
                    <span>Delivery State</span>
                    <span>in porgrass</span>
                </div>
            </div> */}
            </div>
            

        </section>
        <div className='profile-notification'>
            Please note that this information is view-only. For changes, contact <Link to='/contactus'>customer support</Link>.
        </div></>}</> 
    </div>}
    {(profileLoadingState && !error) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
     
    </>
  )
}

export default MyInfo
