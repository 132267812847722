// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#updatepassword {
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, #72BCFF, #5E0F8E);
    border: none;
    border-radius: 34px;
    box-shadow: 0px 4px 20px 0px #00000014;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.6px;
    color: #FFFFFF;
    margin-top: 20px;
}
button#updatepassword:disabled {
    opacity: 0.8;
    cursor: not-allowed;
}

`, "",{"version":3,"sources":["webpack://./src/resetpassword/CSS/resetpasswordnewpassword.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,wDAAwD;IACxD,YAAY;IACZ,mBAAmB;IACnB,sCAAsC;IACtC,kCAAkC;IAClC,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["#updatepassword {\n    width: 100%;\n    height: 50px;\n    background: linear-gradient(to bottom, #72BCFF, #5E0F8E);\n    border: none;\n    border-radius: 34px;\n    box-shadow: 0px 4px 20px 0px #00000014;\n    font-family: Helvetica, sans-serif;\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 27.6px;\n    color: #FFFFFF;\n    margin-top: 20px;\n}\nbutton#updatepassword:disabled {\n    opacity: 0.8;\n    cursor: not-allowed;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
