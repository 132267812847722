// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thankYouOrderCardContainer {
    width: 100%;
    min-height: 100vh;
}
.thankYouOrderCardWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.thankYouOrderCardWrapper > figure > img {
    width: 100%;
    max-width: 452px; 
}
.thankYouOrderCardContent {
    width: 100%;
    font-family: var(--font-poppins);
    margin-top: 23px;
}
.thankYouOrderCardContentTitle {
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -1.2px;
    text-align: center;
    color: #364A63;
}
.thankYouOrderCardContentDescription {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -1px;
    text-align: center;
    color: #4E4E4E;
    margin-top: 37px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/CSS/thankyouordercard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,gCAAgC;IAChC,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".thankYouOrderCardContainer {\n    width: 100%;\n    min-height: 100vh;\n}\n.thankYouOrderCardWrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.thankYouOrderCardWrapper > figure > img {\n    width: 100%;\n    max-width: 452px; \n}\n.thankYouOrderCardContent {\n    width: 100%;\n    font-family: var(--font-poppins);\n    margin-top: 23px;\n}\n.thankYouOrderCardContentTitle {\n    font-size: 40px;\n    font-weight: 600;\n    line-height: 44px;\n    letter-spacing: -1.2px;\n    text-align: center;\n    color: #364A63;\n}\n.thankYouOrderCardContentDescription {\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 30px;\n    letter-spacing: -1px;\n    text-align: center;\n    color: #4E4E4E;\n    margin-top: 37px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
