// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_control {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.form_control:first-child {
    margin-top: 17px;
}
label.form_label {
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: var(--font-weight-600);
    line-height: 33px;
    color: var(--black-color);
    margin-bottom: 0;
    padding-left: 8px;
}

input.register-input {
    height: 43px;
    margin-bottom: 20px;
    border: 1.5px solid #D5D5D5;
    border-radius: 17px;
    padding-inline: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
    color: var(--black-color);
}

.error {
    border-color: red !important;
}

.correct {
    border-color: green !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/textinput.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gCAAgC;IAChC,eAAe;IACf,mCAAmC;IACnC,iBAAiB;IACjB,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".form_control {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n.form_control:first-child {\n    margin-top: 17px;\n}\nlabel.form_label {\n    font-family: var(--font-poppins);\n    font-size: 16px;\n    font-weight: var(--font-weight-600);\n    line-height: 33px;\n    color: var(--black-color);\n    margin-bottom: 0;\n    padding-left: 8px;\n}\n\ninput.register-input {\n    height: 43px;\n    margin-bottom: 20px;\n    border: 1.5px solid #D5D5D5;\n    border-radius: 17px;\n    padding-inline: 10px;\n    font-size: 15px;\n    font-weight: 400;\n    line-height: 27px;\n    color: var(--black-color);\n}\n\n.error {\n    border-color: red !important;\n}\n\n.correct {\n    border-color: green !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
