import React, { createContext, useEffect, useState, useCallback, useContext } from "react";
// import { NavMenuToggle } from "../jsx/layouts/nav/NavHader";
export const EnableCountDownContext = createContext(null);


const EnableCountDownContextProvider = (props) => {
    const [stateCountDown, setStateCountDown] = useState(false)
    // const toggleHandler = useCallback(function toggleHandler () {
    //     setToggle(!toggle)
    //     NavMenuToggle()
    //  }, [toggle])
    //  const initToggle = useCallback(()=>{
    //   setToggle(false)
    //  }, [])
     return (
        <EnableCountDownContext.Provider
          value={{
            stateCountDown,
            setStateCountDown
        }}
        >
          {props.children}
        </EnableCountDownContext.Provider>
     )
}

export default EnableCountDownContextProvider

export function useStateCountDown () {
    const {stateCountDown, setStateCountDown} = useContext(EnableCountDownContext)
    return {stateCountDown, setStateCountDown} 
}

