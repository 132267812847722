import React, {  useContext, useReducer, useState, useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import {Collapse, Dropdown} from 'react-bootstrap';
import {MenuList} from './Menu';
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import '../../../dashboard/CSS/navigationcustomization.css'
import { useToggle } from "../../../context/ToggleContext";
import { useActive } from "../../../context/ActiveContext";

let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1]
//   function initialActiveLink () {
//     let index_active_link = MenuList.findIndex(menulink=> (menulink.to==='/'+path))
//     console.log(index_active_link)
//     if(index_active_link === -1) {
//       return 'profile'
//     } else {
//       return MenuList[index_active_link].title
//     }
//   }
// export const reducer = (previousState, updatedState) => ({
//   ...previousState,
//   ...updatedState,
// });
// export const initialState = {
//   active : initialActiveLink(),
//   activeSubmenu : "",
// }


const SideBar = () => {
  var d  = new Date();
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
		ChangeIconSidebar,
  
	} = useContext(ThemeContext);
  const {state, setState, handleMenuActive} = useActive()
  // const [state, setState] = useReducer(reducer, initialState);
  const [isProfileValid, setIsProfileValid] = useState(true) 
   
  const {toggle} = useToggle()
  useEffect(()=>{
    const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))
    // console.log(decoded_payload['req'])
    // decoded_payload['req'] = 'N'
    if(decoded_payload['req'] === 'S') {
      setIsProfileValid(true)
    }
}, )
  useEffect(()=>{
    MenuList.forEach(function(item){
      document.getElementById(item.id)?.addEventListener('mouseenter', ()=>{
        document.querySelector('#menu > li:hover > a > span > span > img')?.setAttribute('src', item.active_icon)
      })
      document.getElementById(item.id)?.addEventListener('mouseleave', ()=>{
        if(state.active === item.title) {
          document.querySelector(`#menu > li > a#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon)
        }
        else {
          document.querySelector(`#menu > li > a#${item.id} > span > span > img`)?.setAttribute('src', item.normal_icon)
        }
      
      })
    })
   
    return ()=>{
      MenuList.forEach((item)=>{
        document.getElementById(item.id)?.removeEventListener('mouseenter', ()=>{})
        document.getElementById(item.id)?.removeEventListener('mouseleave', ()=>{})

      })
    }
    })
    
   

	  let handleheartBlast = document.querySelector('.heart');
	  function heartBlast(){
		  return handleheartBlast.classList.toggle("heart-blast");
	  }
  
 	const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)

 
	// const handleMenuActive = status => {		
	// 	setState({active : status});
	// 	// if(state.active === status){				
	// 	// 	setState({active : ""});      
	// 	// }   
	// }
	const handleSubmenuActive = (status) => {		
		setState({activeSubmenu : status})
		if(state.activeSubmenu === status){
			setState({activeSubmenu : ""})			
		}    
	}
	// Menu dropdown list End

  
    return (
        <div className="deznav">
          <PerfectScrollbar className="deznav-scroll">          
            <ul className="metismenu" id="menu" >
              {MenuList.map((data, index)=>{
                let menuClass = data.classsChange;
                  if(menuClass === "menu-title"){
                    return(
                        <li className={menuClass}  key={index} >{data.title}</li>
                    )
                  }else{
                    return(				
                      <li className={` ${ state.active === data.title ? 'mm-active' : ''}`}
                        key={index} 
                      >                        
                        {data.content && data.content.length > 0 ?
                            <>
                              <Link to={"#"} 
                                className="has-arrow"
                                onClick={() => {handleMenuActive(data.title)}}
                              >								
                                  {data.iconStyle}
                                  <span className="nav-text">{data.title}</span>
                              </Link>
                              <Collapse in={state.active === data.title ? true :false}>
                                  <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                    {data.content && data.content.map((data,index) => {									
                                      return(	
                                          <li key={index}
                                            className={`${ state.activeSubmenu === data.title ? "mm-active" : ""}`}                                    
                                          >
                                            {data.content && data.content.length > 0 ?
                                                <>
                                                  <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                                    onClick={() => { handleSubmenuActive(data.title)}}
                                                  >
                                                    {data.title}
                                                  </Link>
                                                  <Collapse in={state.activeSubmenu === data.title ? true :false}>
                                                      <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                                        {data.content && data.content.map((data,index) => {
                                                          return(	
                                                            <>
                                                              <li key={index}>
                                                                <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                                              </li>
                                                            </>
                                                          )
                                                        })}
                                                      </ul>
                                                  </Collapse>
                                                </>
                                              :
                                              <>
                                                
                                                <Link to={data.to}>
                                                  {data.title}
                                                </Link>
                                              </>
                                            }
                                            
                                          </li>                                        
                                      )
                                    })}
                                  </ul>
                                </Collapse>
                            </>
                        :
                          <Link  to={data.to}  onClick={() => {handleMenuActive(data.title)}} id={data.id} hidden={((data.title === 'Profile') ? ((!isProfileValid) ? true : false) : false)} > 
                            <span className={`link-logo-label-container ${toggle ? 'toggeled-icons-containers' : ''}`}>
                              {
                                state.active === data.title ? 
                                <span style={{marginRight: '2px'}}><img src={data.active_icon} /></span> : 
                                <span style={{marginRight: '2px'}}><img src={data.normal_icon} /></span> 

                              }
                              <span className={`nav-text link-label ${data.title === 'Profile' ? 'ml-2' : ''}`}>{data.title} </span>
                              </span>
                          </Link>
                        }
                        
                      </li>	
                    )
                  }
                })} 
            </ul>
            {/* <div className="copyright">
              <p><strong>Paytaps</strong> © 2024 All Rights Reserved</p>
              {/* <p>Made with{" "}
                <span                  
                  className="heart"
                  onClick={()=>heartBlast()}
                ></span>{" "}
                  by DexignZone
              </p> */}
            {/* </div>  */} 
			    </PerfectScrollbar>
        </div>
    );
  
}

export default SideBar;

