import { redirect } from 'react-router-dom';  
import {  API_HOST } from '../config/env';
const API_URL = API_HOST() + "api/";
export function register(payload) {
    return fetch(API_URL+'signup', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function login(payload) {
    // console.log(payload, API_URL+'login')
    // if(localStorage.getItem('u_t_p')) {

    // }
     
    return fetch(API_URL+'login', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function saveToken(payload) {
    // console.log(payload.split('.'))
    const splittedToken = payload.split('.')
    localStorage.setItem('u_t_h', splittedToken[0])
    localStorage.setItem('u_t_p', splittedToken[1])
    localStorage.setItem('u_t_s', splittedToken[2])
    // return localStorage.setItem('u_t', payload)
}

export function joinToken() {
    if(localStorage.getItem('u_t_h') && localStorage.getItem('u_t_p') && localStorage.getItem('u_t_s')) {
        return [localStorage.getItem('u_t_h'), localStorage.getItem('u_t_p'), localStorage.getItem('u_t_s')].join('.')
    } else {
        return null
    }
}

export function logout() {
    // console.log(joinToken()) 
    // const x = joinToken()
    // killToken()
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'logout', {
        method: 'POST', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken()  

        }
    })
}
export function killToken() {
    if(localStorage.getItem('u_t_h') && localStorage.getItem('u_t_p') && localStorage.getItem('u_t_s') && localStorage.getItem('m_t_u') && localStorage.getItem('o_t_p_t') && localStorage.getItem('t_u_f')) {
        localStorage.removeItem('u_t_h')
        localStorage.removeItem('u_t_p')
        localStorage.removeItem('u_t_s')
        localStorage.removeItem('m_t_u')
        localStorage.removeItem('o_t_p_t')
        localStorage.removeItem('t_u_f') 
        localStorage.removeItem('c_l') 
        return {kill: true}
    } 
    else {
        return null
    }
}
export function formatError (errorResponse) {
    switch (errorResponse) {
        case 'Invalid credentials.':
            return "Invalid credentials"
            break;
        case 'Email already exists':
            return "Email already exists"
            break;
        case 'EMAIL_NOT_FOUND':
           return "Email not found"
           break;
        case 'INVALID_PASSWORD':
            return "Invalid Password"
            break;
        case 'USER_DISABLED':
            return 'User Disabled';
        default:
            return '';
    }
}


export function requestCard(payload) { 
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'request-card', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
} 

export function userBalance() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'client/wallet/balance', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function generateAddress() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    
    return fetch(API_URL+'address/wallet/generete', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
export function regenerateAddress() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'address/wallet/regenerete', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function initialDeposit(payload) {
    // address/wallet/confirmation
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'address/wallet/confirmation', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
export function normalDeposit(payload) {
    // address/wallet/confirmation
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'request/deposit/confirmation', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function findRequestCard() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'find-request-card', {
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getProfileData() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'me', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
// /api/find-request-card
 

export function getCardBalance() {
    // console.log(decoded_payload, decoded_payload['exp']*1000, (new Date().valueOf()), decoded_payload['exp']*1000 < (new Date().valueOf()))
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }          
    return fetch(API_URL+'card-balance', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getCardPreviousTransactions() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'card-transactions-Previos', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
} 
// http://localhost:8741/api/card-transactions-Previos 

export function getCardTopupHistory() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    } 
    return fetch(API_URL+'card-transactions-topup-history', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
} 
// http://localhost:8741/api/card-transactions-topup-history 
export function getAllDeposits() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'request/deposit', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getConfig() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }    
    
    return fetch(API_URL+'config', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function generateOTP() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'otp/generate', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function verifyOTP(payload) {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    console.log(payload) 
    return fetch(API_URL+'otp/verify-otp', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getcardDetails() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'card-details', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function convertToUSDT(payload) {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'convert', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function requestTopUp(payload) {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'request-topup', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
// http://localhost:8741/api/card/info 
export function cardInfo() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'card/info', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function generateOTPForgetPassword(payload) {
    // if(localStorage.getItem('u_t_p')) {
    //     const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    // if(decoded_payload['exp']*1000 < new Date().valueOf()) {
    //     killToken()
    //     return redirect('/login')
    // }
    // } else {
    //     return redirect('/login') 
    // }
    return fetch(API_URL+'otp/forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
           // "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function verifyOTPForgetPassword(payload) {
    // if(localStorage.getItem('u_t_p')) {
    //     const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    // if(decoded_payload['exp']*1000 < new Date().valueOf()) {
    //     killToken()
    //     return redirect('/login')
    // }
    // } else {
    //     return redirect('/login') 
    // }
    console.log(payload) 
    return fetch(API_URL+'otp/verify-otp-forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
           // "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function forgetPassword(payload) {
    // if(localStorage.getItem('u_t_p')) {
    //     const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    // if(decoded_payload['exp']*1000 < new Date().valueOf()) {
    //     killToken()
    //     return redirect('/login')
    // }
    // } else {
    //     return redirect('/login') 
    // }
    console.log(payload) 
    return fetch(API_URL+'forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
           // "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function updatePassword(payload) { 
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    console.log(payload) 
    return fetch(API_URL+'reset-password', {     
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json" 
        }
    })
}