import React from 'react'
import { useNavigate } from 'react-router-dom'; 
const ResetPassword = () => {
    const navigate = useNavigate();
    
    function goToResetPassword () {
        navigate('/loggedresetpassword') 
    }
    return(
        <>
            <button  className="dropdown-item ai-icon" onClick={goToResetPassword}>  
                <img src='/images/dashboard/header/tabler_lock.svg' alt='green lock' style={{width: '18px', height: '18px'}} />  
                <span className="ms-2" >Reset password</span> 
            </button>
        </>
    )
}

export default ResetPassword 
