import React, { createContext, useEffect, useState, useCallback, useContext, useReducer } from "react";
export const AlignModalAdjustContext = createContext(null);

const AlignModalAdjustContextProvider = (props) => {
    const [adjustState, setAdjustState] = useState(true)
    
     return (
        <AlignModalAdjustContext.Provider
          value={{
            adjustState,
            setAdjustState
        }}
        >
          {props.children}
        </AlignModalAdjustContext.Provider>
     )
}

export default AlignModalAdjustContextProvider

export function useAlignModalAdjust () {
    const { adjustState, setAdjustState } = useContext(AlignModalAdjustContext)
    return { adjustState, setAdjustState }
}


