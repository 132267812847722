// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
  .otp-input input {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }
  
  .otp-input input:focus {
    border-color: #007bff;
  }`, "",{"version":3,"sources":["webpack://./src/components/CSS/OtpInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,yCAAyC;EAC3C;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".otp-input {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    max-width: 300px;\n    margin: 0 auto;\n  }\n  .otp-input input {\n    width: 40px;\n    height: 40px;\n    margin: 0 5px;\n    text-align: center;\n    font-size: 18px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    outline: none;\n    transition: border-color 0.3s ease-in-out;\n  }\n  \n  .otp-input input:focus {\n    border-color: #007bff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
