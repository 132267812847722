import React from 'react'

const ProfileChunkData = ({imagesrc, imagealt, detailLabel, detailValue}) => {
  return (
    <div className='profile-data-item'>
        <img src={imagesrc} alt={imagealt} />
        <div className='profile-data-item-values-wrapper'>
            <span>{detailLabel}</span>
            <span>{detailValue}</span>
        </div>
    </div>
  )
}

export default ProfileChunkData