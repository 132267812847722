import React, { useState, useEffect, useRef, Suspense } from 'react'
import './CSS/mycard.css'
import { FigureCaption } from 'react-bootstrap'
import BalanceDataDetail from '../components/BalanceDataDetail'
import Button from '../components/Button'
import { useToggle } from '../context/ToggleContext'
import Modal from 'react-bootstrap/Modal'
import ButtonBootstrap from 'react-bootstrap/Button'
import TransactionsVisulizer from '../components/TransactionsVisulizer'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Topup from '../Modals/Topup'
import UnsufficientBalance from '../Modals/UnsufficientBalance'
import SureToContinueTopUp from '../Modals/InsufficientBalance_top_up'
import { useAlignModalAdjust } from '../context/AlignModalAdjustContext'
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up' 
import ShowDetails from '../Modals/ShowDetails' 
// import UnsufficientBalance from '../Modals/UnsufficientBalance'
// import SureToContinueTopUp from '../Modals/SureToContinueTopUp'
import VerifyCardInfo from '../Modals/VerifyCardInfo'
import { useLoaderData, Await } from 'react-router-dom' 
import { userBalance, getCardBalance, getCardPreviousTransactions, getCardTopupHistory } from '../security/AuthService' 
import Loader from '../jsx/pages/Loader/Loader'  
import TopUpVisualizer from '../components/TopUpVisualizer' 
const MyCard = () => {
    // const styles = {
    //     fontFamily: 'var(--font-poppins)',
    //     fontSize: '14px',
    //     fontWeight: 'var(--font-weight-400)',
    //     lineHeight: '18px',
    //     borderRadius: '6px',        
    //     textAlign: 'center',
    //     color: 'var(--color-1EAAE7)',
    //     border: '1px solid var(--color-1EAAE7)',
    //     height: '46px'
    // }
    // const settingStyles = {
    //     width: '50%',
    //     height: '32px',
    //     gap: '5px',
    //     border: '1px solid var(--color-1EAAE7)',
    //     borderRadius: '2px',
    //     fontSize: '13px',
    //     fontWeight: '400',
    //     lineHeight: '16px',
    //     color: 'var(--color-1EAAE7)'
        

    // }
    // const showdetailsStyles = {
    //     width: '50%',
    //     height: '32px',
    //     border: '1px solid #2BC155',
    //     borderRadius: '2px',
    //     gap: '5px',
    //     fontSize: '13px',
    //     fontWeight: '400',
    //     lineHeight: '16px',
    //     color: '#2BC155'
    // }
    // const approvetrxStyles = {
    //     position: 'absolute',
    //     top: '-65px',
    //     right: '0',
    //     padding: '7px 20px 7px 20px',
    //     background: '#1EAAE7',
    //     fontSize: '12px',
    //     lineHeight: '16px',
    //     gap: '8px',
    //     color: '#FFFFFF',
    //     borderRadius: '13px'
        
    // }
    const { adjustState, setAdjustState } = useAlignModalAdjust()
    const initialRender = useRef(true);
    // const [activeFilterPrevTrx, setActiveFilterPrevTrx] = useState(null)
    const activeFilterPrevTrx = useRef(null)  
    // const [activeFilterTopUp, setActiveFilterTopUp] = useState(null)
    const activeFilterTopUp = useRef(null)   
    const { toggle } = useToggle()
    const [show, setShow] = useState(false)
    const [balanceCard, setBalanceCard] = useState('')
    const [balanceWallet, setBalanceWallet] = useState('') 
    const [previousTrx, setPreviousTrx] = useState({data: []}) 
    const [topupHistory, setTopupHistory] = useState({data: []}) 
    const [filteredPreviousTrx, setFilteredPreviousTrx] = useState({data: []}) 
    const [filteredTopupHistory, setFilteredTopupHistory] = useState({data: []})   
    const [showdetails, setShowDetails] = useState(false)
    const [showcarddetails, setShowcardDetails] = useState(false) 
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  
    const [error, setError] = useState('') 
    const [cardHolderDataLoadingState, setCardHolderDataLoadingState] = useState(false) 
    // const handleClose = () => {setShow(false);                 initialRender.current = false}
    const refAllow = useRef(false) 
    const handleClose = () => {setShow(false); setAdjustState(false)}
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false)
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleCloseDetails = () => setShowDetails(false);
    const handleShowDetails = () => setShowDetails(true);  
    const handleClosecardDetails = () => setShowcardDetails(false);
    const handleShowcardDetails = () => setShowcardDetails(true);
    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);                                        
    const handleRefAllowTrue = ()=>  refAllow.current = true  
    const handleRefAllowFalse = ()=>  refAllow.current = false      
    useEffect(()=>{
        getAllCardHolderDetails() 
    }, []) 
    // useEffect(()=>{
    //     if(window.innerWidth >= 600) {
    //         if (initialRender.current) {
    //             if(document.querySelector('.confirmation > .modal-dialog')) {
    //                 (document.querySelector('.confirmation > .modal-dialog').style.left = '-8px') 
    //             }
                
    //         }
    //     }
    // })
    function activeFilterPrevTrxHandler (e) {
        console.log(e.target.innerHTML)
        // setActiveFilterPrevTrx(e.target.innerHTML) 
        activeFilterPrevTrx.current = e.target.innerHTML 
        console.log(activeFilterPrevTrx.current)  
        handleFilterPrevTrx()
    }
    function activeFilterTopUpHandler (e) {
        // setActiveFilterTopUp(e.target.innerHTML)
        activeFilterTopUp.current = e.target.innerHTML 
        handleFilterTopupHistory() 
    }
    function topupClickHandler () {
        console.log('Hi')
        handleShow()
    }
    console.log(previousTrx)
                console.log(topupHistory)
    async function getAllCardHolderDetails() {
        try {
            setCardHolderDataLoadingState(true)
            const result_balance_wallet = await userBalance()
            const wallet_balance = await result_balance_wallet.json()

            console.log(wallet_balance['data'][0]['balance']) 
            const result_card_balance = await getCardBalance()

            const card_balance = await result_card_balance.json() 
            console.log(card_balance['data']['balance'])   

            const result_card_previous_trx = await getCardPreviousTransactions()
            const card_previous_trx = await result_card_previous_trx.json()

            const result_card_topup_history = await getCardTopupHistory()
            const card_topup_history = await result_card_topup_history.json()
            // console.log(card_trx) 
            // console.log(card_previous_trx)
            // console.log(card_topup_history) 
            if(result_balance_wallet.ok && result_card_balance.ok && result_card_previous_trx.ok && result_card_topup_history.ok) {
                console.log('?') 
                setBalanceWallet(wallet_balance['data'][0]['balance']) 
                setBalanceCard(card_balance['data']['balance']) 
                console.log(card_previous_trx['data'])
                console.log(card_topup_history['data'])
                setPreviousTrx({...previousTrx, data: card_previous_trx['data'].reverse()})
                setFilteredPreviousTrx({...filteredPreviousTrx, data: card_previous_trx['data']})    
                setTopupHistory({...topupHistory, data: card_topup_history['data'].reverse()})    
                setFilteredTopupHistory({...filteredTopupHistory, data: card_topup_history['data']})                                                           
                console.log(previousTrx)
                console.log(topupHistory) 
                // {...topupHistory, data: card_topup_history.data}   
                // const res = card_trx['data'].filter((trx)=> console.log(trx) )   

                // console.log(typeof card_trx)  
                setCardHolderDataLoadingState(false) 
            } else {
                setError('Error') 
                setCardHolderDataLoadingState(false) 
            }
            // Promise.all([userBalance(), getCardBalance()]).then((values) => {
            //     console.log(values[0], values[1]);
            //     return values // [3, 1337, "foo"]
            //   }).then((values) => {let a = values[0].json()
            //     let b = values[1].json()
            //     return [a,b]}).then((values)=> console.log(values))  
        } catch(err) {
            setError(err) 
        }
          
} 
    
    function handleFilterPrevTrx() {
        
            if(activeFilterPrevTrx.current==='Current Month') {
                const filteredData = previousTrx['data'].filter((trx)=> ((new Date().getMonth()+1) === (new Date(trx['dateOfTransaction']).getMonth()+1)) && ((new Date().getFullYear()) === (new Date(trx['dateOfTransaction']).getFullYear()))) 
                // console.log(a) 
               return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData})    
                   
            } else if(activeFilterPrevTrx.current === 'Current Week') {
                // setFilteredPreviousTrx({...filteredPreviousTrx, data: []}) 
                // const today = new Date('07-13-2024') 
                const today = new Date()  

                const today_index = today.getDay() 
                const start_week = today.valueOf() - (today_index * (24*60*60*1000))
                const end_week = today.valueOf() + ((6 - today_index) * (24*60*60*1000))
                // console.log(new Date(`${new Date(start_week).getFullYear()}-${new Date(start_week).getMonth()+1}-${new Date(start_week).getDate()}`))  
                // console.log(new Date(new Date(`${new Date(end_week).getFullYear()}-${new Date(end_week).getMonth()+1}-${new Date(end_week).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000)))
                // console.log(new Date(s_w)) 
                // console.log(new Date(e_w).getFullYear(), new Date(e_w).getMonth()+1, new Date(e_w).getDate())  
                // console.log(new Date(`${new Date(e_w).getFullYear()}-${new Date(e_w).getMonth()+1}-${new Date(e_w).getDate()}`))     
                // console.log(new Date(new Date(`${new Date(e_w).getFullYear()}-${new Date(e_w).getMonth()+1}-${new Date(e_w).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000)))       
                const start_week_date = new Date(`${new Date(start_week).getFullYear()}-${new Date(start_week).getMonth()+1}-${new Date(start_week).getDate()}`)
                const end_week_date = new Date(new Date(`${new Date(end_week).getFullYear()}-${new Date(end_week).getMonth()+1}-${new Date(end_week).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000))
                // console.log(new Date(start_week_date).valueOf(), new Date(end_week_date).valueOf())   
                // console.log(today, today_index, new Date(1724944132547))     
                const filteredData = previousTrx['data'].filter((trx)=> (new Date(trx['dateOfTransaction']).valueOf()>=new Date(start_week_date).valueOf() && new Date(trx['dateOfTransaction']).valueOf() <= new Date(end_week_date).valueOf()))  
                // console.log(a) 
                // console.log(filteredData)
               return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData}) 
                // previousTrx['data'].filter((trx)=> {return (new Date().getMonth()+1) === (new Date(trx['dateOfTransaction']).getMonth()+1)}) 
            } else if(activeFilterPrevTrx.current === 'Today') { 
                // const today = new Date('07-15-2024')
                const today = new Date()   
                const start_day = new Date(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`)
                const end_day = new Date(new Date(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000))
                // previousTrx['data'].filter((trx)=> {return (new Date().getMonth()+1) === (new Date(trx['dateOfTransaction']).getMonth()+1)})   
                const filteredData = previousTrx['data'].filter((trx)=> new Date(trx['dateOfTransaction']).valueOf() >= new Date(start_day).valueOf() && new Date(trx['dateOfTransaction']).valueOf() <= new Date(end_day).valueOf())  
                // console.log(filteredData)  
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData})
            } else {

                return setFilteredPreviousTrx(previousTrx['data']) 
            } 
        
    } 
    function handleFilterTopupHistory() {
        
        if(activeFilterTopUp.current==='Current Month') {
            const filteredData = topupHistory['data'].filter((trx)=> ((new Date().getMonth()+1) === (new Date(trx['dateOfTransaction']).getMonth()+1)) && ((new Date().getFullYear()) === (new Date(trx['dateOfTransaction']).getFullYear()))) 
            // console.log(a) 
           return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})    
               
        } else if(activeFilterTopUp.current === 'Current Week') {
            // setFilteredTopupHistory({...filteredTopupHistory, data: []}) 
            // const today = new Date('07-13-2024') 
            const today = new Date()  
            const today_index = today.getDay() 
            const start_week = today.valueOf() - (today_index * (24*60*60*1000))
            const end_week = today.valueOf() + ((6 - today_index) * (24*60*60*1000))
            console.log(new Date(`${new Date(start_week).getFullYear()}-${new Date(start_week).getMonth()+1}-${new Date(start_week).getDate()}`))  
            console.log(new Date(new Date(`${new Date(end_week).getFullYear()}-${new Date(end_week).getMonth()+1}-${new Date(end_week).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000)))
            // console.log(new Date(s_w)) 
            // console.log(new Date(e_w).getFullYear(), new Date(e_w).getMonth()+1, new Date(e_w).getDate())  
            // console.log(new Date(`${new Date(e_w).getFullYear()}-${new Date(e_w).getMonth()+1}-${new Date(e_w).getDate()}`))     
            // console.log(new Date(new Date(`${new Date(e_w).getFullYear()}-${new Date(e_w).getMonth()+1}-${new Date(e_w).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000)))       
            const start_week_date = new Date(`${new Date(start_week).getFullYear()}-${new Date(start_week).getMonth()+1}-${new Date(start_week).getDate()}`)
            const end_week_date = new Date(new Date(`${new Date(end_week).getFullYear()}-${new Date(end_week).getMonth()+1}-${new Date(end_week).getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000))
            // console.log(new Date(start_week_date).valueOf(), new Date(end_week_date).valueOf())   
            // console.log(today, today_index, new Date(1724944132547))     
            const filteredData = topupHistory['data'].filter((trx)=> (new Date(trx['dateOfTransaction']).valueOf()>=new Date(start_week_date).valueOf() && new Date(trx['dateOfTransaction']).valueOf() <= new Date(end_week_date).valueOf()))  
            // console.log(a) 
            console.log(filteredData)
           return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData}) 
            // previousTrx['data'].filter((trx)=> {return (new Date().getMonth()+1) === (new Date(trx['dateOfTransaction']).getMonth()+1)}) 
        } else if(activeFilterTopUp.current === 'Today') { 
            // const today = new Date('07-15-2024') 
            const today = new Date()  
            const start_day = new Date(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`)
            const end_day = new Date(new Date(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`).valueOf()+ (23*60*60*1000)+ (59*60*1000) + (59*1000))
            // previousTrx['data'].filter((trx)=> {return (new Date().getMonth()+1) === (new Date(trx['dateOfTransaction']).getMonth()+1)})   
            const filteredData = topupHistory['data'].filter((trx)=> new Date(trx['dateOfTransaction']).valueOf() >= new Date(start_day).valueOf() && new Date(trx['dateOfTransaction']).valueOf() <= new Date(end_day).valueOf())  
            console.log(filteredData)  
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})
        } else {

            return setFilteredTopupHistory(topupHistory['data']) 
        } 
    
}
function WalletRedirectButton() { 
    const userAgent = navigator.userAgent.toLowerCase(); 
    if (userAgent.includes("android")) { 
        if (userAgent.includes("samsung")) { 
            window.location.href = "https://www.samsung.com/us/samsung-pay/"; 
        } else { 
            window.location.href = "https://pay.google.com/gp/wallet"; 
        } 
    } else if ( 
        userAgent.includes("iphone") || 
        userAgent.includes("ipad") || 
        userAgent.includes("ipod") 
    ) { 
        alert( 
            'To add your card to Apple Pay:\n\n1. Open the Wallet app on your iPhone.\n2. Tap the "+" icon in the upper right corner.\n3. Follow the instructions to add your card.' 
        ); 
    } else { 
        alert("Device not supported for Google Pay, Samsung Pay, or Apple Pay."); 
    } 
};
  return (
    <>
    {(!cardHolderDataLoadingState && !error) && <div className='mycard-container'>
        <section className='balance-card-container'>
            <div className='mycard-title'>My Paytaps card</div>
            <div className='mypaytaps-container'>
                <div className='main-balance-wrapper'>
                    <figure className='main-balance-figure-wrapper'>
                        <img src='/images/dashboard/mycard/card.svg' alt='card' loading='lazy' draggable='false' />
                        <FigureCaption>Main Balance</FigureCaption>
                    </figure>
                    <div className='balance-details-wrapper'>
                        
                        <span className='vertical-dots'><img src='/images/dashboard/mycard/vertical-dots.svg' alt='vertical dots' /></span>
                        <BalanceDataDetail imagesrc='/images/dashboard/mycard/tabler_credit-card.svg' 
                            label='Card balance' value={`€${balanceCard}`} altText='card balance' 
                        />

                        {/* <BalanceDataDetail imagesrc='/images/dashboard/mycard/mingcute_tether-usdt-line.svg' 
                            label='Total Balance' value='€27,568' altText='total balance' 
                        /> */}

                        <BalanceDataDetail imagesrc='/images/dashboard/mycard/mingcute_coin-2-line.svg' 
                            label='Wallet balance' value={`$${balanceWallet}`} altText='wallet'  
                        />
                        <Button styles='topup-btn-styles' click={topupClickHandler}>+Top Up</Button>
                        
                    </div>
                    {/* <div>

                    </div> */}
                </div>
                <div className={`card-wrapper ${toggle ? 'align-items-center' : ''}`} >
                    <figure>
                        <img src='/images/dashboard/mycard/paytaps card.png' alt="card paytaps" />
                        {/* <span className='card-holder-name'>foulan ben foulan</span> */}
                        <span className='card-holder-name'>XXXXX XXXXX</span>

                        {/* <span className='card-number'>9758  0158  0015  8799</span>  */}
                        <span className='card-number'>XXXX XXXX XXXX XXXX</span> 

                        <div className='card-validity'>
                            <span>VALID THRU</span>
                            <span>XX/XX</span> 
                        </div>
                        <Button imagesrc='/images/dashboard/mycard/checked.svg' styles='approvetrx-btn-styles'>Approve Transaction</Button>
                    </figure>
                    <div className='card-config-wrapper'>
                        <Button imagesrc='/images/dashboard/mycard/uil_setting.svg' styles='setting-btn-styles' click={WalletRedirectButton}>Setting</Button>
                        <Button imagesrc='/images/dashboard/mycard/tabler_eye.svg' styles='showdetails-btn-styles' click={handleShowDetails}> Show details</Button>
                    </div>
                </div>
            </div>
        </section>
        <section className='history-data-container'>
            <div className='previous-transactions-wrapper'>
                <div className='previous-transactions-wrapper-header'>
                    <div className='previous-transactions-wrapper-header-text-content'>
                        <span>Previous Transactions</span>
                        {(previousTrx['data'].length > 0) && 
                        // <span>Lorem ipsum dolor sit amet, consectetur</span> 
                        <>
                        {(activeFilterPrevTrx.current === 'Current Month') && <span>Current month's transactions</span>}
                        {(activeFilterPrevTrx.current === 'Current Week') && <span>Current Week's transactions</span>}
                        {(activeFilterPrevTrx.current === 'Today') && <span>Today's transactions</span>}
                        {(activeFilterPrevTrx.current === null) && <span>All previous transactions</span>}                     
                        </>}
                    </div>
                    {(previousTrx['data'].length > 0) && <div className='previous-transactions-wrapper-header-filter'>
                        <button className={`${activeFilterPrevTrx.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler} >Current Month</button>
                        <button className={`${activeFilterPrevTrx.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Current Week</button>
                        <button className={`${activeFilterPrevTrx.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Today</button>
                    </div>}
                </div>
                
                {/* filteredPreviousTrx['data'] */}
            <TransactionsVisulizer data={filteredPreviousTrx['data']} />     

            </div>
            <div className='top-up-history-wrapper'>
                <div className='previous-transactions-wrapper-header'>
                    <div className='previous-transactions-wrapper-header-text-content'>
                        <span>Top-up History </span>
                        {/* {(topupHistory['data'].length > 0) && <span>Lorem ipsum dolor sit amet, consectetur</span>} */}
                        {(topupHistory['data'].length > 0) && 
                        // <span>Lorem ipsum dolor sit amet, consectetur</span> 
                        <>
                        {(activeFilterTopUp.current === 'Current Month') && <span>Current month's transactions</span>}
                        {(activeFilterTopUp.current === 'Current Week') && <span>Current Week's transactions</span>}
                        {(activeFilterTopUp.current === 'Today') && <span>Today's transactions</span>}
                        {(activeFilterTopUp.current === null) && <span>All Top-up history</span>}                   
                        </>}
                    </div>
                    {(topupHistory['data'].length > 0) &&<div className='previous-transactions-wrapper-header-filter'>
                        <button className={`${activeFilterTopUp.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler} >Current Month</button>
                        <button className={`${activeFilterTopUp.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Current Week</button>
                        <button className={`${activeFilterTopUp.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Today</button>
                    </div>} 
                </div>
                {/* <div className='previous-transactions-wrapper-body'>
                    <ul className='previous-transactions-wrapper-body-list'>
                        {topup.map((item)=>{
                            return (<li className='previous-transactions-wrapper-body-list-transaction-data'>
                            <figure className='transaction-status-image'>
                                {
                                    true ?
                                    <img src='/images/dashboard/mycard/transaction-green.svg' alt='completed transaction' /> :
                                    <img src='/images/dashboard/mycard/transaction-red.svg' alt='pending transaction' />
                                }
                            </figure>
                            <div>
                                <span className='transaction-origin'>{item.origin}</span>
                                <div className='transaction-date'><span>{item.date.day}</span><span>{item.date.time}</span></div>
                                <span className='transaction-value'>{item.value}</span>
                                
                            </div>
                        </li>)})}
                    </ul>
                </div> */}
                {/* filteredTopupHistory['data'] */}
                <TopUpVisualizer data={filteredTopupHistory['data']} topup={true} />     

            </div>
            
        </section>
    </div>}
    {(cardHolderDataLoadingState && !error) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
    {/* <Modal show={show} onHide={handleClose} className='topup-modal' backdrop='static' keyboard='false' >
    <Modal.Header closeButton>
      
        <div className='topup-title'>Top-up</div>
        <div className='topup-description'>Lorem ipsum dolor sit amet, consectetuer</div>
    </Modal.Header> */}
    {/* <Modal.Body className='center'> */}
    {/* <button type='button' class="btn-close" aria-label="Close"><img src='/images/dashboard/topup/cross.svg'  /></button> */}
    
    {/* <Formik
                        initialValues={{
                            topup_amount: ''
                        }}
                        validateOnChange={ false }
                        validateOnBlur={ false } 
                        validationSchema={YUP.object({
                            topup_amount: YUP.string().required('Required!').min(8, 'Should be greater than 8').matches(/[A-Z]+/, { message: <em>Must Contain Maj</em> })
                            .matches(/[a-z]+/, { message: <em>Must Contain Min</em>}),
                            email: YUP.string().email('Invalid Email!').required('Required!'),
                            password: YUP.string().required('Required!'),
                            confirm_password: YUP.string().required('Required!').oneOf([YUP.ref('password'), ''], 'Should be as password')
                        })}

                        onSubmit={async (values, {setSubmitting, resetForm})=>{
                            console.log(values)
                            // setSubmitting(false)
                            // resetForm()
                            // navigate('/')
                        }}
                    >
                        {(formik) => {
                        // state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                <TextInput name='topup_amount' id='topup_amount' label='Enter amount' 
                                inputtype='text' />
                                <div className='amount-fees-wrapper'>
                                    <div className='amount-fees-row'><span>Amount</span><span>500.00 EUR</span></div>
                                    <div className='amount-fees-row'><span>Fees</span><span>3.0 EUR</span></div>
                                    <div className='amount-fees-row'><span>Total</span><span>503.00 EUR</span></div>
                                </div>
                                <div className='transaction-fee-notification'>
                                    * Our transaction fee are included. <span>See transaction fee</span>
                                </div>
                                <div className='wallet-address-wrapper'>
                                    <div>Lorem ipsum dolor sit amet, consectetuer</div>
                        f            <div>
                                        <div>
                                            <img src='/images/dashboard/topup/tabler_wallet.svg' alt='wallet' />
                                            <div className='wallet-address'>
                                                <span>Wallet code</span>
                                                <span id='wallet-address-value'>FJG97GR54EAZD452CX3AZEZE23WXWSD2548SHC</span>
                                            </div>
                                        </div>
                                        <button className='copy-btn' type='button' onClick={copyAddress}>
                                            <img src='/images/dashboard/topup/tabler_copy.svg' 
                                            alt='2 squares with the highest one its right half clipped' hidden={isCopied} />
                                            <span hidden={!isCopied}>Copied!</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='group-btn'>
                                
                                <Button click={handleClose} id="cancel">Cancel</Button>
                                <Button btnType='submit' id='confirm_topup'>Confirm</Button> 
                                </div>
                                
                            </Form>)}}
    </Formik> */}
    {/* </Modal.Body>
    <Modal.Footer> */}
      
      {/* <ButtonBootstrap variant="primary" onClick={handleClose}>
        Save Changes
      </ButtonBootstrap> */}
    {/* </Modal.Footer> */}
  {/* </Modal> */}
  {/* <Modal show={show} onHide={handleClose} className='unsufficient-balance-topup' backdrop='static' keyboard='false' > */}
    {/* <Modal.Header closeButton>
      
        <figure>
            <img src='/images/dashboard/topup/Group 318.svg' alt='wallet containing fiat money and coins' />
        </figure>
        <div className='insufficient-balance'>
            Insufficient  Balance 
        </div>
        <div className='insufficient-balance-deposit-120-dollar'>
            It appears that your current balance is insufficient to order a card. To proceed, please deposit at least <span>$120</span>.
        </div>
    </Modal.Header> */}
    {/* <Modal.Body className='center'> */}
    {/* <button type='button' class="btn-close" aria-label="Close"><img src='/images/dashboard/topup/cross.svg'  /></button> */}
    
    {/* <Formik
                        initialValues={{
                            topup_amount: '',
                            agree_terms_conditions: false
                        }}
                        validateOnChange={ false }
                        validateOnBlur={ false } 
                        validationSchema={YUP.object({
                            // topup_amount: YUP.string().required('Required!').min(8, 'Should be greater than 8').matches(/[A-Z]+/, { message: <em>Must Contain Maj</em> })
                            // .matches(/[a-z]+/, { message: <em>Must Contain Min</em>}),
                            // email: YUP.string().email('Invalid Email!').required('Required!'),
                            // password: YUP.string().required('Required!'),
                            // confirm_password: YUP.string().required('Required!').oneOf([YUP.ref('password'), ''], 'Should be as password')
                        })}

                        onSubmit={async (values, {setSubmitting, resetForm})=>{
                            console.log(values)
                            // setSubmitting(false)
                            // resetForm()
                            // navigate('/')
                            handleShow1(true)
                        }}
                    >
                        {(formik) => {
                        // state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                <TextInput name='topup_amount' id='topup_amount' label='Enter amount' 
                                inputtype='text' />
                                {/* <div className='amount-fees-wrapper'>
                                    <div className='amount-fees-row'><span>Amount</span><span>500.00 EUR</span></div>
                                    <div className='amount-fees-row'><span>Fees</span><span>3.0 EUR</span></div>
                                    <div className='amount-fees-row'><span>Total</span><span>503.00 EUR</span></div>
                                </div>
                                <div className='transaction-fee-notification'>
                                    * Our transaction fee are included. <span>See transaction fee</span>
                                </div> */}
                                

 
                                {/* <div className='wallet-address-wrapper'>
                                    <div className='use-wallet-address-provided-for-deposit'>Use the wallet address provided below to make the deposit.</div>
                                    <div>
                                        <div>
                                            <img src='/images/dashboard/topup/tabler_wallet.svg' alt='wallet' />
                                            <div className='wallet-address'>
                                                <span>Wallet code</span>
                                                <span id='wallet-address-value'>FJG97GR54EAZD452CX3AZEZE23WXWSD2548SHC</span>
                                            </div>
                                        </div>
                                        <button className='copy-btn' type='button' onClick={copyAddress}>
                                            <img src='/images/dashboard/topup/tabler_copy.svg' 
                                            alt='2 squares with the highest one its right half clipped' hidden={isCopied} />
                                            <span hidden={!isCopied}>Copied!</span>
                                        </button>
                                    </div>
                                </div>
                                <label className='check-term-conditions'>
                                    <Field type="checkbox" name="agree_terms_conditions" />
                                    <span> Agree to the <span className='terms_conditions'>Terms and Conditions</span> and <span className='terms_conditions'>Privacy Policy</span>.</span>
                                </label> */}
                                
                                {/* <ButtonBootstrap variant="secondary" onClick={handleClose}>
                                    
                                </ButtonBootstrap>
                                <button type='submit' id='confirm_topup'></button> */}
                                {/* <Button click={handleClose}>Cancel</Button> */}
                                {/* <Button btnType='submit' id='submit_topup' disabled={!formik.values.agree_terms_conditions} styles='submit-btn'>Submit</Button>  */}
                                
                                
                            {/* </Form>)}}
    // </Formik> */} 
    {/* </Modal.Body>
    <Modal.Footer> */}
      
      {/* <ButtonBootstrap variant="primary" onClick={handleClose}>
        Save Changes
      </ButtonBootstrap> */}
    {/* </Modal.Footer> */}
  {/* </Modal> */}
  {/* <Modal show={show1} onHide={handleClose1} className='confirmation' backdrop='static' keyboard='false'>
    <div>
        <figure>
            <img src='/images/dashboard/topup/freepik--Character--inject-44.svg' alt='2 cards with the frontal one that is hidding almost the second card' draggable='false' />
        </figure>
        <div className='sure-to-continue-top-up'>Are you sure you want to continue?</div>
        <div>You are about to confirm that you have made the deposit. If you proceed, your request will be reviewed.</div>
    </div>
    <div className='group-btn'>
        <Button click={handleClose1}>Cancel</Button>
        <Button>Confirm</Button>
    </div>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      {/* </Modal>  */}
      <Topup show={show} handleClose={handleClose} handleShowConfirmation={handleShow1} handleShowInsufTopupBalance={handleShowInsufTopupBalance} />  
      {/* <UnsufficientBalance show={show} handleClose={handleClose} handleShowSuretoContinueTopUp={handleShow1} />
      <SureToContinueTopUp show={show1} handleClose={handleClose1} initialRender={adjustState} />  */}
      <InsufficientBalance_top_up show={showInsufficientTopupBalance} handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} handleShowInsufTopupBalance={handleShowInsufTopupBalance} />   
      <ShowDetails show={showcarddetails}  handleClosecardDetails={handleClosecardDetails} />   
      <VerifyCardInfo show={showdetails} handleClose={handleCloseDetails} a={refAllow.current} handleRefAllowTrue={handleRefAllowTrue} handleRefAllowFalse={handleRefAllowFalse} 
      handleClosecardDetails={handleClosecardDetails} handleShowcardDetails={handleShowcardDetails} />    
  </>
  )
}

export default MyCard


