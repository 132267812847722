import React from 'react'

import { Field, ErrorMessage } from 'formik'

import ErrorInput from './ErrorInput'

import './CSS/textinput.css'

const TextInput = ({label, name, id, error, touched, inputtype, onKeyUp, placeholder}) => {
  return (
    <div className='form_control'>
        {label && <label htmlFor={id} className='form_label'>{label}</label>}
        <Field input={inputtype} name={name} id={id} 
        className={`register-input ${error && touched ? 'error' : ''} ${!error && touched ? 'correct' : ''}`} 
        onKeyUp={onKeyUp && onKeyUp} inputMode={`${id === 'email' ? 'email' : 'text'}`} placeholder={placeholder} />
        <ErrorMessage name={name} component={ErrorInput} />
    </div>
  )
}

export default TextInput 
